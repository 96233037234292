import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from 'services'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 233px);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
`

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 0px auto;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;
  border-radius: 5px;
  background: #fff;
  padding: 24px;
  border-radius: 0px 5px 5px 0px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
  }

  .warp-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 20px;

    &.form-register {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .ant-form-item {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-form-item-label {
      padding-bottom: 0;

      label {
        font-weight: bold;
      }
    }

    .ant-form-item-explain-error {
      font-size: 12px;
    }

    .form-bottom {
      margin-top: 16px;

      p {
        text-align: center;
        font-size: 14px;
        margin-bottom: 12px;
      }

      button {
        margin-bottom: 7px;
      }

      .check-box-rule {
        font-size: 13px;
      }
    }
  }
`

const ResetPassword = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    form.validateFields().then(async (values) => {
      const token = location.search.split('?token=')[1]

      const payload = {
        token,
        password: values.password,
        password_confirmation: values.confirmPassword,
      }

      setLoading(true)
      try {
        await authService.resetPassword(
          payload,
          (response) => {
            toast.success(response?.data?.message, {
              autoClose: 1000,
            })
            form.resetFields()
            setLoading(false)
            setTimeout(() => history.push('/'), 1500)
          },
          (response) => {
            toast.error(response?.message || 'Xác nhận mật khẩu mới thất bại')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('resetPassword', error)
      }
    })
  }, [history, location, form])

  return (
    <Wrapper>
      <WrapperForm>
        <h2>Xác nhận mật khẩu mới</h2>

        <div className='warp-form'>
          <Form name='basic' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} autoComplete='off' form={form}>
            <Form.Item
              label='Mật khẩu'
              name='password'
              rules={[
                { required: true, message: 'Vui lòng nhập mật khẩu!' },
                { min: 8, message: 'Trường mật khẩu tối thiểu 8 ký tự' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder='Nhập mật khẩu!'
                autoComplete='false'
              />
            </Form.Item>

            <Form.Item
              label='Xác nhận mật khẩu'
              name='confirmPassword'
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng xác nhận mật khẩu',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Không trùng khớp với mật khẩu đã nhập'))
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder='Nhập lại mật khẩu!' autoComplete='false' />
            </Form.Item>
          </Form>

          <Button type='primary' className='submit-btn' loading={loading} onClick={handleSubmit}>
            Xác nhận
          </Button>
        </div>
      </WrapperForm>
    </Wrapper>
  )
}

export default ResetPassword
