import { Button, Col, Form, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import DeliveryForm from './components/DeliveryForm'
import OrderDetailForm from './components/OrderDetailForm'
import ConfirmForm from './components/ConfirmForm'
import { useHistory } from 'react-router-dom'
import ROUTER from 'services/router'
import { useCallback, useEffect, useState } from 'react'
import { orderService } from 'services'
import { toast } from 'react-toastify'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import ModalError from './components/ModalError'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;

  ${({ theme }) => theme.mediaQueries.sm} {
    .button-add {
      position: absolute;
      right: 20px;
      top: 16px;
    }

    .form-title {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0;
      line-height: 1.5715;
      height: 35px;
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const Page = ({ dataDetail }: { dataDetail?: any }) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const { customerProfile } = useGetCustomerData()

  const [packageSelected, setPackageSelected] = useState<any>()
  const [qty, setQty] = useState<number>(0)
  const [packageList, setPackageList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [modalError, setModalError] = useState<boolean>(false)
  const [textError, setTextError] = useState<string>('')

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async (values) => {
      setLoading(true)
      const paramCreate = {
        product_name: values?.orderName,
        view_product: values?.isCheck ? 1 : 0,
        package_ship: values?.typePackage,
        who: values?.transferFee === 0 ? 0 : 1,
        price: values?.orderPrice || 0,
        number_of_package: values?.orderQty,
        product_price: values?.productPrice || 0,
        sendAddress: {
          full_name: values?.delivery_full_name,
          email: values?.delivery_email,
          phone_number: values?.delivery_phone_number,
          address: values?.delivery_address,
          province: values?.delivery_province_id,
          district: values?.delivery_district_id,
          ward: values?.delivery_ward_id,
          note: values?.note,
        },
        receiveAddress: {
          full_name: values?.shipping_full_name,
          email: values?.shipping_email,
          phone_number: values?.shipping_phone_number,
          address: values?.shipping_address,
          province: values?.shipping_province_id,
          district: values?.shipping_district_id,
          ward: values?.shipping_ward_id,
        },
      }

      orderService.create(
        paramCreate,
        (response) => {
          history.push(ROUTER.orderTracking)
          toast.success(response?.data?.message, {
            autoClose: 1000,
          })
          setLoading(false)
        },
        (response) => {
          setLoading(false)

          if (response?.screen === 'modal') {
            setTextError(response?.message)
            setModalError(true)
          } else {
            toast.error(response?.message)
          }
        }
      )
    })
  }, [form, history])

  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue({
        orderName: dataDetail?.detail?.title,
        isCheck: dataDetail?.detail?.view_product,
        typePackage: dataDetail?.detail?.package?.id,
        transferFee: dataDetail?.detail?.who,
        orderPrice: dataDetail?.detail?.price,
        orderQty: dataDetail?.detail?.number_of_package,
        productPrice: dataDetail?.product_price,
        note: dataDetail?.sender?.note,
        delivery_full_name: dataDetail?.sender?.full_name,
        delivery_email: dataDetail?.sender?.email,
        delivery_phone_number: dataDetail?.sender?.phone_number,
        delivery_address: dataDetail?.sender?.address,
        delivery_province_id: dataDetail?.sender?.province?.id,
        delivery_district_id: dataDetail?.sender?.district?.id,
        delivery_ward_id: dataDetail?.sender?.ward?.id,
        shipping_full_name: dataDetail?.receiver?.full_name,
        shipping_email: dataDetail?.receiver?.email,
        shipping_phone_number: dataDetail?.receiver?.phone_number,
        shipping_address: dataDetail?.receiver?.address,
        shipping_province_id: dataDetail?.receiver?.province?.id,
        shipping_district_id: dataDetail?.receiver?.district?.id,
        shipping_ward_id: dataDetail?.receiver?.ward?.id,
        isConfirm: dataDetail?.product_price ? true : false,
      })

      setQty(dataDetail?.detail?.number_of_package)
    } else {
      form.setFieldValue('orderPrice', 0)
      form.setFieldValue('orderQty', 1)
      setQty(1)
      form.setFieldsValue({
        delivery_full_name: customerProfile?.full_name,
        delivery_email: customerProfile?.email,
        delivery_phone_number: customerProfile?.phone_number,
      })
    }
  }, [customerProfile, dataDetail, form])

  return (
    <>
      <WrapperPage>
        <p className='title-page'>Tạo đơn vận chuyển</p>

        <Row gutter={[14, 14]}>
          <Col span={24}>
            <fieldset disabled={!!dataDetail}>
              <Row gutter={[14, 14]}>
                <Col span={24}>
                  <Row gutter={[14, 14]}>
                    <Col span={24}>
                      <FormWrapper title='Thông tin người gửi hàng'>
                        <DeliveryForm formType='delivery' form={form} dataDetail={dataDetail} />
                      </FormWrapper>
                    </Col>

                    <Col span={24}>
                      <FormWrapper title='Thông tin người nhận hàng'>
                        <DeliveryForm formType='shipping' form={form} dataDetail={dataDetail} />
                      </FormWrapper>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[14, 14]}>
                    <Col span={24}>
                      <FormWrapper title='Chi tiết kiện hàng'>
                        <OrderDetailForm
                          form={form}
                          setPackageSelected={setPackageSelected}
                          packageSelected={packageSelected}
                          setQty={setQty}
                          customerProfile={customerProfile}
                          packageList={packageList}
                          setPackageList={setPackageList}
                          dataDetail={dataDetail}
                        />
                      </FormWrapper>
                    </Col>

                    <Col span={24}>
                      {packageSelected && (
                        <FormWrapper title='Bảo đảm hàng hóa'>
                          <ConfirmForm form={form} packageSelected={packageSelected} qty={qty} dataDetail={dataDetail} />
                        </FormWrapper>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </fieldset>
          </Col>

          <Col span={24}>
            <ButtonGroups>
              <Button type='primary' size='large' onClick={handleSubmit} loading={loading}>
                Tạo đơn hàng
              </Button>
              <Button size='large' onClick={() => history.push(ROUTER.adminHome)}>
                Về trang chủ
              </Button>
            </ButtonGroups>
          </Col>
        </Row>
      </WrapperPage>

      <ModalError open={modalError} onCancel={() => setModalError(false)} text={textError} />
    </>
  )
}

export default Page
