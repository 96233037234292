import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'
import { useConfig } from 'services/swr/settings'

const Recruitment = () => {
  const { data: config } = useConfig({
    revalidateIfStale: false,
  })

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: 'Tuyển dụng',
              href: '/tuyen-dung',
            },
          ]}
        />

        <Content>
          <div className='wrap-content'>
            <div dangerouslySetInnerHTML={{ __html: config?.recruitmentNotice }} />
          </div>
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default Recruitment

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;

  .wrap-content {
    font-size: 13px;
    color: #333;

    h1 {
      font-weight: 400;
      font-size: 1.5em;
      text-align: center;
      color: #333;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 2em;
      }
    }

    p {
      line-height: 1.6em;
      margin: 0 0 10px;
      margin-bottom: 5px;
    }

    img {
      max-width: 100%;
    }
  }
`
