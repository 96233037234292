import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'
import { FileDoneOutlined, DollarOutlined, DropboxOutlined } from '@ant-design/icons'
import Banner from './components/Banner'
import { useConfig } from 'services/swr/settings'

const Service = () => {
  const { data: config } = useConfig({
    revalidateIfStale: false,
  })

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: 'Dịch vụ',
              href: '/dich-vu',
            },
          ]}
        />

        <Content>
          <div className='header-list'>
            <a href={config?.pdfCommitment} target='_blank' rel='noopener noreferrer'>
              <FileDoneOutlined />
              <span>bản cam kết</span>
            </a>
            <a href={config?.pdfDeliveryPrice} target='_blank' rel='noopener noreferrer'>
              <DollarOutlined />
              <span>Bảng giá giao nhận</span>
            </a>
            <a href={config?.pdfPackagingRegulations} target='_blank' rel='noopener noreferrer'>
              <DropboxOutlined />
              <span>Quy định đóng gói hàng hóa</span>
            </a>
          </div>

          <Banner />
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default Service

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;

  .header-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    a {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: #666;

      background-color: #fff;
      border: 1px solid #ccc;
      padding: 6px 12px;
      transition: all 0.2s ease-in;

      display: flex;
      align-items: center;
      gap: 6px;

      ${({ theme }) => theme.mediaQueries.lg} {
        font-size: 20px;
        gap: 15px;
      }

      :hover {
        color: #666;
        background-color: #e6e6e6;
        border-color: #adadad;
      }
    }
  }
`
