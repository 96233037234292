import styled from 'styled-components'
import { flexbox } from 'styled-system'
import Box from './Box'
import { FlexProps } from './types'

interface FlexPropsCustom {
  gap?: string
  center?: boolean
}

const Flex = styled(Box)<FlexProps & FlexPropsCustom>`
  display: flex;
  gap: ${({ gap }) => gap || 0};
  ${({center}) => center && `
    align-items: center;
    justify-content: center;
  `}
  ${flexbox}
`

export default Flex
