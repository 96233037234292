import { Col, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import { TAreas, TBranches } from 'state/bank/actions'
import useGetBankData from 'state/bank/hooks/useGetBankData'

const PaymentForm = ({ form, profile }) => {
  const { setFieldsValue } = form
  const { bankList } = useGetBankData()

  const [areaList, setAreaList] = useState<TAreas[]>([])
  const [branchList, setBranchList] = useState<TBranches>([])

  const handleChangeBank = (type, val) => {
    if (type === 'bank') {
      setFieldsValue({
        area_id: undefined,
        branch_id: undefined,
        bank_name: undefined,
        bank_number: undefined,
      })
      const foundData = bankList?.find((x) => x?.id === val)
      if (foundData) setAreaList(foundData?.areas)
    }

    if (type === 'areas') {
      setFieldsValue({
        branch_id: undefined,
        bank_name: undefined,
        bank_number: undefined,
      })
      const foundData = areaList?.find((x) => x?.id === val)
      if (foundData) setBranchList(foundData?.branches)
    }
  }

  useEffect(() => {
    if (profile?.bankInfo?.bank_id && bankList?.length) {
      const foundData = bankList?.find((x) => x?.id === profile?.bankInfo?.bank_id)
      if (foundData) setAreaList(foundData?.areas)
    }
  }, [profile?.bankInfo?.bank_id, bankList])

  useEffect(() => {
    if (profile?.bankInfo?.area_id && areaList?.length) {
      const foundData = areaList?.find((x) => x?.id === profile?.bankInfo?.area_id)
      if (foundData) setBranchList(foundData?.branches)
    }
  }, [profile?.bankInfo?.area_id, areaList])

  return (
    <Form form={form} layout='horizontal' labelCol={{ span: 4, lg: { span: 3 } }} wrapperCol={{ span: 20, lg: { span: 21 } }}>
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Form.Item name='bank_id' label='Chọn ngân hàng'>
            <select className='custom-select' onChange={(e) => handleChangeBank('bank', +e?.target?.value)}>
              <option value=''>Chọn ngân hàng</option>
              {bankList?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name='area_id' label='Địa bàn'>
            <select className='custom-select' onChange={(e) => handleChangeBank('areas', +e?.target?.value)}>
              <option value=''>Chọn địa bàn</option>
              {areaList?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name='branch_id' label='Chi nhánh'>
            <select className='custom-select'>
              <option value=''>Chọn chi nhánh</option>
              {branchList?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Số tài khoản' name='bank_number'>
            <Input placeholder='Nhập số tài khoản' />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label='Tên tài khoản' name='bank_name'>
            <Input placeholder='Nhập tên tài khoản' />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default PaymentForm
