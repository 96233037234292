import { Button, Checkbox, Col, Form, InputNumber, Row } from 'antd'
import styled from 'styled-components'
import { EyeFilled } from '@ant-design/icons'
import { numberWithCommas } from 'utils'
import { useEffect, useState } from 'react'
import { bankService } from 'services'

import ModalInfoCalculate from './ModalInfoCalculate'
import ModalInfoDeposit from './ModalInfoDeposit'

const TransferScheduleForm = ({ form, profile }) => {
  const [dataConfig, setDataConfig] = useState<any>()
  const [openModalDeposit, setOpenModalDeposit] = useState<boolean>(false)
  const [openModalCalculate, setOpenModalCalculate] = useState<boolean>(false)

  useEffect(() => {
    bankService.getConfigDeposit((data) => setDataConfig(data))
  }, [])

  return (
    <>
      <Form form={form} layout='horizontal' labelCol={{ span: 4, lg: { span: 5 } }} wrapperCol={{ span: 20, lg: { span: 19 } }}>
        <Row gutter={[24, 10]}>
          <Col span={24}>
            <BoxInfo>
              <p>Số dư ký quỹ:</p>
              <div>
                <p>{numberWithCommas(profile?.marginBalance?.margin_balance)}đ</p>
                <Button type='primary' onClick={() => setOpenModalDeposit(true)}>
                  Nạp tiền vào quỹ
                </Button>
              </div>
            </BoxInfo>
          </Col>
          <Col span={24}>
            <BoxInfo>
              <p>Số dư ký quỹ khả dụng:</p>
              <div>
                <p>{numberWithCommas(profile?.marginBalance?.margin_balance_available)}đ</p>
                <Button
                  icon={<EyeFilled />}
                  style={{ backgroundColor: '#ec971f', borderColor: '#d58512', color: '#fff' }}
                  onClick={() => setOpenModalCalculate(true)}
                >
                  Xem cách tính tại đây
                </Button>
              </div>
            </BoxInfo>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Số dư ký quỹ tối thiểu'
              name='minBalance'
              rules={[{ required: true, message: 'Vui lòng nhập Số dư ký quỹ tối thiểu' }]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                controls={false}
                placeholder='100,000'
              />
            </Form.Item>
          </Col>
          <BoxCalendar span={24}>
            <Form.Item
              label='Lịch chuyển tiền trong tuần'
              name='calendar'
              rules={[{ required: true, message: 'Vui lòng chọn Lịch chuyển tiền trong tuần' }]}
              style={{ marginBottom: 0 }}
            >
              <Checkbox.Group>
                <Checkbox value='monday'>T.Hai</Checkbox>
                <Checkbox value='tuesday'>T.Ba</Checkbox>
                <Checkbox value='wednesday'>T.Tư</Checkbox>
                <Checkbox value='thursday'>T.Năm</Checkbox>
                <Checkbox value='friday'>T.Sáu</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </BoxCalendar>
        </Row>
      </Form>

      <ModalInfoDeposit
        open={openModalDeposit}
        onCancel={() => setOpenModalDeposit(false)}
        dataConfig={dataConfig}
        profile={profile}
      />
      <ModalInfoCalculate open={openModalCalculate} onCancel={() => setOpenModalCalculate(false)} profile={profile} />
    </>
  )
}

export default TransferScheduleForm

const BoxCalendar = styled(Col)`
  .ant-form-item-control-input {
    height: 100%;
  }
`

const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  row-gap: 8px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: center;
  }

  > p {
    font-size: 14px;

    &:nth-child(1) {
      font-weight: bold;

      ${({ theme }) => theme.mediaQueries.sm} {
        text-align: right;
      }

      ${({ theme }) => theme.mediaQueries.lg} {
        width: 20%;
      }
    }

    &:nth-child(2) {
      font-weight: bold;

      ${({ theme }) => theme.mediaQueries.lg} {
        width: 80%;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  button {
    display: flex;
    align-items: center;

    .anticon-cloud-download {
      margin-top: 4px;
    }

    p {
      margin-left: 4px;
    }

    .view-pc {
      display: none;

      ${({ theme }) => theme.mediaQueries.sm} {
        display: contents;
      }
    }
  }
`
