import { Button, Col, Form, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { financialService } from 'services'
import styled from 'styled-components'
import { DatePicker } from 'antd'
import moment from 'moment-timezone'

const FilterForm = ({
  setParams,
  exportFinancial,
  loadingExport,
  reconcilation_id,
}: {
  setParams: (input: any) => void
  exportFinancial: () => void
  loadingExport: boolean
  reconcilation_id: any
}) => {
  const [form] = Form.useForm()

  const [listStatus, setListStatus] = useState<any>()
  const [fromDay, setFromDay] = useState<any>()
  const [toDay, setToDay] = useState<any>()

  useEffect(() => {
    financialService.getConfig((data) => setListStatus(data?.data))
  }, [])

  const searchOrder = useCallback(() => {
    form.validateFields().then(async (values) => {
      setParams((prev) => {
        let paramsSearch: any = {
          page: prev?.page,
          per_page: prev?.per_page,
        }

        if (prev?.reconcilation_id) {
          paramsSearch.reconcilation_id = prev?.reconcilation_id
        }

        if (values?.status) {
          paramsSearch = {
            ...paramsSearch,
            status: values?.status,
          }
        }

        if (values?.payment_status) {
          paramsSearch = {
            ...paramsSearch,
            payment_status: values?.payment_status,
          }
        }

        if (values?.reconcilation_status) {
          paramsSearch = {
            ...paramsSearch,
            reconcilation_status: values?.reconcilation_status,
          }
        }

        if (values?.insurance_status) {
          paramsSearch = {
            ...paramsSearch,
            insurance_status: values?.insurance_status,
          }
        }

        if (values?.from_date) {
          paramsSearch = {
            ...paramsSearch,
            from_date: values?.from_date?.format('YYYY-MM-DD'),
          }
        }

        if (values?.to_date) {
          paramsSearch = {
            ...paramsSearch,
            to_date: values?.to_date?.format('YYYY-MM-DD'),
          }
        }

        if (values?.search_by) {
          paramsSearch = {
            ...paramsSearch,
            search_by: values?.search_by,
          }
        }

        return {
          ...paramsSearch,
        }
      })
    })
  }, [form, setParams])

  const handleFromDateChange = useCallback((e) => {
    setFromDay(e)
  }, [])

  const handleToDateChange = useCallback((e) => {
    setToDay(e)
  }, [])

  const disableToDate = (current) => {
    return fromDay ? current.isBefore(fromDay) : false
  }

  const disableFromDate = (current) => {
    return toDay ? current.isAfter(toDay) : false
  }

  useEffect(() => {
    if (!reconcilation_id) {
      const toDate = moment()
      const fromDate = moment().subtract(7, 'days')

      form.setFieldsValue({
        search_by: 'created_at',
        from_date: fromDate,
        to_date: toDate,
      })
    }
  }, [form, reconcilation_id])

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={[8, 0]}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='status'>
            <select className='custom-select'>
              <option value=''>Trạng thái đơn hàng</option>
              {listStatus?.statusOrders?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='payment_status'>
            <select className='custom-select'>
              <option value=''>Trạng thái thanh toán</option>
              {listStatus?.payments?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='reconcilation_status'>
            <select className='custom-select'>
              <option value=''>Trạng thái đối soát</option>
              {listStatus?.reconcilations?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='insurance_status'>
            <select className='custom-select'>
              <option value=''>Trạng thái bảo hiểm</option>
              {listStatus?.insurances?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 0]}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='from_date'>
            <DatePicker
              style={{ width: '100%' }}
              placeholder='Từ ngày'
              onChange={handleFromDateChange}
              disabledDate={disableFromDate}
              format='DD-MM-YYYY'
            />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='to_date'>
            <DatePicker
              style={{ width: '100%' }}
              placeholder='Đến ngày'
              onChange={handleToDateChange}
              disabledDate={disableToDate}
              format='DD-MM-YYYY'
            />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <Form.Item name='search_by'>
            <select className='custom-select'>
              <option value=''>Tìm theo</option>
              {[
                {
                  id: 'created_at',
                  title: 'Ngày tạo',
                },
                {
                  id: 'updated_at',
                  title: 'Ngày cập nhật',
                },
              ]?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'center' }}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 5 }}>
          <ActionGroup>
            <Button onClick={searchOrder}>
              <SearchOutlined /> Tìm kiếm
            </Button>
          </ActionGroup>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <ActionGroup style={{ justifyContent: 'flex-end' }}>
            <Button onClick={exportFinancial} type='primary' loading={loadingExport}>
              Xuất file excel
            </Button>
          </ActionGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default FilterForm

const ActionGroup = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 1px;
  }

  button {
    height: 40px;
  }

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
  }
`
