import { Button, Form, Input, Switch } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { NumericFormat } from 'react-number-format'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .content-list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    li {
      font-size: 14px;

      span {
        font-weight: 700;
      }
    }
  }

  .custom-label {
    .ant-form-item-label {
      overflow: visible;
      margin-bottom: 6px;
    }
  }
`

const ConfirmForm = ({
  form,
  packageSelected,
  qty,
  dataDetail,
}: {
  form: any
  packageSelected: any
  qty: number
  dataDetail?: any
}) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false)
  const [productPrice, setProductPrice] = useState<number>(0)
  const [viewMore, setViewMore] = useState<boolean>(false)

  const handleChagneConfirm = useCallback((e) => {
    setIsConfirm(e)
  }, [])

  const handleChagneProductPrice = (value) => {
    if (+value > packageSelected?.insurance * qty) {
      setProductPrice(+value * (packageSelected?.fee_insurance / 100))
    } else {
      setProductPrice(0)
    }
  }

  useEffect(() => {
    if (dataDetail && dataDetail?.product_price) {
      setIsConfirm(true)
    }
  }, [dataDetail])

  return (
    <Wrapper>
      <ul className='content-list'>
        <li>
          Miễn phí - Bảo hiểm mất mát tối đa <span>{numberWithCommas(packageSelected?.insurance)}đ</span>/1 kiện hàng.(Không tính
          trường hợp bị kiểm tra VAT bởi CQCN/QLTT)
        </li>

        {viewMore && (
          <>
            <li>
              Khách hàng mua bảo đảm hàng hóa sẽ được bồi thường tối đa 100% giá trị khai giá khi bị mất hàng, hư hỏng do lỗi vận
              chuyển.
            </li>
            <li>
              <span>ToanQuocPost.VN</span> chỉ nhận khai giá tối đa{' '}
              <span>{numberWithCommas(packageSelected?.max_product_price)}đ</span>/1 kiện hàng
            </li>
            <li>Quý khách không mua bảo đảm vui lòng không click chọn mục này để tránh bị tính phí bảo đảm hàng hóa.</li>
          </>
        )}

        <li style={{ listStyleType: 'none', textAlign: 'center' }}>
          <Button onClick={() => setViewMore((prev) => !prev)}>
            {viewMore ? (
              <>
                <ArrowUpOutlined /> Rút gọn
              </>
            ) : (
              <>
                <ArrowDownOutlined /> Xem thêm
              </>
            )}
          </Button>
        </li>
      </ul>

      <Form form={form} layout='horizontal'>
        <div className='custom-label'>
          <Form.Item label='Mua bảo đảm, phí bảo đảm là 0.5% khai giá hàng hóa' name='isConfirm'>
            <Switch onChange={handleChagneConfirm} checked={isConfirm} />
          </Form.Item>
        </div>

        {isConfirm && (
          <>
            <Form.Item style={{ display: 'none' }} name='productPrice' />
            <Form.Item
              label='Khai giá hàng hóa'
              labelCol={{ span: 4, lg: { span: 3 } }}
              wrapperCol={{ span: 20, lg: { span: 21 } }}
            >
              <NumericFormat
                className='number-format'
                allowLeadingZeros
                thousandSeparator=','
                onValueChange={(e) => {
                  form.setFieldValue('productPrice', e?.floatValue)
                  handleChagneProductPrice(e?.floatValue)
                }}
                isAllowed={(values: any) => {
                  const { floatValue } = values
                  if (floatValue) {
                    return floatValue <= 999999999999
                  }
                  return true
                }}
                placeholder='Khai giá hàng hóa'
              />
            </Form.Item>

            <Form.Item label='Phí bảo hiểm' labelCol={{ span: 4, lg: { span: 3 } }} wrapperCol={{ span: 20, lg: { span: 21 } }}>
              <Input placeholder='Nhập Tiền thu hộ' disabled value={numberWithCommas(productPrice)} />
            </Form.Item>
          </>
        )}
      </Form>
    </Wrapper>
  )
}

export default ConfirmForm
