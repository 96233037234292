import styled from 'styled-components'

const WrapperFooterPrivate = styled.div`
  background: #f2f5f7;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
`

function FooterPrivate() {
  return (
    <WrapperFooterPrivate>
      <p>
        <img width={80} src='/logo-transparent.png' alt='' />
      </p>
      <p>DRUGY - Dịch Vụ Vận Chuyển Toàn Quốc</p>
    </WrapperFooterPrivate>
  )
}

export default FooterPrivate
