import { Button, Col, Modal, Row } from 'antd'
import styled from 'styled-components'
import { DropboxOutlined, CheckOutlined } from '@ant-design/icons'

const ModalBody = styled.div`
  b {
    display: contents;
  }
`

const ModalInfo = ({ open, onCancel, packageSelected }) => {
  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          <DropboxOutlined /> Chi tiết gói
        </p>
      }
      open={open}
      footer={
        <Button onClick={onCancel} icon={<CheckOutlined />}>
          Đồng ý
        </Button>
      }
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p>{packageSelected?.description}</p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfo
