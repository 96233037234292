import TableBase from 'components/Table/TableBase'
import { LoadingOutlined } from '@ant-design/icons'

const HistoryTable = ({
  dataList,
  setParams,
  loadingList,
}: {
  dataList: any
  setParams: (input: any) => void
  loadingList: boolean
}) => {
  const columns = [
    {
      title: 'Dữ liệu',
      dataIndex: 'content',
      render: (value) => <p style={{ textAlign: 'left', fontSize: 14 }}>{JSON.stringify(value)}</p>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (value) => <p style={{ fontSize: 14 }}>{value}</p>,
    },
  ]

  return (
    <TableBase
      columns={columns}
      dataSource={dataList?.data?.logs}
      scroll={{ x: 300 }}
      rowKey={(row) => row.id}
      pagination={{
        current: dataList?.data?.meta?.current_page,
        pageSize: dataList?.data?.meta?.per_page,
        total: dataList?.data?.meta?.total,
        showSizeChanger: true,
        onChange: (page, pageSize) => {
          setParams((prev) => ({
            ...prev,
            page: page,
            per_page: pageSize,
          }))
        },
        showTotal: (total) => `Tổng ${total}`,
      }}
      loading={{
        spinning: loadingList,
        indicator: <LoadingOutlined spin />,
      }}
    />
  )
}

export default HistoryTable
