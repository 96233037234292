import { Button, Col, Form, Input, Row, Skeleton } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { supportService } from 'services'
import styled from 'styled-components'
import { BugFilled, CloudUploadOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import fetchHelper, { getAuthToken } from 'helpers/FetchHelper'
import axios from 'axios'
import { API_BASE_URL } from 'constants/index'

const { TextArea } = Input

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;

  .box-content {
    border: 1px solid rgba(102, 102, 102, 0.1);
    padding: 5px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 16px;
    }

    .info-content {
      display: flex;
      gap: 6px;

      ${({ theme }) => theme.mediaQueries.sm} {
        gap: 16px;
      }

      .wrap-avt {
        width: 40px;

        ${({ theme }) => theme.mediaQueries.sm} {
          width: 80px;
        }

        img {
          width: 100%;
        }
      }

      .wrap-info {
        flex: 1;

        .info-name {
          font-weight: bold;
          margin-bottom: 6px;
          font-size: 12px;

          ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 14px;
          }

          span {
            display: contents;
            font-style: italic;
            font-weight: 400;
            color: #666;
          }
        }

        .info-content {
          font-size: 12px;

          ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 14px;
          }
        }

        .wrap-image {
          display: flex;
          width: 100%;
          margin-top: 6px;
          gap: 4px;

          ${({ theme }) => theme.mediaQueries.sm} {
            gap: 8px;
          }

          > div {
            width: 33%;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    &.info-general {
      p {
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 14px;
        }

        span {
          display: contents;
          font-weight: bold;
        }
      }
    }
  }

  .wrap-btn-upload {
    label {
      opacity: 0;
    }

    button {
      margin-bottom: 10px;
      display: flex;
      algin-items: center;

      .anticon-cloud-upload {
        margin-top: 2px;
      }

      span {
        &:nth-child(2) {
          margin-left: 3px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .wrap-item-upload-image {
      display: flex;
      gap: 10px;

      .item-upload-image {
        width: 33%;
        position: relative;

        svg {
          position: absolute;
          top: 2px;
          right: 2px;
          color: red;
          cursor: pointer;
          font-size: 18px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.xs} {
    gap: 20px;
  }
`

const Page = () => {
  const history = useHistory()
  const { id } = useParams()
  const inputFileRef = useRef<any>(null)
  const [form] = Form.useForm()
  const auth = getAuthToken()

  const [dataDetail, setDataDetail] = useState<any>()
  const [loadingDataDetail, setLoadingDataDetail] = useState<boolean>(true)
  const [image, setImage] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getDetailSupport = useCallback(() => {
    if (id) {
      supportService.getDetail(id, (data) => {
        setDataDetail(data?.data)
        setLoadingDataDetail(false)
      })
    }
  }, [id])

  useEffect(() => {
    getDetailSupport()
  }, [getDetailSupport])

  const handleButtonClick = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click()
    }
  }, [])

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)

    if ([...image, ...files].length > 3) {
      toast.error('Bạn chỉ có thể chọn tối đa 3 tấm ảnh')
    }

    const fileListWithPreview = files.slice(0, 3).map((file: any) => ({
      uid: file.name,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      originFileObj: file,
    }))

    setImage((prev) => {
      if ([...prev, ...fileListWithPreview].length > 3) {
        return prev
      } else {
        return [...prev, ...fileListWithPreview]
      }
    })
  }

  const removeImage = (urlToRemove) => {
    const filteredImages = image.filter((item) => item.url !== urlToRemove)
    setImage(filteredImages)
  }

  const submitForm = useCallback(() => {
    form.validateFields().then(async (values) => {
      try {
        setLoading(true)

        const formData = new FormData()
        formData.append('support_id', id)
        formData.append('content', values?.content)

        if (image?.[0]) {
          formData.append('image_1', image?.[0]?.originFileObj, 'image_1.png')
        }
        if (image?.[1]) {
          formData.append('image_2', image?.[1]?.originFileObj, 'image_2.png')
        }
        if (image?.[2]) {
          formData.append('image_3', image?.[2]?.originFileObj, 'image_3.png')
        }

        const response = await axios.post(`${API_BASE_URL}/support/create-log-support`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth}`,
          },
        })

        if (response?.data?.error) {
          toast.error(response?.data?.message)
          setLoading(false)
        } else {
          getDetailSupport()
          toast.success(response?.data?.data?.message, {
            autoClose: 1000,
          })
          form.resetFields()
          setImage([])
          setLoading(false)
        }
        fetchHelper.addDefaultHeader('Content-Type', 'application/json')

        return
      } catch (error) {
        setLoading(false)
        console.error('updateAddress', error)
        toast.error('Báo hỗ trợ không thành công')
      }
    })
  }, [auth, form, getDetailSupport, id, image])

  return (
    <>
      <WrapperPage>
        {!loadingDataDetail && (
          <p
            className='title-page'
            style={{
              textAlign: 'left',
            }}
          >
            [{dataDetail?.orders?.typeSupport?.title}] {dataDetail?.orders?.date && `[Ngày ${dataDetail?.orders?.date}]`}
          </p>
        )}

        <Row gutter={[15, 10]}>
          {dataDetail?.is_fail && (
            <Col span={24}>
              <FormWrapper title='Thông báo lỗi khi tạo đơn' icon={<BugFilled />}>
                <div style={{ color: 'red' }}>{dataDetail?.note}</div>
              </FormWrapper>
            </Col>
          )}

          <Col span={24}>
            <FormWrapper>
              {!loadingDataDetail ? (
                <>
                  <Form form={form}>
                    <Row gutter={[12, 12]}>
                      <Col span={24} xl={{ span: 17 }}>
                        <Row gutter={[12, 12]}>
                          <Col span={24}>
                            <>
                              {dataDetail?.orders?.logs?.map((item) => (
                                <div key={item?.id} className='box-content'>
                                  <div className='info-content'>
                                    <div className='wrap-avt'>
                                      {item?.type === 1 ? (
                                        <img src='/images/support/avatar-2.png' alt='' />
                                      ) : (
                                        <img src='/images/support/avatar-1.png' alt='' />
                                      )}
                                    </div>

                                    <div className='wrap-info'>
                                      <p className='info-name'>
                                        {item?.user?.fullname} <span>cập nhật {item?.updated_at}</span>
                                      </p>

                                      <p className='info-content'>{item?.content}</p>

                                      <div className='wrap-image'>
                                        {item?.images?.map((item) => (
                                          <div key={item?.path}>
                                            <img src={item?.path} alt='' />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          </Col>

                          <Col span={24}>
                            <Form.Item name='content' rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}>
                              <TextArea rows={5} placeholder='Nội dung bắt buộc' />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <div className='wrap-btn-upload'>
                              <input
                                type='file'
                                multiple
                                accept='image/*'
                                style={{ display: 'none' }}
                                ref={inputFileRef}
                                onChange={handleFileChange}
                              />
                              <Button icon={<CloudUploadOutlined />} onClick={handleButtonClick}>
                                Tải đính kèm (Tối đa 3 tấm)
                              </Button>

                              <div className='wrap-item-upload-image'>
                                {image?.map((item) => (
                                  <div key={item?.url} className='item-upload-image'>
                                    <CloseOutlined onClick={() => removeImage(item.url)} />
                                    <img src={item?.url} alt='' />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>

                          <Col span={24}>
                            <ButtonGroups>
                              <Button size='large' onClick={submitForm} loading={loading}>
                                Gửi yêu cầu
                              </Button>

                              <Button size='large' onClick={() => history.goBack()} icon={<RollbackOutlined />}>
                                Trở về
                              </Button>
                            </ButtonGroups>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24} xl={{ span: 7 }}>
                        <div className='box-content info-general'>
                          <p>
                            Mã hỗ trợ: <span>{dataDetail?.orders?.id}</span>
                          </p>
                          <p>
                            Mã đơn vận: <span>{dataDetail?.orders?.order?.order_code}</span>
                          </p>
                          <p>
                            Trạng thái: <span>{dataDetail?.orders?.status_string}</span>
                          </p>
                          <p>
                            Đã tạo: <span>{dataDetail?.orders?.created_at}</span>
                          </p>
                          <p>
                            Cập nhật: <span>{dataDetail?.orders?.updated_at}</span>
                          </p>
                          <p>
                            Người yêu cầu: <span>{dataDetail?.orders?.customer?.fullname}</span>
                          </p>
                          <p>
                            Điện thoại: <span>{dataDetail?.orders?.customer?.phone_number}</span>
                          </p>
                          <p>
                            Người hỗ trợ: <span>{dataDetail?.orders?.staff?.fullname}</span>
                          </p>
                          <p>
                            Điện thoại: <span>{dataDetail?.orders?.staff?.phone}</span>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                <div style={{ marginTop: 10 }}>
                  <Skeleton active />
                </div>
              )}
            </FormWrapper>
          </Col>
        </Row>
      </WrapperPage>
    </>
  )
}

export default Page
