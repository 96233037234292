import { Button, Modal } from 'antd'
import styled from 'styled-components'
import { CloseOutlined, CalculatorOutlined } from '@ant-design/icons'
import { numberWithCommas } from 'utils'

const ModalBody = styled.div`
  b {
    display: contents;
  }
`

const ModalInfoCalculate = ({ open, onCancel, profile }) => {
  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          <CalculatorOutlined style={{ marginRight: 8 }} />
          Cách tính số dư ký quỷ khả dụng
        </p>
      }
      open={open}
      footer={
        <Button onClick={onCancel} icon={<CloseOutlined />}>
          Thoát
        </Button>
      }
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <div>
          <p>
            + Số dư ký quỹ: <b>{numberWithCommas(profile?.marginBalance?.margin_balance)}</b>đ
          </p>
          <p>
            + Số dư ký quỹ khả dụng: <b>{numberWithCommas(profile?.marginBalance?.margin_balance_available)}đ</b>
          </p>
          <p>
            Giải thích: Số dư ký quỹ khả dụng là số tiền sẽ được chuyển về cho quý Shop trong phiên chuyển tiền kế tiếp tính tới
            thời điểm hiện tại.
          </p>
          <p>Số dư ký quỹ khả dụng = Số dư ký quỹ - số dư ký quỹ tối thiểu – Trị tuyệt đối(số dư tài chính âm)</p>
          <p>
            <a>Xem đơn chưa đối soát</a>
          </p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type='primary' style={{ height: 'auto' }}>
            Xem đơn chưa đối soát
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfoCalculate
