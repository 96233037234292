import { EyeFilled } from '@ant-design/icons'
import TableBase from 'components/Table/TableBase'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const OptionAction = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

const HistoryTable = ({
  dataList,
  setParams,
  loadingList,
}: {
  dataList: any
  setParams: (input: any) => void
  loadingList: boolean
}) => {
  const columns = [
    {
      title: 'Tiêu đề',
      dataIndex: '',
      render: (_, record) => (
        <p style={{ textAlign: 'left', color: record?.color ? record?.color : 'rgba(0, 0, 0, 0.85)' }}>
          [{record?.typeSupport?.title}
          {record?.date ? ` ${record?.date}` : ''}] {record?.order?.order_code}
        </p>
      ),
    },
    {
      title: 'Trạng thái đơn',
      dataIndex: '',
      render: (_, record) => record?.order?.status_string,
    },
    {
      title: 'Đã tạo',
      dataIndex: 'created_at',
      render: (value) => value,
    },

    {
      title: 'Cập nhật',
      dataIndex: 'updated_at',
      render: (value) => value,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_string',
      render: (value) => value,
    },
    {
      title: 'Nhân viên hỗ trợ',
      dataIndex: '',
      render: (_, record) => record?.staff?.fullname,
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      render: (_, record) => (
        <OptionAction>
          <Link to={`/xem-ho-tro/${record?.id}`}>
            <EyeFilled onClick={() => true} />
          </Link>
        </OptionAction>
      ),
    },
  ]

  return (
    <TableBase
      columns={columns}
      dataSource={dataList?.data?.orders}
      scroll={{ x: 1000 }}
      rowKey={(row) => row.id}
      pagination={{
        current: dataList?.data?.meta?.current_page,
        pageSize: dataList?.data?.meta?.per_page,
        total: dataList?.data?.meta?.total,
        showSizeChanger: true,
        onChange: (page, pageSize) => {
          setParams((prev) => ({
            ...prev,
            page: page,
            per_page: pageSize,
          }))
        },
        showTotal: (total) => `Tổng ${total}`,
      }}
      loading={{
        spinning: loadingList,
        indicator: <LoadingOutlined spin />,
      }}
    />
  )
}

export default HistoryTable
