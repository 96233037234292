import { LoadingOutlined } from '@ant-design/icons'
import TableBase from 'components/Table/TableBase'
import { useState } from 'react'
import { useOrderUpdateWeigh } from 'services/swr/order'
import { numberWithCommas } from 'utils'
import { Flex } from 'widgets/Box'
import { useHistory } from 'react-router-dom'
import FilterForm from './FilterForm'

const HistoryTable = () => {
  const history = useHistory()

  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 10,
  })

  const { data, isLoading } = useOrderUpdateWeigh(params)

  const columns = [
    {
      title: 'Ngày điều chỉnh',
      dataIndex: 'created_at',
      render: (value) => value,
    },
    {
      title: 'Mã đơn',
      dataIndex: 'order_code',
      render: (value) => value,
    },
    {
      title: 'Người nhận',
      dataIndex: 'receiver',
      render: (value) => value?.full_name,
    },
    {
      title: 'Trước điều chỉnh',
      dataIndex: 'from_package',
      render: (value) => (
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <p>{value?.package_title}</p>
          <p>Khối lượng: {value?.weight / 1000}</p>
          <p>Giá vận: {numberWithCommas(value?.fee)}đ</p>
        </Flex>
      ),
    },
    {
      title: 'Sau điều chỉnh',
      dataIndex: 'to_package',
      render: (value) => (
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <p>{value?.package_title}</p>
          <p>Khối lượng: {value?.weight / 1000}</p>
          <p>Giá vận: {numberWithCommas(value?.fee)}đ</p>
        </Flex>
      ),
    },
    {
      title: 'Thu hộ',
      dataIndex: 'cod',
      render: (value, item) => (
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          <p>{numberWithCommas(value)}đ</p>
          <p>Ship: {numberWithCommas(item?.fee)}đ</p>
        </Flex>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_string',
      render: (value, item) => (
        <Flex flexDirection={'column'} alignItems={'center'}>
          <p>{value}</p>
          <p style={{ color: '#018848', cursor: 'pointer' }} onClick={() => history.push(`xem-don-hang/${item?.id}`)}>
            Xem đơn hàng
          </p>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <FilterForm setParams={setParams} />

      <TableBase
        columns={columns}
        dataSource={data?.orders}
        scroll={{ x: 1000 }}
        rowKey={(row) => row.id}
        loading={{
          spinning: isLoading || !data,
          indicator: <LoadingOutlined spin />,
        }}
        pagination={{
          current: data?.meta?.current_page,
          pageSize: data?.meta?.per_page,
          total: data?.meta?.total,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setParams((prev) => ({
              ...prev,
              page: page,
              per_page: pageSize,
            }))
          },
          showTotal: (total) => `Tổng ${total}`,
        }}
      />
    </>
  )
}

export default HistoryTable
