export const CONFIG_SEO = [
  { path: '/', name: 'DRUGY - Dịch Vụ Vận Chuyển Toàn Quốc' },
  { path: '/active-account', name: 'DRUGY - Kích hoạt tài khoản' },
  { path: '/reset-password', name: 'DRUGY - Đặt lại mật khẩu' },
  { path: '/hot-login-user', name: 'DRUGY - Đăng nhập nhanh' },
  { path: '/logger', name: 'DRUGY - Logger' },
  { path: '/ve-chung-toi', name: 'DRUGY - Về chúng tôi' },
  { path: '/dich-vu', name: 'DRUGY - Dịch vụ' },
  { path: '/tuyen-dung', name: 'DRUGY - Tuyển dụng' },
  { path: '/tin-tuc', name: 'DRUGY - Tin tức' },
  { path: '/chi-tiet/:slug', name: 'DRUGY - Chi tiết tin tức' },
  { path: '/bang-dieu-khien', name: 'DRUGY - Bảng điều khiển' },
  { path: '/theo-doi-don-van', name: 'DRUGY - Theo dõi đơn vận' },
  { path: '/lich-su-dieu-chinh-khoi-luong', name: 'DRUGY - Lịch sử điều chỉnh khối lượng' },
  { path: '/don-dieu-chinh-khoi-luong', name: 'DRUGY - Đơn điều chỉnh khối lượng' },
  { path: '/yeu-cau-cap-nhat', name: 'DRUGY - Yêu cầu cập nhật' },
  { path: '/ho-tro-khach-hang', name: 'DRUGY - Hỗ trợ khách hàng' },
  { path: '/xem-ho-tro/:id', name: 'DRUGY - Xem hỗ trợ khách hàng' },
  { path: '/lich-su-don-van', name: 'DRUGY - Lịch sử đơn nhận' },
  { path: '/bao-cao-tai-chinh', name: 'DRUGY - Báo cáo tài chính' },
  { path: '/lich-su-ky-quy', name: 'DRUGY - Lịch sử ký quỹ' },
  { path: '/lich-su-giao-dich', name: 'DRUGY - Lịch sử giao dịch' },
  { path: '/tai-khoan', name: 'DRUGY - Tài khoản' },
  { path: '/tao-don-hang', name: 'DRUGY - Tạo đơn hàng' },
  { path: '/xem-don-hang/:id', name: 'DRUGY - Xem đơn hàng' },
  { path: '/tim-kiem-don-van', name: 'DRUGY - Tìm kiếm đơn vận' },
  { path: '/in-don-hang/:id', name: 'DRUGY - In đơn hàng' },
  { path: '/tra-cuu-don-van', name: 'DRUGY - Tra cứu đơn vận' },
]
