import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import { useEffect, useState } from 'react'
import { publicService } from 'services'
import { useParams } from 'react-router-dom'
import { useMetaTags } from 'react-metatags-hook'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import { ReadOutlined } from '@ant-design/icons'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'

const News = () => {
  const { slug } = useParams()

  const [dataDetail, setDataDetail] = useState<any>()
  const [listNews, setListNews] = useState([])

  useEffect(() => {
    publicService.postList((data) => {
      setListNews(data?.data?.posts?.filter((item) => item?.slug !== slug))
    })
  }, [slug])

  useEffect(() => {
    if (slug) {
      publicService.postDetail(slug, (data) => {
        setDataDetail(data?.data?.post)
      })
    }
  }, [slug])

  useMetaTags(
    {
      title: dataDetail?.meta_title || dataDetail?.title,
      description: dataDetail?.meta_description,
      openGraph: {
        title: dataDetail?.meta_title,
        image: dataDetail?.avatar,
        site_name: dataDetail?.meta_title || dataDetail?.title,
      },
    },
    [dataDetail]
  )

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: 'Tin tức',
              href: '/tin-tuc',
            },
            {
              label: 'Chi tiết tin',
              href: '',
            },
          ]}
        />

        <Content>
          <div className='wrap-title'>
            <h1 className='title'>{dataDetail?.title}</h1>
            <p className='date'>{dataDetail?.created_at}</p>
          </div>

          <div className='wrap-content'>
            <div dangerouslySetInnerHTML={{ __html: dataDetail?.body }} />
          </div>

          <div className='list-post'>
            <p>
              <ReadOutlined /> Các bài viết liên quan khác
            </p>

            <Row gutter={[20, 20]}>
              {listNews.map((item: any) => (
                <Col span={12} md={{ span: 8 }} lg={{ span: 6 }} key={item.title}>
                  <Link to={`/chi-tiet/${item?.slug}`}>
                    <NewItem>
                      <div className='logo'>
                        <img src={item.avatar} alt={item.title} />
                      </div>
                      <p className='title'>{item.title}</p>
                      <p className='time'>{item.created_at}</p>
                    </NewItem>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default News

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;

  .wrap-title {
    margin-bottom: 5px;

    .title {
      font-weight: 400;
      font-size: 1.5em;
      text-align: center;
      color: #333;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 2em;
      }
    }

    .date {
      text-align: right;
      font-size: 0.8em;
      font-style: italic;
      font-family: 'Roboto', sans-serif;
      line-height: 1.43;
      color: #333;
      font-weight: 300;
    }
  }

  .wrap-content {
    font-size: 13px;
    color: #333;

    p {
      line-height: 1.6em;
      margin: 0 0 10px;
      margin-bottom: 5px;
    }

    img {
      max-width: 100%;
    }
  }

  .list-post {
    > p {
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
`

const NewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  .logo {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    color: #333;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    min-height: 36px;
    transition: all 0.25s ease-in;

    font-size: 14px;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 16px;
      min-height: 42px;
    }

    :hover {
      opacity: 0.8;
    }
  }

  .time {
    text-align: right;
    font-size: 12px;
    font-style: italic;
  }
`
