import { Skeleton } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import { EnvironmentOutlined } from '@ant-design/icons'

const WrapperComponent = styled.div`
  .box-info {
  }
`

const Component = ({ info, note, title, loading }: { info: any; note: any; title: any; loading: boolean }) => {
  return (
    <WrapperComponent>
      <FormWrapper title={title} icon={<EnvironmentOutlined />}>
        {!loading ? (
          <>
            <div className='box-info'>
              <p>Tên đại diện:</p>
              <p>{info?.full_name}</p>
            </div>
            <div className='box-info'>
              <p>Điện thoại:</p>
              <p>{info?.phone_number}</p>
            </div>
            {title === 'Thông tin người gửi hàng' && (
              <div className='box-info'>
                <p>Email:</p>
                <p>{info?.email}</p>
              </div>
            )}
            <div className='box-info'>
              <p>Địa chỉ:</p>
              <p>
                {info?.address}, {info?.ward?.title}, {info?.district?.title}, {info?.province?.title}
              </p>
            </div>
            <div className='box-info'>
              <p>Ghi chú:</p>
              <p>{note}</p>
            </div>
          </>
        ) : (
          <div style={{ marginTop: 10 }}>
            <Skeleton active />
          </div>
        )}
      </FormWrapper>
    </WrapperComponent>
  )
}

export default Component
