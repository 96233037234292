import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'
import FilterForm from './components/FilterForm'
import { useCallback, useEffect, useState } from 'react'
import { orderService } from 'services'
import { useLocation } from 'react-router-dom'
import * as qs from 'query-string'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
`

const Page = () => {
  const location = useLocation()
  const { statusSumary, cod, keyword } = qs.parse(location.search)

  const [dataList, setDataList] = useState<any>()
  const [loadingList, setLoadingList] = useState<boolean>(true)
  const [params, setParams] = useState<any>()
  const [listSelectedOrder, setListSelectedOrder] = useState<any[]>([])

  useEffect(() => {
    setParams(() => {
      const base: any = {
        page: 1,
        per_page: 10,
      }

      if (cod) base.statusCod = +cod
      if (statusSumary) base.statusSumary = +statusSumary
      if (keyword) base.keyword = keyword
      return base
    })
  }, [cod, keyword, statusSumary])

  const searchOrder = useCallback(() => {
    if (params) {
      setLoadingList(true)
      orderService.searchFilter(params, (data) => {
        setDataList(data)
        setLoadingList(false)
      })
    }
  }, [params])

  useEffect(() => {
    searchOrder()
  }, [searchOrder])

  useEffect(() => {
    if (keyword)
      setParams((prev) => ({
        ...prev,
        keyword,
      }))
  }, [keyword])

  return (
    <WrapperPage>
      <p className='title-page'>Tìm kiếm đơn vận</p>

      <FormWrapper>
        <FilterForm setParams={setParams} listSelectedOrder={listSelectedOrder} />

        <HistoryTable
          dataList={dataList}
          setParams={setParams}
          loadingList={loadingList}
          setListSelectedOrder={setListSelectedOrder}
          searchOrder={searchOrder}
        />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page
