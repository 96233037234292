import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import { LanguageProvider } from 'translations'
import store from './state'
import { ThemeContextProvider } from './ThemeContext'
import SwrProvider from 'services/swr/SwrProvider'
import { ConfigProvider } from "antd";
import vi_VN from "antd/lib/locale/vi_VN";
import 'moment/locale/vi';
import moment from "moment";
moment.locale('vi');
moment.updateLocale('vi', {
  monthsShort :['tháng 1', 'tháng 2', 'tháng 3', 'tháng 4', 'tháng 5', 'tháng 6', 'tháng 7', 'tháng 8','tháng 9','tháng 10','tháng 11','tháng 12']
});
const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider locale={vi_VN}>
      <Provider store={store}>
        <ThemeContextProvider>
          <LanguageProvider>
            <SwrProvider>{children}</SwrProvider>
          </LanguageProvider>
        </ThemeContextProvider>
      </Provider>
    </ConfigProvider>
  )
}

export default Providers