import { useSelector } from 'react-redux'
import { AppState } from 'state'

const useGetCustomerData = () => {
  const customerProfile = useSelector((state: AppState) => state.customer.customerProfile)

  return {
    customerProfile,
  }
}

export default useGetCustomerData
