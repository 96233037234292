import { API_BASE_URL, StatusCode } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import useSWR from 'swr'
import SWR_KEY from './constant'

export const usePublicSlider = (options = {}) => {
  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.PUBLIC_SLIDER}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR(SWR_KEY.PUBLIC_SLIDER, getData, {
    revalidateOnFocus: false,
    ...options,
  })
}
