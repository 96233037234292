export const APP_ENV = process.env.REACT_APP_ENV as string

export const API_BASE_URL = process.env.REACT_APP_API as string
export const ROOT_API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT as string

export const ROOT_DOMAIN = process.env.REACT_APP_DOMAIN as string

/** Cache key */
export const AuthCache = {
  AUTH_CACHE: 'auth-toanquoc',
  AUTH_TOKEN_CACHE: 'auth-toanquoc-token',
  AUTH_REFRESH_TOKEN_CACHE: 'auth-toanquoc-refresh-token',
  CACHE_USER_INFO: 'user-toanquoc',
}

export const StatusCode = {
  OK: 200,
  Created: 201,
  Unauthorized: 401,
  Forbidden: 403,
}
