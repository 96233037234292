import TableBase from 'components/Table/TableBase'
import { numberWithCommas } from 'utils'
import { LoadingOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const HistoryTable = ({
  dataList,
  setParams,
  loadingList,
}: {
  dataList: any
  setParams: (input: any) => void
  loadingList: boolean
}) => {
  const columns = [
    {
      title: 'Thời gian',
      dataIndex: 'updated_at',
      render: (value, record) => (
        <div style={{ textAlign: 'right' }}>
          <p>Tạo: {record?.created_at}</p>
          <p>
            Cập nhật: <b style={{ display: 'contents' }}>{value}</b>
          </p>
          <Link to={`/xem-don-hang/${record?.id}`}>Xem chi tiết</Link>
        </div>
      ),
    },
    {
      title: 'Mã đơn',
      dataIndex: 'service_code',
      render: (service_code, record) => (
        <div>
          <p>{service_code}</p>
          <p>{record?.status_string}</p>
        </div>
      ),
    },
    {
      title: 'Người nhận',
      render: (record) => (
        <div style={{ textAlign: 'left' }}>
          <p>{record?.receiver?.full_name}</p>
          <p>{record?.receiver?.phone_number}</p>
          <p>{record?.receiver?.province?.title}</p>
        </div>
      ),
    },
    {
      title: 'Tính tiền',
      dataIndex: 'price',
      render: (price, record) => (
        <div style={{ textAlign: 'right' }}>
          {/* <p>Tiền hàng: {price ? `${numberWithCommas(price)}đ` : 0}</p>
          <p>Khai giá: {record?.product_price ? `${numberWithCommas(record?.product_price)}đ` : 0}</p> */}
          <p>Thu hộ: {record?.cod ? `${numberWithCommas(record?.cod)}đ` : 0}</p>
        </div>
      ),
    },
    {
      title: 'Phí',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <p>{record?.fee ? `${numberWithCommas(record?.fee)}đ` : 0}</p>
          <p style={{ color: 'green' }}>
            <b style={{ display: 'contents' }}>{record?.who === 0 ? 'Bên gửi trả' : 'Bên nhận trả'}</b>
          </p>
          {record?.insurance > 0 && <p>Phí bảo hiểm: {numberWithCommas(record?.insurance)}đ</p>}
        </div>
      ),
    },
    {
      title: 'Khấu còn',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <strong>{record.remaining ? `${numberWithCommas(record.remaining)}đ` : 0}</strong>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      render: (record) => (
        <div>
          <p>
            <b>
              Thanh toán:{' '}
              <span style={{ display: 'contents', color: record.status_payment ? 'green' : 'red' }}>
                {record.status_payment ? 'Rồi' : 'Chưa'}
              </span>
            </b>
          </p>
          <p>
            <b>
              Đối soát:{' '}
              <span style={{ display: 'contents', color: record.status_reconciliation ? 'green' : 'red' }}>
                {record.status_reconciliation ? 'Rồi' : 'Chưa'}
              </span>
            </b>
          </p>
        </div>
      ),
    },
  ]

  return (
    <ComponentStyles>
      <TableBase
        columns={columns}
        dataSource={dataList?.data?.financialReportS}
        scroll={{ x: 1000 }}
        rowKey={(row) => row.id}
        pagination={{
          current: dataList?.data?.meta?.current_page,
          pageSize: dataList?.data?.meta?.per_page,
          total: dataList?.data?.meta?.total,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setParams((prev) => ({
              ...prev,
              page: page,
              per_page: pageSize,
            }))
          },
          showTotal: (total) => `Tổng ${total}`,
        }}
        loading={{
          spinning: loadingList,
          indicator: <LoadingOutlined spin />,
        }}
        summary={(pageData) => {
          // let priceSum = 0
          let codSum = 0
          let remainingSum = 0
          pageData.forEach(({ cod, remaining }) => {
            // priceSum += price
            codSum += cod
            remainingSum += remaining
          })
          return (
            <>
              <Table.Summary.Row className='ant-table-row ant-table-row-level-0'>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <div style={{ textAlign: 'right', padding: "15px 0" }}>
                    {/* <p>
                      Tổng tiền hàng: <b style={{ display: 'contents' }}>{priceSum ? `${numberWithCommas(priceSum)}đ` : 0}</b>
                    </p> */}
                    <p>
                      Tổng thu hộ: <b style={{ display: 'contents' }}>{codSum ? `${numberWithCommas(codSum)}đ` : 0}</b>
                    </p>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <div style={{ textAlign: 'right', padding: "15px 0" }}>
                    <p>
                      Tổng khấu còn:{' '}
                      <b style={{ display: 'contents' }}>{remainingSum ? `${numberWithCommas(remainingSum)}đ` : 0}</b>
                    </p>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1} />
              </Table.Summary.Row>
            </>
          )
        }}
      />
    </ComponentStyles>
  )
}

export default HistoryTable

const ComponentStyles = styled.div`
  .ant-table-row {
    font-size: 13px !important;

    * {
      font-size: 13px !important;
    }
  }
`
