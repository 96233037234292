import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { locationService, orderService } from 'services'
import { IProvinceData } from 'state/location/actions'
import useGetProvince from 'state/location/hooks/useGetLocation'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import { NumericFormat } from 'react-number-format'

const ModalBody = styled.div`
  padding-right: 10px;

  > div {
    padding: 0;
    box-shadow: none;
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ModalUpdateOrder = ({ open, onCancel, editData, getDetailOrder }) => {
  const [form] = Form.useForm()
  const { provinceList } = useGetProvince()

  const { setFieldsValue } = form

  const [districtList, setDistrictList] = useState<IProvinceData[]>([])
  const [wardList, setWardList] = useState<IProvinceData[]>([])
  const [hasAmountCollected, setHasAmountCollected] = useState<boolean>(true)
  const [amountCollected, setAmountCollected] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const handleChangeLocation = useCallback(
    async (type, id) => {
      if (type === 'province') {
        setFieldsValue({
          district_id: undefined,
          ward_id: undefined,
        })
        await locationService.getDistrict(id, (res) => setDistrictList(res?.districts))
        setWardList([])
      }
      if (type === 'district') {
        setFieldsValue({
          ward_id: undefined,
        })
        await locationService.getWard(id, (res) => setWardList(res?.wards))
      }
    },
    [setFieldsValue]
  )

  useEffect(() => {
    if (editData) {
      const { address, district, email, full_name, phone_number, province, ward } = editData?.receiver
      setHasAmountCollected(editData?.detail?.who)
      handleChangeLocation('province', province?.id)
      handleChangeLocation('district', district?.id)
      handleChangeLocation('ward', ward?.id)

      setFieldsValue({
        address,
        district_id: district?.id,
        email,
        full_name,
        phone_number,
        province_id: province?.id,
        ward_id: ward?.id,
        transferFee: editData?.detail?.who,
      })
    }
  }, [editData, setFieldsValue, handleChangeLocation])

  const handleChagnePrice = useCallback(() => {
    const selectedPackage = editData?.detail?.package
    const price = form.getFieldValue('orderPrice') || 0
    const qty = editData?.detail?.number_of_package
    setAmountCollected((selectedPackage?.fee || 0) * +qty + +price)
  }, [editData, form])

  useEffect(() => {
    if (editData) {
      form.setFieldValue('orderPrice', editData?.detail?.price)
      handleChagnePrice()
    }
  }, [editData, form, handleChagnePrice])

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async (values) => {
      setLoading(true)

      const dataUpdate = {
        receiver_full_name: values?.full_name,
        receiver_phone_number: values?.phone_number,
        receiver_address: values?.address,
        receiver_province: values?.province_id,
        receiver_district: values?.district_id,
        receiver_ward: values?.ward_id,
        who: values?.transferFee,
        price: values?.orderPrice,
      }

      orderService.update(
        editData?.id,
        dataUpdate,
        (response) => {
          toast.success(response?.data?.message, {
            autoClose: 1000,
          })
          setLoading(false)
          getDetailOrder()
          onCancel()
        },
        (response) => {
          toast.error(response?.message || 'Cập nhật thông tin thất bại')
          setLoading(false)
        }
      )
    })
  }, [editData?.id, form, getDetailOrder, onCancel])

  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>Cập nhật thông tin người nhận</p>}
      open={open}
      footer={false}
      centered
      onCancel={() => {
        if (!loading) {
          onCancel()
        }
      }}
      width={700}
    >
      <ModalBody>
        <FormWrapper>
          <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label='Tên đại diện'
                  name='full_name'
                  rules={[{ required: true, message: 'Vui lòng nhập Tên đại diện!' }]}
                >
                  <Input placeholder='Nhập Tên đại diện' />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label='Số điện thoại'
                  name='phone_number'
                  rules={[{ required: true, message: 'Vui lòng nhập Số điện thoại' }]}
                >
                  <Input placeholder='Nhập Số điện thoại' />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name='province_id'
                  label='Tỉnh / Thành'
                  rules={[{ required: true, message: 'Vui lòng chọn Tỉnh / Thành' }]}
                >
                  <select
                    className='custom-select'
                    onChange={(e) => {
                      handleChangeLocation('province', e?.target?.value)
                    }}
                  >
                    <option value=''>Chọn tỉnh / thành</option>
                    {provinceList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name='district_id'
                  label='Quận / Huyện'
                  rules={[{ required: true, message: 'Vui lòng chọn Quận / Huyện' }]}
                >
                  <select className='custom-select' onChange={(e) => handleChangeLocation('district', e?.target?.value)}>
                    <option value=''>Chọn quận / huyện</option>
                    {districtList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name='ward_id' label='Phường / Xã' rules={[{ required: true, message: 'Vui lòng chọn Phường / Xã' }]}>
                  <select className='custom-select'>
                    <option value=''>Chọn phường / xã</option>
                    {wardList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Địa chỉ' name='address' rules={[{ required: true, message: 'Vui lòng nhập Địa chỉ!' }]}>
                  <Input placeholder='Nhập địa chỉ' />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Phí vận chuyển' name='transferFee'>
                  <Radio.Group
                    defaultValue={editData?.detail?.who}
                    onChange={(e) => {
                      setHasAmountCollected(Boolean(e?.target?.value))
                      handleChagnePrice()
                    }}
                  >
                    <Radio value={0}>Người gửi trả</Radio>
                    <Radio value={1}>Người nhận trả</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item style={{ display: 'none' }} name='orderPrice' />
                <Form.Item label='Tiền hàng'>
                  <NumericFormat
                    className='number-format'
                    allowLeadingZeros
                    thousandSeparator=','
                    onValueChange={(e) => {
                      form.setFieldValue('orderPrice', e?.floatValue)
                      handleChagnePrice()
                    }}
                    isAllowed={(values: any) => {
                      const { floatValue } = values
                      if (floatValue) {
                        return floatValue <= 999999999999
                      }
                      return true
                    }}
                    placeholder='Nhập tiền hàng'
                    defaultValue={0}
                    value={form.getFieldValue('orderPrice')}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Tiền thu hộ'>
                  <Input
                    placeholder='Nhập Tiền thu hộ'
                    disabled
                    value={numberWithCommas(hasAmountCollected ? amountCollected : +form.getFieldValue('orderPrice'))}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <ButtonGroups>
                  <Button type='primary' size='large' onClick={handleSubmit} loading={loading}>
                    Xác nhận
                  </Button>
                  <Button
                    size='large'
                    onClick={() => {
                      if (!loading) {
                        onCancel()
                      }
                    }}
                  >
                    Thoát
                  </Button>
                </ButtonGroups>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdateOrder
