import { Carousel, Grid } from 'antd'
import React from 'react'
import styled from 'styled-components'

const { useBreakpoint } = Grid

const Wrapper = styled.section`
  width: 100%;
  background: #fff;

  .container {
    padding: 20px 15px;

    h2 {
      font-weight: bold;
      font-size: 26px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
`

const CustomCarousel = styled(Carousel as any)`
  .carousel-item {
    display: flex !important;
    justify-content: center;
    align-items: center;

    > img {
      width: 150px;
      justify-self: center;
    }
  }

  .slick-dots {
    padding-top: 24px;
    bottom: -10px;

    li {
      height: 6px;
      width: 6px;

      button {
        height: 6px;
        width: 6px;
        border-radius: 4px;
        background-color: #bfbfbf;
      }
    }

    li.slick-active {
      height: 6px;
      width: 6px;

      button {
        height: 6px;
        width: 6px;
        border-radius: 4px;
        background-color: #000;
      }
    }
  }
`

const PartnerCarousel = () => {
  const breakPoint = useBreakpoint()

  const slidesToShow = () => {
    if (breakPoint?.xl) return 6
    if (breakPoint?.lg) return 4
    if (breakPoint?.md) return 3
    return 2
  }

  return (
    <Wrapper>
      <div className='container'>
        <h2>Happy customers</h2>
        <CustomCarousel autoplay swipeToSlide draggable dots={false} slidesToShow={slidesToShow()}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
            <div className='carousel-item' key={x}>
              <img src={`/images/home/partner-slider-${x}.png`} alt='Image' />
            </div>
          ))}

          {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
            <div className='carousel-item' key={x}>
              <img src={`/images/home/partner-slider-${x}.png`} alt='Image' />
            </div>
          ))}
        </CustomCarousel>
      </div>
    </Wrapper>
  )
}

export default PartnerCarousel
