import ROUTER from 'services/router'
import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { authService } from 'services'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 233px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .car {
    transform: scale(2);

    ${({ theme }) => theme.mediaQueries.md} {
      transform: scale(3);
    }

    &__body {
      animation: shake 0.2s ease-in-out infinite alternate;
    }

    &__line {
      transform-origin: center right;
      stroke-dasharray: 22;
      animation: line 0.8s ease-in-out infinite;
      animation-fill-mode: both;

      &--top {
        animation-delay: 0s;
      }

      &--middle {
        animation-delay: 0.2s;
      }

      &--bottom {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes shake {
    0% {
      transform: translateY(-1%);
    }
    100% {
      transform: translateY(3%);
    }
  }

  @keyframes line {
    0% {
      stroke-dashoffset: 22;
    }

    25% {
      stroke-dashoffset: 22;
    }

    50% {
      stroke-dashoffset: 0;
    }

    51% {
      stroke-dashoffset: 0;
    }

    80% {
      stroke-dashoffset: -22;
    }

    100% {
      stroke-dashoffset: -22;
    }
  }
`

const CustomText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 60px;

  span {
    position: relative;
    top: 20px;
    display: inline-block;
    animation: bounce 0.3s ease infinite alternate;
    font-size: 30px;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 40px;
    }
  }

  @keyframes bounce {
    100% {
      top: -15px;
      text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc,
        0 50px 25px rgba(0, 0, 0, 0.2);
    }
  }
  span:nth-child(2) {
    animation-delay: 0.1s;
  }
  span:nth-child(3) {
    animation-delay: 0.2s;
  }
  span:nth-child(4) {
    animation-delay: 0.3s;
  }
  span:nth-child(5) {
    animation-delay: 0.4s;
  }
  span:nth-child(6) {
    animation-delay: 0.5s;
  }
  span:nth-child(7) {
    animation-delay: 0.6s;
  }
  span:nth-child(8) {
    animation-delay: 0.7s;
  }
  span:nth-child(9) {
    animation-delay: 0.8s;
  }
  span:nth-child(10) {
    animation-delay: 0.9s;
  }
`

const ActiveAccount = () => {
  const location = useLocation()
  const history = useHistory()

  const handleVerify = useCallback(
    async (payload) => {
      await authService.verify(
        payload,
        (response) => {
          toast.success(response?.data?.message, {
            autoClose: 1000,
          })
          setTimeout(() => history.push(ROUTER.adminHome), 3000)
        },
        (response) => {
          toast.error(response?.message || 'Verify failed')
          setTimeout(() => history.push('/'), 2000)
        }
      )
    },
    [history]
  )

  useEffect(() => {
    if (location.search) {
      const token = location.search.split('?token=')[1]
      setTimeout(() => handleVerify({ token }), 2000)
    }
  }, [location.search, handleVerify])

  return (
    <Wrapper>
      <CustomText>
        <span>X</span>
        <span>Á</span>
        <span>C</span>
        <span>T</span>
        <span>H</span>
        <span>Ự</span>
        <span>c</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </CustomText>

      <div className='loader'>
        <svg className='car' width='102' height='40' xmlns='http://www.w3.org/2000/svg'>
          <g
            transform='translate(2 1)'
            stroke='#002742'
            fill='none'
            fillRule='evenodd'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path
              className='car__body'
              d='M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01'
              strokeWidth='3'
            />
            <ellipse className='car__wheel--left' strokeWidth='3.2' fill='#FFF' cx='83.493' cy='30.25' rx='6.922' ry='6.808' />
            <ellipse className='car__wheel--right' strokeWidth='3.2' fill='#FFF' cx='46.511' cy='30.25' rx='6.922' ry='6.808' />
            <path className='car__line car__line--top' d='M22.5 16.5H2.475' strokeWidth='3' />
            <path className='car__line car__line--middle' d='M20.5 23.5H.4755' strokeWidth='3' />
            <path className='car__line car__line--bottom' d='M25.5 9.5h-19' strokeWidth='3' />
          </g>
        </svg>
      </div>
    </Wrapper>
  )
}

export default ActiveAccount
