import React from 'react'
import { ConfigProvider, Layout } from 'antd'
import Header from 'components/Header'
import Footer from 'components/Footer'

const PublicLayout = ({ children }) => {
  return (
    <Layout className='public-layout'>
      <ConfigProvider>
        <Header />
        <div style={{ flex: 1 }}>{children}</div>
        <Footer />
      </ConfigProvider>
    </Layout>
  )
}

export default PublicLayout
