import TableBase from 'components/Table/TableBase'
import { numberWithCommas } from 'utils'
import { Link } from 'react-router-dom'

const HistoryTable = ({ data, onPagination }) => {
  const columns = [
    {
      title: 'Tài khoản',
      dataIndex: 'customer_id',
      render: (value) => value,
    },
    {
      title: 'Nguồn quỹ',
      dataIndex: 'fund_source',
      render: (value) => value,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (value) => numberWithCommas(value),
    },
    {
      title: 'Quỹ hiện tại',
      dataIndex: 'balance_after',
      render: (value) => numberWithCommas(value),
    },
    {
      title: 'Nội dung',
      dataIndex: 'note',
      render: (value) => value,
    },
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      render: (value) => value,
    },
    {
      title: 'Thao tác',
      render: (record) => (
        <div>
          {record?.reconcilation_id && (
            <Link to={`/bao-cao-tai-chinh?reconcilation_id=${record?.reconcilation_id}`}>Chi tiết</Link>
          )}
        </div>
      ),
    },
  ]

  return (
    <TableBase
      columns={columns}
      dataSource={data?.escrowHistories}
      pagination={{
        total: data?.meta?.total,
        onChange: onPagination,
        current: data?.meta?.current_page,
        pageSizeOptions: ['10', '30', '50'],
        showTotal: (total) => `Tổng ${total}`,
      }}
      scroll={{ x: 1000 }}
      rowKey={(row) => row?.id}
      loading={!data}
    />
  )
}

export default HistoryTable
