/* eslint-disable consistent-return */
import { API_BASE_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

const search = async (payload: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/list?${qs.stringify(payload)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const create = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/create-support`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getTypeSupports = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/get-type-supports`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getDetail = async (id: number, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/detail?support_id=${id}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const createLogSupport = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/create-log-support`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getStatusList = async (cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/support/get-status`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

export default { search, create, getTypeSupports, getDetail, createLogSupport, getStatusList }
