import {
  HomeOutlined,
  FormOutlined,
  SearchOutlined,
  IdcardOutlined,
  SketchOutlined,
  UserAddOutlined,
  ProductOutlined,
  SettingOutlined,
} from '@ant-design/icons'

export interface IMenuSubEntry {
  label: string
  href: string
  target?: string
  calloutClass?: string
  icon?: any
  iconLight?: string
}

export interface IMenuEntry {
  label: string
  icon: any
  items?: IMenuSubEntry[]
  href?: string
  att?: string
  target?: string
  calloutClass?: string
  initialOpenState?: boolean
  external?: boolean
  actives: string[]
}

export const MENU_CONFIGS: IMenuEntry[] = [
  {
    label: 'Trang chủ',
    icon: HomeOutlined,
    href: '/',
    actives: ['/'],
  },
  {
    label: 'Bảng cam kết',
    icon: FormOutlined,
    href: '/ban-cam-ket.pdf',
    actives: ['/ban-cam-ket.pdf'],
    external: true,
  },
  {
    label: 'Tra cứu đơn vận',
    icon: SearchOutlined,
    href: '/tra-cuu-don-van',
    actives: ['/tra-cuu-don-van'],
  },
  {
    label: 'Về chúng tôi',
    icon: IdcardOutlined,
    href: '/ve-chung-toi',
    actives: ['/ve-chung-toi'],
  },
  {
    label: 'Dịch vụ',
    icon: SketchOutlined,
    href: '/dich-vu',
    actives: ['/dich-vu'],
  },
  {
    label: 'Tuyển dụng',
    icon: UserAddOutlined,
    href: '/tuyen-dung',
    actives: ['/tuyen-dung'],
  },
  {
    label: 'Tin tức',
    icon: ProductOutlined,
    href: '/tin-tuc',
    actives: ['/tin-tuc', '/chi-tiet/:slug'],
  },
  {
    label: 'Quản trị',
    icon: SettingOutlined,
    href: '/bang-dieu-khien',
    actives: ['/bang-dieu-khien'],
  },
]
