import { Button, Form } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ROUTER from 'services/router'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import styled from 'styled-components'
import AccountForm from './components/AccountForm'
import DeliveryTable from './components/DeliveryTable'
import PaymentForm from './components/PaymentForm'
import { authService, customerService } from 'services'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setProfile } from 'state/customer/actions'
import TransferScheduleForm from './components/TransferScheduleForm'
import ModalCheckPassword from './components/ModalCheckPassword'
import { LogoutOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .wrap-form-custom {
    position: relative;
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ButtonLogout = styled.div`
  cursor: pointer;
  top: 0;
  right: 0;
  font-weight: bold;
  text-align: right;

  ${({ theme }) => theme.mediaQueries.sm} {
    position: absolute;
    transform: translateY(calc(-100% - 8px));
  }
`

const Profile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const { setFieldsValue } = form
  const { customerProfile } = useGetCustomerData()

  const [loading, setLoading] = useState(false)
  const [modalCheckPassword, setModalCheckPassword] = useState(false)

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      const payload = {
        ...values,
        default_package_id: +values?.default_package_id || null,
        branch_id: +values?.branch_id || null,
        bank_id: +values?.bank_id || null,
        area_id: +values?.area_id || null,
        marginBalance: {
          margin_balance_min: values?.minBalance,
          monday: values?.calendar?.includes('monday'),
          tuesday: values?.calendar?.includes('tuesday'),
          wednesday: values?.calendar?.includes('wednesday'),
          thursday: values?.calendar?.includes('thursday'),
          friday: values?.calendar?.includes('friday'),
        },
      }

      setLoading(true)

      try {
        await customerService.updateProfile(
          payload,
          async (response) => {
            toast.success(response?.data?.message, {
              autoClose: 1000,
            })
            setLoading(false)
            setModalCheckPassword(false)
            form.resetFields()
            await customerService.getProfile((data) => {
              dispatch(setProfile(data?.data?.customer))
            })
          },
          (response) => {
            toast.error(response?.message || 'Cập nhật dữ liệu không thành công')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('updateProfile', error)
      }
    })
  }

  useEffect(() => {
    if (!customerProfile) return

    setFieldsValue({
      supporter: customerProfile?.support
        ? `${customerProfile?.support?.fullname} ${customerProfile?.support?.phone ? `- ${customerProfile?.support?.phone}` : ''}`
        : '',
      userCode: customerProfile?.id,
      email: customerProfile?.email,
      full_name: customerProfile?.full_name,
      phone_number: customerProfile?.phone_number,
      default_package_id: customerProfile?.default_package_id || 1,

      bank_id: customerProfile?.bankInfo?.bank_id,
      area_id: customerProfile?.bankInfo?.area_id,
      branch_id: customerProfile?.bankInfo?.branch_id,
      bank_number: customerProfile?.bankInfo?.bank_number,
      bank_name: customerProfile?.bankInfo?.bank_name,

      minBalance: customerProfile?.marginBalance?.margin_balance_min,
      calendar: customerProfile?.marginBalance
        ? Object.keys(customerProfile?.marginBalance).filter((day) => {
            return customerProfile?.marginBalance[day] === true
          })
        : null,
    })
  }, [setFieldsValue, customerProfile])

  return (
    <Wrapper>
      <FormWrapper title='Cập nhật thông tin tài khoản'>
        <div className='wrap-form-custom'>
          <ButtonLogout onClick={() => authService.logout()}>
            <LogoutOutlined /> Đăng xuất
          </ButtonLogout>
          <AccountForm form={form} />
        </div>
      </FormWrapper>

      <FormWrapper title='Cập nhật thông tin ngân hàng'>
        <PaymentForm form={form} profile={customerProfile} />
      </FormWrapper>

      <FormWrapper title='Lịch chuyển tiền ký quỹ'>
        <TransferScheduleForm form={form} profile={customerProfile} />
      </FormWrapper>

      <ButtonGroups>
        <Button type='primary' size='large' onClick={() => setModalCheckPassword(true)} loading={loading}>
          Lưu thông tin
        </Button>
        <Button size='large' onClick={() => history.push(ROUTER.adminHome)} loading={loading}>
          Về trang chủ
        </Button>
      </ButtonGroups>

      <FormWrapper title='Thông tin gửi hàng'>
        <DeliveryTable type='delivery' />
      </FormWrapper>

      <FormWrapper title='Thông tin nhận hàng'>
        <DeliveryTable type='shipping' />
      </FormWrapper>

      <ModalCheckPassword
        open={modalCheckPassword}
        onCancel={() => setModalCheckPassword(false)}
        form={form}
        onConfirm={handleSubmit}
        loading={loading}
      />
    </Wrapper>
  )
}

export default Profile
