/* eslint-disable consistent-return */
import axios from 'axios'
import { API_BASE_URL } from 'constants/index'
import fetchHelper, { getAuthToken } from 'helpers/FetchHelper'
import * as qs from 'query-string'

const getReport = async (body: any, cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/report/financial-report?${qs.stringify(body)}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const exportReport = async (body: any, cbs?: (data) => void, cbe?: (data) => void) => {
  const auth = getAuthToken()

  return await axios({
    method: 'get',
    url: `${API_BASE_URL}/report/export-financial-report`,
    params: body,
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${auth}`, // Thêm token vào headers
    },
  })
    .then((response) => {
      cbs?.(response)
    })
    .catch((error) => cbe?.(error))
}

const getConfig = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/config/list-status-reconcilation`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

export default { getReport, exportReport, getConfig }
