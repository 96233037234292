import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ProfileState {
  customerProfile: actions.ICustomerProfile | undefined
}

const initialState: ProfileState = {
  customerProfile: undefined,
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setProfile, (state, { payload }) => {
    state.customerProfile = payload
  })
)
