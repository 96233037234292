import { API_BASE_URL, StatusCode } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import useSWR from 'swr'
import SWR_KEY from './constant'
import * as qs from 'query-string'

export const useOrderReport = (options = {}) => {
  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.ORDERS_REPORT_SUMMARY}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR(SWR_KEY.ORDERS_REPORT_SUMMARY, getData, {
    revalidateOnFocus: false,
    ...options,
  })
}

export const useCODReport = (options = {}) => {
  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.ORDERS_REPORT_COD}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR(SWR_KEY.ORDERS_REPORT_COD, getData, {
    revalidateOnFocus: false,
    ...options,
  })
}

export const useOrderUpdateWeigh = (params: { page: number; per_page: number; order_code?: string }, options = {}) => {
  const str = qs.stringify(params)

  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.ORDER_UPDATE_WEIGHT}?${str}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR([SWR_KEY.ORDER_UPDATE_WEIGHT, ...Object.values(params)], getData, {
    revalidateOnFocus: false,
    ...options,
  })
}

export const useHistoryOrderUpdateWeigh = (params: { page: number; per_page: number; order_code?: string }, options = {}) => {
  const str = qs.stringify(params)

  const getData = async () =>
    await fetchHelper
      .fetch(`${API_BASE_URL}${SWR_KEY.HISTORY_ORDER_UPDATE_WEIGHT}?${str}`, {
        method: 'GET',
      })
      .then(([res, status]: any) => {
        if (status === StatusCode.OK) return res?.data
        return undefined
      })

  return useSWR([SWR_KEY.HISTORY_ORDER_UPDATE_WEIGHT, ...Object.values(params)], getData, {
    revalidateOnFocus: false,
    ...options,
  })
}
