/* eslint-disable consistent-return */
import { API_BASE_URL, AuthCache } from 'constants/index'
import fetchHelper, { setAuthToken } from 'helpers/FetchHelper'
import Cookies from 'js-cookie'
import { BehaviorSubject } from 'rxjs'

const storageUser = typeof window !== 'undefined' ? localStorage.getItem(AuthCache.AUTH_CACHE) : null
export const authSubject: any = new BehaviorSubject(storageUser ? JSON.parse(storageUser) : undefined)

const login = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/login`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        setAuthToken(resp?.data?.customer?.token)
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const logout = (isLogin = false) => {
  Cookies.remove(AuthCache.AUTH_TOKEN_CACHE)
  if (typeof window !== 'undefined') {
    localStorage.removeItem(AuthCache.AUTH_CACHE)
    if (!isLogin) window.location.href = `/`
  }
  authSubject.next(null)
}

const register = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/register`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const verify = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/verify-account`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        setAuthToken(resp?.data?.customer?.token)
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const forgotPassword = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/forgot-password`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const resetPassword = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/verify-forgot-password`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

export default { login, logout, register, verify, forgotPassword, resetPassword }
