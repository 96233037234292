import { Button, Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { orderService } from 'services'
import { numberWithCommas } from 'utils'
import QRCode from 'react-qr-code'
import Barcode from 'react-barcode'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle<{ numPages: number }>`
  @media print {
    html, body {
      height: ${(props) => props.numPages * 209}mm;
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }
  }
`

const Wrapper = styled.div`
  font-size: 14px;
  line-height: 1.42857143;
  margin: auto;

  @page {
    size: A5;
  }

  .sheet {
    margin-left: auto;
    margin-right: auto;
    padding: 6mm;
    width: 148mm;
    height: 209mm;
    margin-bottom: 0;
  }

  .border-top {
    border-top: 1px solid black;
    margin-top: 6px;
    max-width: 100% !important;
  }

  .main-content {
    p {
      margin-top: 6px;
      margin-bottom: 0px;
    }
  }

  b {
    display: contents;
  }

  .box-qr {
    width: 50%;

    svg {
      width: 150px;
      height: 150px;
    }
  }

  .box-barcode {
    text-align: center;
  }
`

const Page = () => {
  const { id } = useParams()
  const history = useHistory()

  const [dataDetail, setDataDetail] = useState<any[]>()

  useEffect(() => {
    const ids = id ? id.split(',') : []

    orderService.getListDetailPublict(
      ids,
      (data) => {
        if (data?.data) {
          setDataDetail(data?.data?.orders)
        } else {
          history.goBack()
        }
      },
      () => {
        history.goBack()
      }
    )
  }, [history, id])

  const openPrint = () => {
    const printButton: any = document.getElementById('printButton')
    printButton.style.display = 'none'
    window.print()
  }

  window.onafterprint = function () {
    const printButton: any = document.getElementById('printButton')
    printButton.style.display = 'block'
  }

  useEffect(() => {
    // Tạo thẻ link cho CSS
    const paperCss = document.createElement('link')
    paperCss.rel = 'stylesheet'
    paperCss.href = 'https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css'

    const bootstrapCss = document.createElement('link')
    bootstrapCss.rel = 'stylesheet'
    bootstrapCss.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css'

    // Thêm các thẻ link vào head
    document.head.appendChild(paperCss)
    document.head.appendChild(bootstrapCss)

    // Xóa các thẻ link khi component unmount
    return () => {
      document.head.removeChild(paperCss)
      document.head.removeChild(bootstrapCss)
    }
  }, [])

  return (
    <>
      <GlobalStyle numPages={dataDetail?.length as number} />

      <Wrapper>
        <Button
          id='printButton'
          type='primary'
          icon={<span className='glyphicon glyphicon-print' style={{ marginRight: 6 }} />}
          onClick={openPrint}
          style={{ position: 'fixed', top: 10, right: 10, zIndex: 10 }}
        >
          In File khổ giấy A5
        </Button>

        <Row>
          {dataDetail?.map((item) => (
            <Col span={24} key={item?.order_code}>
              <section className='sheet'>
                <table style={{ paddingBottom: '6px', display: 'block' }}>
                  <tbody>
                    <tr style={{ verticalAlign: 'top' }}>
                      <td className='box-qr'>
                        <QRCode value={item?.order_code || ''} />
                      </td>
                      <td>
                        <div className='box-barcode'>
                          <strong>ViettelPost.VN</strong>
                          <Barcode value={item?.order_code || ''} width={3.3} height={100} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className='border-top'>
                  <p style={{ paddingTop: 10 }}>
                    <span>
                      Ngày tạo: <b>{item?.created_at}</b>
                    </span>
                    <span style={{ fontWeight: 'bold', fontSize: '32px', marginLeft: '40px', paddingTop: '3px' }}>
                      {item?.order_code ?? ''}
                    </span>
                  </p>
                </div>
                <div className='border-top main-content'>
                  <p style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '10px' }}>NGƯỜI NHẬN HÀNG:</p>
                  <p>
                    Tên đại diện: <b style={{ fontSize: '15px' }}>{item?.receiver?.full_name ?? ''}</b>
                  </p>
                  <p>
                    Số điện thoại: <b style={{ fontSize: '15px' }}>{item?.receiver?.phone_number}</b>
                  </p>
                  <p>
                    Địa chỉ:{' '}
                    <b style={{ fontSize: '15px' }}>
                      {item?.receiver?.address}, {item?.receiver?.ward?.title}, {item?.receiver?.district?.title},{' '}
                      {item?.receiver?.province?.title}
                    </b>
                  </p>
                </div>
                <div className='border-top'>
                  <p style={{ fontWeight: 'bold', marginTop: '10px' }}>NGƯỜI GỬI:</p>
                  <p>
                    Tên đại diện: <b>{item?.sender?.full_name ?? ''}</b>
                  </p>
                  <p>
                    Số điện thoại: <b>{item?.sender?.phone_number}</b>
                  </p>
                  <p>
                    Địa chỉ:{' '}
                    <b style={{ fontSize: '15px' }}>
                      {item?.sender?.address}, {item?.sender?.ward?.title}, {item?.sender?.district?.title},{' '}
                      {item?.sender?.province?.title}
                    </b>
                  </p>
                </div>
                <div className='border-top'>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '18px', marginTop: '6px' }}>GHI CHÚ:</p>
                    <strong style={{ marginBottom: '5px', marginTop: '2px' }}>
                      KL: {item?.detail?.package?.weight / 1000}Kg
                    </strong>
                  </div>
                  <p>Sản phẩm: {item?.detail?.title ?? ''}</p>
                  <p>Shop: {item?.detail?.view_product === 1 ? 'cho khách xem hàng' : 'không được xem hàng'}</p>
                  <p>Gặp sự cố liên hệ người gửi: {item?.sender?.phone_number}</p>
                  <p style={{ marginTop: '15px', fontSize: '30px' }}>
                    Tiền thu hộ: <b>{numberWithCommas(item?.cod)}</b>đ
                  </p>
                </div>
              </section>
            </Col>
          ))}
        </Row>
      </Wrapper>
    </>
  )
}

export default Page
