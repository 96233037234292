import { useDispatch, useSelector } from 'react-redux'
import { locationService } from 'services'
import { AppState } from 'state'
import { setProvinceList } from '../actions'
import { useCallback } from 'react'

const useGetProvince = () => {
  const dispatch = useDispatch()
  const provinceList = useSelector((state: AppState) => state.location.provinceList)

  const fetchProvince = useCallback(() => {
    locationService.getProvince((res) => dispatch(setProvinceList(res?.data?.provinces)))
  }, [dispatch])

  return {
    fetchProvince,
    provinceList,
  }
}

export default useGetProvince
