import { Button, Col, Modal, Row } from 'antd'
import styled from 'styled-components'
import { WarningOutlined } from '@ant-design/icons'

const ModalBody = styled.div`
  b {
    display: contents;
  }
`

const ModalError = ({ open, onCancel, text }) => {
  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          <WarningOutlined /> Thông báo
        </p>
      }
      open={open}
      footer={<Button onClick={onCancel}>Thoát</Button>}
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p>{text}</p>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalError
