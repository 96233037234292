import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  IdcardOutlined,
  LockOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Image, Input, Modal, Row, Upload } from 'antd'
import axios from 'axios'
import { API_BASE_URL } from 'constants/index'
import fetchHelper, { getAuthToken } from 'helpers/FetchHelper'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import { toast } from 'react-toastify'
import { configService, customerService } from 'services'
import { TAreas, TBanks } from 'state/bank/actions'
import useGetBankData from 'state/bank/hooks/useGetBankData'
import styled from 'styled-components'
import { Skeleton } from 'widgets/Skeleton'

const WrapperModalVerifyAccount = styled.div`
  .canvas-combine {
    position: absolute;
    z-index: -9999;
    left: -999999px;
  }

  img {
    width: 100%;
    pointer-events: none;
  }

  .content-modal {
    position: relative;

    .signatureCanvas {
      position: absolute;
      width: 33%;
      height: 16%;
      border: 1px solid black;
    }

    .rs-sig {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .form-kyc {
    form {
      display: block;
      width: 100%;

      label {
        margin-bottom: 2px !important;

        @media (min-width: 576px) {
          margin-bottom: -5px !important;
        }
      }

      .ant-upload-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        text-align: center;
        width: 180px;
        height: 180px;

        .ant-upload-list-picture-card-container {
          width: 180px;
          height: 180px;

          .ant-upload-list-item-info::before {
            left: 0;
          }
        }
      }

      .item-upload-image {
        display: flex;
        align-items: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding-bottom: 8px;

        > p {
          padding-top: 8px;
          padding-bottom: 8px;
          text-align: center;
        }
      }

      .ant-form-item-label {
        padding-bottom: 0;
      }

      .ant-form-item-explain-error {
        font-size: 12px;
      }

      .ant-input-number {
        width: 100%;
        height: 32px;

        .ant-input-number-handler-wrap {
          display: none;
        }
      }

      .ant-select-selector,
      .ant-picker.ant-picker-disabled,
      .ant-picker-input > input[disabled],
      .ant-input-number {
        background: #fff !important;
        color: rgba(0, 0, 0, 0.85) !important;
      }

      .bank_name {
        input {
          &:disabled {
            background-color: #ebebe4 !important;
          }
        }
      }
    }
  }

  .wrap-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  .wrap-description-action {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    p {
      font-style: italic;
    }
  }
`

const loadImage = (imageLoad) => {
  return new Promise((resolve, reject) => {
    imageLoad.onload = () => resolve(imageLoad)
    imageLoad.onerror = (error) => reject(error)
  })
}

const removeVietnameseTones = (str: string) => {
  if (!str) return ''
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '')
  str = str.replace(/\u02C6|\u0306|\u031B/g, '')
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  // eslint-disable-next-line no-useless-escape
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
  return str
}

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const ModalVerifyAccount = ({
  modalData,
  setModalData,
  dataUser,
}: {
  modalData: {
    toggle: boolean
    data: any
  }
  setModalData: (input: any) => void
  dataUser: any
}) => {
  const sigCanvas = useRef<any>({})
  const [form] = Form.useForm()
  const auth = getAuthToken()
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const { bankList: dataBank } = useGetBankData()

  const [dataConfig, setDataConfig] = useState<any>()
  const [imageSig, setImageSig] = useState<string | null>('')
  const [step, setStep] = useState(0)
  const [selectedBank, setSelectedBank] = useState<number | null>()
  const [selectedArea, setSelectedArea] = useState<number | null>()
  const [position, setPosition] = useState<{
    bottom: number
    left: number
  }>({
    bottom: 4,
    left: 12,
  })
  const [isSigAgain, setIsSigAgain] = useState<boolean>(false)
  const [isSigFirst, setIsSigFirst] = useState<boolean>(true)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [imageFront, setImageFront] = useState<any[]>([])
  const [imageBack, setImageBack] = useState<any[]>([])
  const [imageSelfie, setImageSelfie] = useState<any[]>([])
  const [isChangeFront, setIsChangeFront] = useState<'notChange' | 'changed' | 'null'>('notChange')
  const [isChangeBack, setIsChangeBack] = useState<'notChange' | 'changed' | 'null'>('notChange')
  const [isChangeSelfie, setIsChangeSelfie] = useState<'notChange' | 'changed' | 'null'>('notChange')
  const [dataKYC, setDataKYC] = useState<any>()
  const [disabledForm, setDisabledForm] = useState<boolean>(false)
  const [isSigned, setIsSigned] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  useEffect(() => {
    if (modalData?.data?.status_kyc === 1 || modalData?.data?.status_kyc === 3) {
      setDisabledForm(true)
    }
  }, [modalData?.data?.status_kyc])

  useEffect(() => {
    if ((modalData?.data?.status_kyc === 1 && disabledForm) || (modalData?.data?.status_kyc === 3 && disabledForm)) {
      setStep(1)
      setIsSigFirst(false)

      customerService.GetKYC((response) => {
        setDataKYC(response?.data?.kyc)

        setImageFront([
          {
            url: response?.data?.kyc?.imageFront,
            preview: response?.data?.kyc?.imageFront,
          },
        ])
        setImageBack([
          {
            url: response?.data?.kyc?.imageBack,
            preview: response?.data?.kyc?.imageBack,
          },
        ])
        setImageSelfie([
          {
            url: response?.data?.kyc?.imageSelfie,
            preview: response?.data?.kyc?.imageSelfie,
          },
        ])
        setImageSig(response?.data?.kyc?.imageSignature)
      })
    } else {
      setImageFront([])
      setImageBack([])
      setImageSelfie([])
      setImageSig(null)
    }
  }, [disabledForm, modalData?.data?.status_kyc])

  useEffect(() => {
    if (dataKYC && step) {
      form?.setFieldsValue({
        full_name: dataKYC?.full_name,
        id_number: dataKYC?.id_number,
        bank_id: dataKYC?.bank_id,
        area_id: dataKYC?.area_id,
        branch_id: dataKYC?.branch_id,
        branch: dataKYC?.branch,
        bank_name: dataKYC?.bank_name,
        bank_number: dataKYC?.bank_number,
      })
      if (dataKYC?.id_number_date) {
        form?.setFieldsValue({
          id_number_date: moment(dataKYC?.id_number_date),
        })
      }
      setSelectedBank(dataKYC?.bank_id)
      setSelectedArea(dataKYC?.area_id)
      setImageFront([
        {
          url: dataKYC?.imageFront,
          preview: dataKYC?.imageFront,
        },
      ])
      setImageBack([
        {
          url: dataKYC?.imageBack,
          preview: dataKYC?.imageBack,
        },
      ])
      setImageSelfie([
        {
          url: dataKYC?.imageSelfie,
          preview: dataKYC?.imageSelfie,
        },
      ])
    } else {
      form?.setFieldsValue({
        full_name: dataUser?.full_name,
        bank_name: removeVietnameseTones(dataUser?.full_name.toString())?.toUpperCase(),
      })
    }
  }, [dataKYC, dataUser?.full_name, form, step])

  const handleOk = () => {
    setModalData({
      toggle: false,
      data: null,
    })
  }

  const handleCancel = () => {
    setModalData({
      toggle: false,
      data: null,
    })
  }

  useEffect(() => {
    configService.getConfig((data) => {
      setDataConfig(data?.data)
      setPosition({
        bottom: data?.data?.positionSignatureBottom,
        left: data?.data?.positionSignatureLeft,
      })
    })
  }, [])

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleImageLoad = () => {
    const canvas = sigCanvas.current.getCanvas()
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
  }

  const base64ToBlob = (base64: string): Blob => {
    const byteString = atob(base64.split(',')[1])
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]

    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uintArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i)
    }

    return new Blob([arrayBuffer], { type: mimeString })
  }

  const onFinish = async (e) => {
    if (modalData?.data?.status_kyc === 1 || modalData?.data?.status_kyc === 2) {
      return
    }

    if (
      imageSig &&
      ((imageFront?.[0]?.base64 && imageBack?.[0]?.base64 && imageSelfie?.[0]?.base64) ||
        (modalData?.data?.status_kyc === 3 && isChangeFront !== 'null' && isChangeBack !== 'null' && isChangeSelfie !== 'null'))
    ) {
      setLoadingSubmit(true)

      const formData = new FormData()
      formData.append('full_name', e.full_name)
      formData.append('id_number', e.id_number)
      formData.append('id_number_date', e.id_number_date.format('YYYY-MM-DD'))
      formData.append('bank_id', e.bank_id)
      if (e.bank_name) {
        formData.append('area_id', e.area_id)
      }
      if (e.branch_id) {
        formData.append('branch_id', e.branch_id)
      }
      formData.append('bank_name', e.bank_name)
      formData.append('bank_number', e.bank_number)
      formData.append('password', e.password)

      const signatureBlob = base64ToBlob(imageSig)

      if (modalData?.data?.status_kyc === 3) {
        if (isChangeFront === 'changed') {
          formData.append('id_front', imageFront?.[0]?.originFileObj, 'id_front.png')
        }
        if (isChangeBack === 'changed') {
          formData.append('id_back', imageBack?.[0]?.originFileObj, 'id_back.png')
        }
        if (isChangeSelfie === 'changed') {
          formData.append('selfie', imageSelfie?.[0]?.originFileObj, 'selfie.png')
        }
      } else {
        formData.append('id_front', imageFront?.[0]?.originFileObj, 'id_front.png')
        formData.append('id_back', imageBack?.[0]?.originFileObj, 'id_back.png')
        formData.append('selfie', imageSelfie?.[0]?.originFileObj, 'selfie.png')
      }

      formData.append('signature', signatureBlob, 'signature.png')

      const response = await axios.post(`${API_BASE_URL}/customer/request-kyc`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth}`, // Make sure 'token' is defined
        },
      })

      if (response?.data?.error) {
        toast.error(response?.data?.message)
        setLoadingSubmit(false)
      } else {
        toast.success(response?.data?.data?.message || 'Gửi yêu cầu xác thực thành công', {
          autoClose: 1000,
        })
        setLoadingSubmit(false)
        setModalData({
          toggle: false,
          data: null,
        })
      }

      fetchHelper.addDefaultHeader('Content-Type', 'application/json')
    }
  }

  const hanldeCombineImages = async () => {
    const canvas = canvasRef.current

    const canvasSig = sigCanvas.current.getCanvas()
    const signatureURL = canvasSig.toDataURL('image/png')

    if (!canvas) {
      console.error('Canvas is null or undefined')
      return
    }

    const context = canvas.getContext('2d')

    if (!context) {
      console.error('Canvas 2D context is not available')
      return
    }

    const base64Image1 = `data:image/png;base64,${dataConfig?.imageFileCommitment}`
    const base64Image2 = `${signatureURL}`

    const image1 = new window.Image()
    const image2 = new window.Image()

    image1.src = base64Image1
    image2.src = base64Image2

    await Promise.all([loadImage(image1), loadImage(image2)])
      .then((images: any) => {
        // Set canvas dimensions to fit both images
        canvas.width = Math.max(images[0].width)
        canvas.height = images[0].height

        // Calculate the new dimensions for images[1]
        const newWidth = images[0].width * 0.33
        const newHeight = images[0].height * 0.16

        // Calculate the position for images[1]
        const xPos = images[0].width * (position?.left / 100)
        const yPos = images[0].height - newHeight - images[0].height * (position?.bottom / 100)

        // Draw the images onto the canvas
        context.drawImage(images[0], 0, 0)
        context.drawImage(images[1], xPos, yPos, newWidth, newHeight)

        // Optionally, perform additional drawing or manipulation

        // Set URL image
        const combinedImageURL = canvas.toDataURL('image/png')
        setImageSig(combinedImageURL)
      })
      .catch((error) => {
        console.error('Error loading images:', error)
      })
  }

  const handleDownload = () => {
    if (imageSig) {
      const downloadLink = document.createElement('a')
      downloadLink.href = imageSig
      downloadLink.download = 'combined-signature.png'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  const scrollToTop = () => {
    const modalWrap = document.querySelector('.ant-modal-wrap')
    if (modalWrap) {
      modalWrap.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Modal
      title={
        <p style={{ fontSize: '24px' }}>
          <IdcardOutlined /> {modalData?.data?.status_kyc === 1 && 'Đang xác'}{' '}
          {(modalData?.data?.status_kyc === 0 || modalData?.data?.status_kyc === 3) && 'Xác'} thực tài khoản{' '}
          {modalData?.data?.status_kyc === 3 && disabledForm && 'thất bại'}
        </p>
      }
      open={modalData?.toggle}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      footer={false}
    >
      <WrapperModalVerifyAccount>
        {modalData?.data?.status_kyc === 3 && (
          <p style={{ marginBottom: '20px' }}>
            Lí do: <span style={{ color: 'red', display: 'contents' }}>{modalData?.data?.note_status_kyc}</span>
          </p>
        )}

        <div className='canvas-combine'>
          <canvas style={{ width: '100%' }} ref={canvasRef} />
        </div>

        {!dataConfig ? (
          <Skeleton width={'100%'} height={700} />
        ) : step === 0 && dataConfig?.imageFileCommitment ? (
          <div className='content-modal'>
            <img
              className='rs-sig'
              style={{ zIndex: imageSig ? '3' : '0' }}
              src={`${imageSig}`}
              onLoad={handleImageLoad}
              alt=''
            />

            <img src={`data:image/png;base64,${dataConfig?.imageFileCommitment}`} onLoad={handleImageLoad} alt='' />
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: 'signatureCanvas',
                style: {
                  bottom: `${position?.bottom}%`,
                  left: `${position?.left}%`,
                  zIndex: imageSig ? '0' : '3',
                },
              }}
              onEnd={() => setIsSigned(true)}
            />

            <img
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-119%, 1350%)',
                width: '25%',
                opacity: '0.2',
              }}
              src='/images/home/text-ky.png'
              alt=''
            />
          </div>
        ) : (
          <div className='form-kyc'>
            <fieldset disabled={disabledForm}>
              <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish} autoComplete='new-password'>
                <Row gutter={[30, 0]}>
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item
                      label='Họ và tên'
                      name='full_name'
                      rules={[{ required: true, message: 'Vui lòng nhập Họ và tên!' }]}
                    >
                      <Input
                        placeholder='Nhập Họ và tên'
                        onChange={(e) => {
                          form?.setFieldsValue({
                            bank_name: removeVietnameseTones(e?.target?.value?.toString())?.toUpperCase(),
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item label='Số CCCD' name='id_number' rules={[{ required: true, message: 'Vui lòng nhập Số CCCD!' }]}>
                      <Input placeholder='Nhập Số CCCD' />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item
                      label='Ngày cấp CCCD'
                      name='id_number_date'
                      rules={[{ required: true, message: 'Vui lòng chọn Ngày cấp CCCD!' }]}
                    >
                      <DatePicker
                        placeholder='Chọn Ngày cấp'
                        format='DD-MM-YYYY'
                        style={{ width: '100%' }}
                        disabled={modalData?.data?.status_kyc === 1}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item
                      name='bank_id'
                      label='Ngân hàng'
                      hasFeedback
                      rules={[{ required: true, message: 'Vui lòng Chọn Ngân hàng!' }]}
                    >
                      <select
                        className='custom-select'
                        onChange={(e) => {
                          setSelectedBank(+e?.target?.value)
                          form?.setFieldsValue({
                            area_id: null,
                            branch_id: null,
                          })
                        }}
                        disabled={disabledForm}
                      >
                        <option value=''>Chọn Ngân hàng!</option>
                        {dataBank?.map((item) => (
                          <option value={item?.id} key={item?.id}>
                            {item?.title}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  </Col>
                  {(dataBank?.find((item) => item?.id === selectedBank) as TBanks)?.areas?.length > 0 && (
                    <Col span={24} lg={{ span: 12 }}>
                      <Form.Item
                        name='area_id'
                        label='Địa bàn'
                        hasFeedback
                        rules={[{ required: true, message: 'Vui lòng Chọn Địa bàn!' }]}
                      >
                        <select
                          className='custom-select'
                          onChange={(e) => {
                            setSelectedArea(+e?.target?.value)
                            form?.setFieldsValue({
                              branch_id: null,
                            })
                          }}
                          disabled={disabledForm}
                        >
                          <option value=''>Chọn Địa bàn!</option>
                          {dataBank
                            ?.find((item) => item?.id === selectedBank)
                            ?.areas?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.title}
                              </option>
                            ))}
                        </select>
                      </Form.Item>
                    </Col>
                  )}
                  {(
                    (dataBank?.find((item) => item?.id === selectedBank) as TBanks)?.areas?.find(
                      (item) => item?.id === selectedArea
                    ) as TAreas
                  )?.branches?.length > 0 && (
                    <Col span={24} lg={{ span: 12 }}>
                      <Form.Item
                        name='branch_id'
                        label='Chi nhánh'
                        hasFeedback
                        rules={[{ required: true, message: 'Vui lòng Chọn Chi nhánh!' }]}
                      >
                        <select className='custom-select' disabled={disabledForm}>
                          <option value=''>Chọn Chi nhánh!</option>
                          {dataBank
                            ?.find((item) => item?.id === selectedBank)
                            ?.areas?.find((item) => item?.id === selectedArea)
                            ?.branches?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.title}
                              </option>
                            ))}
                        </select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item
                      label='Số tài khoản'
                      name='bank_number'
                      rules={[{ required: true, message: 'Vui lòng nhập Số tài khoản!' }]}
                    >
                      <Input placeholder='Nhập Số tài khoản' />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Form.Item label='Tên chủ tài khoản' name='bank_name' className='bank_name'>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  {modalData?.data?.status_kyc !== 1 && (
                    <Col span={24} lg={{ span: 12 }}>
                      <Form.Item
                        label='Mật khẩu hiện tại'
                        name='password'
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại!' }]}
                      >
                        <Input.Password
                          prefix={<LockOutlined />}
                          placeholder='Nhập mật khẩu!'
                          autoComplete='new-password'
                          name='new-password'
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>

                <Row gutter={[30, 0]}>
                  <Col span={24} md={{ span: 12 }} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                    <div className='item-upload-image'>
                      <p>Ảnh CCCD mặt trước</p>
                      <Upload
                        disabled={disabledForm}
                        beforeUpload={() => false}
                        listType='picture-card'
                        fileList={imageFront}
                        onPreview={handlePreview}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                        onChange={async ({ fileList }) => {
                          const newFileList = await Promise.all(
                            fileList.map(async (file: any) => {
                              if (file.originFileObj instanceof File && !file.url) {
                                // Convert file to base64
                                const base64 = await getBase64(file.originFileObj)
                                file.base64 = base64 // Attach base64 to file object
                              }
                              return file
                            })
                          )
                          setImageFront(newFileList)

                          if (fileList?.length > 0) {
                            setIsChangeFront('changed')
                          } else {
                            setIsChangeFront('null')
                          }
                        }}
                        accept='.jpg,.jpeg,.png'
                      >
                        {imageFront.length >= 1 ? null : (
                          <button style={{ border: 0, background: 'none' }} type='button'>
                            <div style={{ marginTop: 8 }}>
                              <PlusOutlined />
                            </div>
                          </button>
                        )}
                      </Upload>
                      {imageFront.length && !(modalData?.data?.status_kyc === 3 && disabledForm) ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setImageFront([])
                            setIsChangeFront('changed')
                          }}
                        >
                          Xóa
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
                    <div className='item-upload-image'>
                      <p>Ảnh CCCD mặt sau</p>
                      <Upload
                        disabled={disabledForm}
                        beforeUpload={() => false}
                        listType='picture-card'
                        fileList={imageBack}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                        onPreview={handlePreview}
                        onChange={async ({ fileList }) => {
                          const newFileList = await Promise.all(
                            fileList.map(async (file: any) => {
                              if (file.originFileObj instanceof File && !file.url) {
                                // Convert file to base64
                                const base64 = await getBase64(file.originFileObj)
                                file.base64 = base64 // Attach base64 to file object
                              }
                              return file
                            })
                          )
                          setImageBack(newFileList)

                          if (fileList?.length > 0) {
                            setIsChangeBack('changed')
                          } else {
                            setIsChangeBack('null')
                          }
                        }}
                        accept='.jpg,.jpeg,.png'
                      >
                        {imageBack.length >= 1 ? null : (
                          <button style={{ border: 0, background: 'none' }} type='button'>
                            <div style={{ marginTop: 8 }}>
                              <PlusOutlined />
                            </div>
                          </button>
                        )}
                      </Upload>
                      {imageBack.length && !(modalData?.data?.status_kyc === 3 && disabledForm) ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setImageBack([])
                            setIsChangeBack('changed')
                          }}
                        >
                          Xóa
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }} style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderTop: 'none' }}>
                    <div className='item-upload-image'>
                      <p>Ảnh chụp khuôn mặt</p>
                      <Upload
                        disabled={disabledForm}
                        beforeUpload={() => false}
                        listType='picture-card'
                        fileList={imageSelfie}
                        showUploadList={{
                          showRemoveIcon: false,
                        }}
                        onPreview={handlePreview}
                        onChange={async ({ fileList }) => {
                          const newFileList = await Promise.all(
                            fileList.map(async (file: any) => {
                              if (file.originFileObj instanceof File && !file.url) {
                                // Convert file to base64
                                const base64 = await getBase64(file.originFileObj)
                                file.base64 = base64 // Attach base64 to file object
                              }
                              return file
                            })
                          )
                          setImageSelfie(newFileList)

                          if (fileList?.length > 0) {
                            setIsChangeSelfie('changed')
                          } else {
                            setIsChangeSelfie('null')
                          }
                        }}
                        accept='.jpg,.jpeg,.png'
                      >
                        {imageSelfie.length >= 1 ? null : (
                          <button style={{ border: 0, background: 'none' }} type='button'>
                            <div style={{ marginTop: 8 }}>
                              <PlusOutlined />
                            </div>
                          </button>
                        )}
                      </Upload>
                      {imageSelfie.length && !(modalData?.data?.status_kyc === 3 && disabledForm) ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setImageSelfie([])
                            setIsChangeSelfie('changed')
                          }}
                        >
                          Xóa
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col span={24} md={{ span: 12 }} style={{ border: '1px solid rgba(0, 0, 0, 0.05)', borderTop: 'none' }}>
                    <div className='item-upload-image'>
                      <p>Ảnh file cam kết có chữ ký</p>

                      <div style={{ width: 172, height: 140 }}>
                        {imageSig && (
                          <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={imageSig} alt='' />
                        )}
                      </div>

                      {modalData?.data?.status_kyc !== 1 && !(modalData?.data?.status_kyc === 3 && disabledForm) ? (
                        <div style={{ textAlign: 'center' }}>
                          <Button type='primary' onClick={handleDownload}>
                            Tải về
                          </Button>
                        </div>
                      ) : (
                        <div style={{ textAlign: 'center' }}>
                          <p
                            className='ant-btn ant-btn-primary'
                            onClick={() => {
                              handlePreview({
                                url: imageSig,
                              })
                            }}
                          >
                            xem
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </fieldset>

            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible: boolean | ((prevState: boolean) => boolean)) => setPreviewOpen(visible),
                }}
                src={previewImage}
              />
            )}
          </div>
        )}

        <div className='wrap-action'>
          {modalData?.data?.status_kyc === 3 && disabledForm && (
            <Button
              type='primary'
              onClick={() => {
                setDisabledForm(false)
                setIsSigFirst(true)
                setIsSigAgain(false)
                setStep(0)
                setIsSigned(false)
              }}
            >
              <EditOutlined /> Làm lại xác thực
            </Button>
          )}
          {step === 0 ? (
            <>
              <Button
                type='default'
                onClick={() => {
                  sigCanvas.current.clear()
                  setImageSig(null)
                  setIsSigAgain(true)
                  setIsSigned(false)
                }}
              >
                <EditOutlined /> Ký lại
              </Button>
              <Button
                type='primary'
                onClick={async () => {
                  if ((!imageSig && isSigAgain) || isSigFirst) {
                    await hanldeCombineImages()
                    setIsSigAgain(false)
                    setIsSigFirst(false)
                  }
                  setStep(1)
                  scrollToTop()
                }}
                disabled={!isSigned}
              >
                <CheckOutlined /> Tiếp Tục
              </Button>
            </>
          ) : (
            <>
              {!disabledForm && (modalData?.data?.status_kyc === 0 || modalData?.data?.status_kyc === 3) && (
                <>
                  <Button
                    type='default'
                    onClick={() => {
                      setStep(0)
                      scrollToTop()
                    }}
                  >
                    <ArrowLeftOutlined /> Xem cam kết
                  </Button>
                  <Button type='primary' onClick={() => form?.submit()} loading={loadingSubmit}>
                    <SendOutlined /> Gửi xác thực
                  </Button>
                </>
              )}
            </>
          )}
          {modalData?.data?.status_kyc !== 3 && (
            <Button type='default' onClick={handleCancel}>
              <CloseOutlined /> Thoát
            </Button>
          )}
        </div>

        {modalData?.data?.status_kyc === 3 && disabledForm && (
          <div className='wrap-description-action'>
            <p>Bạn cần phải thực hiện lại thao tác xác thực từ đầu để hoàn thành việc xác thực.</p>
          </div>
        )}
      </WrapperModalVerifyAccount>
    </Modal>
  )
}

export default ModalVerifyAccount
