import { API_BASE_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'
import * as qs from 'query-string'

const getProvince = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/location/province`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getDistrict = async (id: number | string, cbs?: (data) => void, cbe?: (data) => void) => {
  const param = qs.stringify({
    province_id: id,
  })
  return await fetchHelper
    .fetch(`${API_BASE_URL}/location/district?${param}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp?.data)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const getWard = async (id: number | string, cbs?: (data) => void, cbe?: (data) => void) => {
  const param = qs.stringify({
    district_id: id,
  })
  return await fetchHelper
    .fetch(`${API_BASE_URL}/location/ward?${param}`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp?.data)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

export default { getProvince, getDistrict, getWard }
