import { Button, Col, Form, Input, Row } from 'antd'
import ModalAddress from 'components/Modal/ModalAddress'
import { useCallback, useEffect, useState } from 'react'
import { locationService } from 'services'
import { IProvinceData } from 'state/location/actions'
import useGetProvince from 'state/location/hooks/useGetLocation'
import ModalChooseAddress from './ModalChooseAddress'

const { TextArea } = Input

const DeliveryForm = ({ form, editData = undefined, formType, dataDetail }) => {
  const { setFieldsValue } = form
  const { provinceList } = useGetProvince()

  const [districtList, setDistrictList] = useState<IProvinceData[]>([])
  const [wardList, setWardList] = useState<IProvinceData[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [count, setCount] = useState(0)

  const [modalModify, setModalModify] = useState({
    open: false,
    data: undefined,
  })

  const handleShowModal = () => {
    setIsModalOpen((prev) => !prev)
  }

  const handleShowModify = (itemData) => {
    setModalModify((prev) => ({
      open: !prev.open,
      data: itemData,
    }))
  }

  const handleCloseModalModify = () =>
    setModalModify({
      open: false,
      data: undefined,
    })

  const handleChooseAddress = async (type, address) => {
    handleChangeLocation('province', address?.province_id)
    handleChangeLocation('district', address?.district_id)

    setFieldsValue({
      [`${type}_full_name`]: address?.full_name,
      [`${type}_phone_number`]: address?.phone_number,
      [`${type}_email`]: address?.email,
      [`${type}_province_id`]: address?.province_id,
      [`${type}_district_id`]: address?.district_id,
      [`${type}_ward_id`]: address?.ward_id,
      [`${type}_address`]: address?.address,
    })

    if (type === 'delivery') {
      setFieldsValue({
        [`note`]: `Gọi trước khi qua lấy hàng - Điện thoại: ${address?.phone_number}`,
      })
    }

    handleShowModal()
  }

  const handleChangeLocation = useCallback(
    async (type, id) => {
      if (type === 'province') {
        setFieldsValue({
          district_id: undefined,
          ward_id: undefined,
        })
        await locationService.getDistrict(id, (res) => setDistrictList(res?.districts))
        setWardList([])
      }
      if (type === 'district') {
        setFieldsValue({
          ward_id: undefined,
        })
        await locationService.getWard(id, (res) => setWardList(res?.wards))
      }
    },
    [setFieldsValue]
  )

  useEffect(() => {
    if (editData) {
      const { address, district_id, email, full_name, phone_number, province_id, ward_id } = editData

      handleChangeLocation('province', province_id)
      handleChangeLocation('district', district_id)

      setFieldsValue({
        address,
        district_id,
        email,
        full_name,
        phone_number,
        province_id,
        ward_id,
      })
    }
  }, [editData, setFieldsValue, handleChangeLocation])

  useEffect(() => {
    if (dataDetail) {
      handleChangeLocation('province', dataDetail?.sender?.province?.id)
      handleChangeLocation('district', dataDetail?.sender?.district?.id)
    }
  }, [dataDetail, handleChangeLocation])

  return (
    <>
      <Form
        form={form}
        layout='horizontal'
        labelCol={{ span: 5, md: { span: 4 }, lg: { span: 4 }, xxl: { span: 3 } }}
        wrapperCol={{ span: 20, lg: { span: 20 }, xxl: { span: 21 } }}
      >
        <Row gutter={[24, 0]}>
          {!dataDetail && (
            <Col span={24} className='button-add'>
              <div style={{ textAlign: 'center' }}>
                <Button type='primary' onClick={handleShowModal}>
                  Chọn thông tin {formType === 'delivery' ? 'gửi' : 'nhận'} hàng
                </Button>
              </div>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              label='Tên đại diện'
              name={`${formType}_full_name`}
              rules={[{ required: true, message: 'Vui lòng nhập Tên đại diện!' }]}
            >
              <Input placeholder='Nhập Tên đại diện' />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label='Số điện thoại'
              name={`${formType}_phone_number`}
              rules={[{ required: true, message: 'Vui lòng nhập Số điện thoại' }]}
            >
              <Input placeholder='Nhập Số điện thoại' />
            </Form.Item>
          </Col>

          {formType === 'delivery' && (
            <Col span={24}>
              <Form.Item
                label='Email'
                name={`${formType}_email`}
                rules={[
                  { required: true, message: 'Vui lòng nhập Email!' },
                  {
                    type: 'email',
                    message: 'Email sai định dạng!',
                  },
                ]}
              >
                <Input placeholder='Nhập email' />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name={`${formType}_province_id`}
              label='Tỉnh / Thành'
              rules={[{ required: true, message: 'Vui lòng chọn Tỉnh / Thành' }]}
            >
              <select
                className='custom-select'
                onChange={(e) => {
                  handleChangeLocation('province', e?.target?.value)
                }}
                disabled={!!dataDetail}
              >
                <option value=''>Chọn tỉnh / thành</option>
                {provinceList?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={`${formType}_district_id`}
              label='Quận / Huyện'
              rules={[{ required: true, message: 'Vui lòng chọn Quận / Huyện' }]}
            >
              <select
                className='custom-select'
                onChange={(e) => handleChangeLocation('district', e?.target?.value)}
                disabled={!!dataDetail}
              >
                <option value=''>Chọn quận / huyện</option>
                {districtList?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name={`${formType}_ward_id`}
              label='Phường / Xã'
              rules={[{ required: true, message: 'Vui lòng chọn Phường / Xã' }]}
            >
              <select className='custom-select' disabled={!!dataDetail}>
                <option value=''>Chọn phường / xã</option>
                {wardList?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label='Địa chỉ'
              name={`${formType}_address`}
              rules={[{ required: true, message: 'Vui lòng nhập Địa chỉ!' }]}
            >
              <Input placeholder='Nhập địa chỉ' />
            </Form.Item>
          </Col>

          {formType === 'delivery' && (
            <Col span={24}>
              <Form.Item label='Ghi chú' name='note'>
                <TextArea rows={3} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>

      <ModalChooseAddress
        open={isModalOpen}
        onCancel={handleShowModal}
        type={formType}
        onChooseAddress={handleChooseAddress}
        onAddAddress={handleShowModify}
        refreshData={count}
      />
      <ModalAddress
        open={modalModify.open}
        onCancel={handleCloseModalModify}
        type={formType}
        editData={modalModify.data}
        refreshData={() => setCount((prev) => (prev += 1))}
      />
    </>
  )
}

export default DeliveryForm
