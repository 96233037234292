import { EyeFilled, CloudDownloadOutlined } from '@ant-design/icons'
import TableBase from 'components/Table/TableBase'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ReOrder from './ReOrder'

const OptionAction = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

const HistoryTable = ({
  dataList,
  setParams,
  loadingList,
  setListSelectedOrder,
  searchOrder,
}: {
  dataList: any
  setParams: (input: any) => void
  loadingList: boolean
  setListSelectedOrder: (input: any) => void
  searchOrder: () => void
}) => {
  const history = useHistory()

  const columns = [
    {
      title: 'Mã đơn',
      dataIndex: 'order_code',
      render: (value) => value,
    },
    {
      title: 'Người nhận',
      dataIndex: '',
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>
          <p>{record?.receiver?.full_name}</p>
          <p>{record?.receiver?.phone_number}</p>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_string',
      render: (value) => value,
    },
    {
      title: 'Thu hộ',
      dataIndex: 'cod',
      render: (cod) => (cod ? `${numberWithCommas(cod)}đ` : 0),
    },
    {
      title: 'Thời gian',
      dataIndex: 'updated_at',
      width: 200,
      render: (_, record) => (
        <div>
          <p>Ngày tạo: {record?.created_at}</p>
          <b>Cập nhật: {record?.updated_at}</b>
        </div>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      width: 210,
      render: (_, record) => (
        <OptionAction>
          {record?.is_fail ? (
            <ReOrder id={record?.id} searchOrder={searchOrder} />
          ) : (
            <Button onClick={() => history.push(`/in-don-hang/${record?.id}`)} icon={<CloudDownloadOutlined />}>
              Tải đơn
            </Button>
          )}
          <Button
            onClick={() => {
              history.push(`/xem-don-hang/${record?.id}`)
            }}
            icon={<EyeFilled />}
          >
            Xem
          </Button>
        </OptionAction>
      ),
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setListSelectedOrder(selectedRows)
    },
  }

  return (
    <TableBase
      columns={columns}
      dataSource={dataList?.data?.orders}
      scroll={{ x: 1000 }}
      rowKey={(row) => row.id}
      pagination={{
        current: dataList?.data?.meta?.current_page,
        pageSize: dataList?.data?.meta?.per_page,
        total: dataList?.data?.meta?.total,
        showSizeChanger: true,
        onChange: (page, pageSize) => {
          setParams((prev) => ({
            ...prev,
            page: page,
            per_page: pageSize,
          }))
        },
        showTotal: (total) => `Tổng ${total}`,
      }}
      loading={{
        spinning: loadingList,
        indicator: <LoadingOutlined spin />,
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
    />
  )
}

export default HistoryTable
