import { Col, Form, Input, Row } from 'antd'
import ActionGroup from './ActionGroup'
import { useCallback, useEffect, useState } from 'react'
import { orderService } from 'services'

const FilterForm = ({ setParams, listSelectedOrder }: { setParams: (input: any) => void; listSelectedOrder: any[] }) => {
  const [form] = Form.useForm()

  const [listStatus, setListStatus] = useState<any[]>([])

  useEffect(() => {
    orderService.getStatusList((data) => setListStatus(data?.data?.statuses))
  }, [])

  const searchOrder = useCallback(() => {
    form.validateFields().then(async (values) => {
      setParams((prev) => {
        let paramsSearch: any = {
          page: prev?.page,
          per_page: prev?.per_page,
        }

        if (prev?.keyword) paramsSearch.keyword = prev?.keyword
        if (prev?.cod) paramsSearch.statusCod = prev?.statusCod
        if (prev?.statusSumary) paramsSearch.statusSumary = prev?.statusSumary

        if (values?.status) {
          paramsSearch = {
            ...paramsSearch,
            status: values?.status,
          }
        }

        if (values?.order_code) {
          paramsSearch = {
            ...paramsSearch,
            order_code: values?.order_code,
          }
        }

        if (values?.full_name) {
          paramsSearch = {
            ...paramsSearch,
            full_name: values?.full_name,
          }
        }

        if (values?.phone_number) {
          paramsSearch = {
            ...paramsSearch,
            phone_number: values?.phone_number,
          }
        }

        return {
          ...paramsSearch,
        }
      })
    })
  }, [form, setParams])

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={[8, 0]} style={{ justifyContent: 'flex-end' }}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='status'>
            <select className='custom-select'>
              <option value=''>Trạng thái</option>
              {listStatus?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='order_code'>
            <Input placeholder='Nhập Mã vận đơn' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='full_name'>
            <Input placeholder='Nhập Tên người nhận' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='phone_number'>
            <Input placeholder='Nhập Số điện thoại người nhận' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 24 }} xl={{ span: 12 }}>
          <ActionGroup searchOrder={searchOrder} listSelectedOrder={listSelectedOrder} />
        </Col>
      </Row>
    </Form>
  )
}

export default FilterForm
