import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
`

const Page = () => {
  return (
    <WrapperPage>
      <FormWrapper title='Lịch sử giao dịch'>
        <HistoryTable />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page
