import { Button, Form, Input, Modal } from 'antd'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { authService } from 'services'
import styled from 'styled-components'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .submit-btn {
    width: 100%;
  }
`

const ModalForgotPassword = ({ open, onCancel }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    form.resetFields()
    onCancel()
  }

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      setLoading(true)
      try {
        await authService.forgotPassword(
          values,
          (response) => {
            toast.success(response?.data?.message, {
              autoClose: 1000,
            })
            form.resetFields()
            onCancel()
            setLoading(false)
          },
          (response) => {
            toast.error(response?.message || 'Gửi email thất bại')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('forgotPassword', error)
      }
    })
  }

  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>Quên mật khẩu</p>}
      open={open}
      footer={false}
      centered
      onCancel={handleClose}
    >
      <ModalBody>
        <p className='title'>Vui lòng nhập email đã đăng ký tài khoản, sau đó ấn nút xác nhận</p>

        <Form form={form} layout='horizontal'>
          <Form.Item
            label='Email'
            name='email'
            rules={[
              { required: true, message: 'Vui lòng nhập email!' },
              {
                type: 'email',
                message: 'Email sai định dạng!',
              },
            ]}
          >
            <Input placeholder='Nhập email' />
          </Form.Item>
        </Form>

        <Button type='primary' className='submit-btn' loading={loading} onClick={handleSubmit}>
          Xác nhận
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalForgotPassword
