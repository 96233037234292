import { FormOutlined, MenuOutlined, PhoneOutlined, ZoomInOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import { useState } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import styled from 'styled-components'
import { MENU_CONFIGS } from './config'
import { Link } from 'react-router-dom'
import { useConfig, useSetting } from 'services/swr/settings'

const WrapperHeader = styled.header`
  width: 100%;
  background: #fff;

  .body {
    padding: 10px 15px;

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .mobile-menu {
        display: block;

        svg {
          width: 24px;
          height: 24px;
        }

        ${({ theme }) => theme.mediaQueries.md} {
          display: none;
        }
      }
    }
  }
`

const TopHeader = styled.div`
  width: 100%;
  background: #f2f2f2;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    .item-left {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 13px;
        display: none;

        ${({ theme }) => theme.mediaQueries.md} {
          display: inline-block;
        }
      }

      img {
        width: 100%;
        max-width: 50px;

        ${({ theme }) => theme.mediaQueries.xs} {
          max-width: 64px;
        }
      }
    }

    .item-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      a {
        font-size: 12px;

        ${({ theme }) => theme.mediaQueries.xs} {
          font-size: 13px;
        }

        &:first-child {
          display: none;

          ${({ theme }) => theme.mediaQueries.md} {
            display: inline-block;
          }
        }

        .anticon {
          margin-right: 4px;
        }

        :hover {
          color: #008848;
        }
      }
    }
  }
`

const MenuRightWrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;

  width: 100%;
  list-style: none;
  flex: 1;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    gap: 24px;
  }

  &.pc {
    display: none;

    ${({ theme }) => theme.mediaQueries.md} {
      display: flex;
    }
  }

  .menu-item {
    padding: 5px 0;
    border-bottom: 3px solid transparent;
    font-size: 12px;

    a {
      text-transform: uppercase;
      transition: all 0.2s ease-in;
    }

    :hover a {
      color: #008848;
    }

    &.active {
      color: #008848;
      border-color: #008848;
    }
  }
`

const MenuLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  > a {
    flex-shrink: 0;
  }

  .menu-logo {
    width: 100%;
    max-width: 70px;
  }

  .content-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 120px;

    li {
      font-weight: bold;
      font-size: 13px;
    }
  }
`

const MenuRight = (pathname, isPC = false) => {
  const location = useLocation()

  const { data: config } = useConfig({
    revalidateIfStale: false,
  })

  const currentPath = location.pathname

  return (
    <MenuRightWrapper className={isPC ? 'pc' : ''}>
      {MENU_CONFIGS?.map((menu) => {
        const active = menu?.actives?.some((activePath) =>
          matchPath(currentPath, {
            path: activePath,
            exact: true,
            strict: false,
          })
        )
        const { icon: Icon } = menu

        return (
          <li key={menu?.label} className={active ? 'menu-item active' : 'menu-item'}>
            {menu?.external ? (
              <a href={menu?.href === '/ban-cam-ket.pdf' ? config?.pdfCommitment : menu?.href} target='_blank' rel='noreferrer'>
                <Icon /> {menu?.label}
              </a>
            ) : (
              <Link to={menu?.href}>
                <Icon /> {menu?.label}
              </Link>
            )}
          </li>
        )
      })}
    </MenuRightWrapper>
  )
}

function Header() {
  const location = useLocation()
  const { data: settings } = useSetting({
    revalidateIfStale: false,
  })
  const { data: config } = useConfig({
    revalidateIfStale: false,
  })

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const toggleMobileMenu = () => setIsOpenMobileMenu((prev) => !prev)

  return (
    <>
      <WrapperHeader>
        <TopHeader>
          <div className='container body content'>
            <div className='item-left'>
              <span>Bấm để tải app {settings?.site_name_mini}:</span>
              <a target='blank_' rel='noopener' href={config?.urlAppleApp}>
                <img src='/images/home/apple-store-icon.png' />
              </a>
              <a target='blank_' rel='noopener' href={config?.urlAndroidApp}>
                <img src='/images/home/google-play-icon.png' />
              </a>
            </div>

            <div className='item-right'>
              <a href={config?.pdfCommitment} target='_blank' rel='noopener noreferrer'>
                <FormOutlined /> Bản cam kết&nbsp;&nbsp;
              </a>
              <a title='Thông tin bưu cục' href='https://viettelpost.com.vn/tim-kiem-buu-cuc/' target='_Blank' rel='noreferrer'>
                <ZoomInOutlined />
                Bưu cục&nbsp;&nbsp;
              </a>
              <a href='tel:0367144544'>
                <PhoneOutlined />
                0367144544
              </a>
            </div>
          </div>
        </TopHeader>

        <div className='container body'>
          <div className='menu'>
            <MenuLeft>
              <a href='/'>
                <img className='menu-logo' src='/logo.png' />
              </a>

              <ul className='content-list'>
                <li>Đồng giá toàn quốc</li>
                <li>Dịch vụ tốt nhất</li>
                <li>Giá rẻ nhất</li>
              </ul>
            </MenuLeft>

            {MenuRight(location.pathname, true)}

            <MenuOutlined className='mobile-menu' onClick={toggleMobileMenu} />
          </div>
        </div>
      </WrapperHeader>

      <Drawer
        title={<img className='menu-logo' src='/logo.png' width={80} />}
        onClose={toggleMobileMenu}
        open={isOpenMobileMenu}
        width={300}
      >
        {MenuRight(location.pathname)}
      </Drawer>
    </>
  )
}
export default Header
