import { createAction } from '@reduxjs/toolkit'

type TBaseData = {
  id: number
  title: string
}

export type TBranches = TBaseData[]
export type TAreas = (TBaseData & { branches: TBranches })
export type TBanks = TBaseData & { areas: TAreas[] }

export const setBankData = createAction<TBanks[]>('banks/bankList')
