import { Col, Form, Input, Row } from 'antd'
import { useCallback } from 'react'
import ActionGroup from './ActionGroup'


const FilterForm = ({ setParams, listSelectedOrder }: { setParams: (input: any) => void; listSelectedOrder: any[] }) => {
  const [form] = Form.useForm()

  const searchOrder = useCallback(() => {
    form.validateFields().then(async (values) => {
      setParams((prev) => {
        let paramsSearch: any = {
          page: prev?.page,
          per_page: prev?.per_page,
        }

        if (prev?.keyword) paramsSearch.keyword = prev?.keyword

        if (values?.order_code) {
          paramsSearch = {
            ...paramsSearch,
            order_code: values?.order_code,
          }
        }

        if (values?.full_name) {
          paramsSearch = {
            ...paramsSearch,
            full_name: values?.full_name,
          }
        }

        if (values?.phone_number) {
          paramsSearch = {
            ...paramsSearch,
            phone_number: values?.phone_number,
          }
        }

        return {
          ...paramsSearch,
        }
      })
    })
  }, [form, setParams])

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={[8, 0]}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='order_code'>
            <Input placeholder='Nhập Mã vận đơn' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='full_name'>
            <Input placeholder='Nhập Tên người nhận' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='phone_number'>
            <Input placeholder='Nhập Số điện thoại người nhận' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 24 }} xl={{ span: 15 }}>
          <ActionGroup searchOrder={searchOrder} listSelectedOrder={listSelectedOrder} />
        </Col>
      </Row>
    </Form>
  )
}

export default FilterForm
