import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { supportService } from 'services'
import styled from 'styled-components'
import { QuestionCircleOutlined, CloudUploadOutlined, CloseOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import axios from 'axios'
import { API_BASE_URL } from 'constants/index'
import fetchHelper, { getAuthToken } from 'helpers/FetchHelper'

const ModalBody = styled.div`
  padding-right: 10px;

  > div {
    padding: 0;
    box-shadow: none;
    background: transparent;
  }

  .wrap-btn-upload {
    label {
      opacity: 0;
    }

    button {
      margin-bottom: 10px;
      display: flex;
      algin-items: center;

      .anticon-cloud-upload {
        margin-top: 2px;
      }

      span {
        &:nth-child(2) {
          margin-left: 3px;
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .wrap-item-upload-image {
      display: flex;
      gap: 10px;

      .item-upload-image {
        width: 33%;
        position: relative;

        svg {
          position: absolute;
          top: 2px;
          right: 2px;
          color: red;
          cursor: pointer;
          font-size: 18px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const { TextArea } = Input

const ModalCallSupport = ({ open, onCancel, loading, setLoading, orderId }) => {
  const auth = getAuthToken()

  const [form] = Form.useForm()
  const inputFileRef = useRef<any>(null)

  const [supportType, setSupportType] = useState<any[]>([])
  const [selectedType, setSelectedType] = useState<any>()
  const [image, setImage] = useState<any[]>([])

  const submitForm = useCallback(() => {
    form.validateFields().then(async (values) => {
      try {
        if (supportType?.find((item) => item?.id === selectedType)?.type_content) {
          const formData = new FormData()
          formData.append('order_id', orderId)
          formData.append('type_support_id', values?.type_support_id)
          formData.append('content', values?.content)

          if (image?.[0]) {
            formData.append('image_1', image?.[0]?.originFileObj, 'image_1.png')
          }
          if (image?.[1]) {
            formData.append('image_2', image?.[1]?.originFileObj, 'image_2.png')
          }
          if (image?.[2]) {
            formData.append('image_3', image?.[2]?.originFileObj, 'image_3.png')
          }

          const response = await axios.post(`${API_BASE_URL}/support/create-support`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth}`,
            },
          })

          if (response?.data?.error) {
            toast.error(response?.data?.message)
            setLoading(false)
          } else {
            toast.success(response?.data?.data?.message || 'Báo hỗ trợ thành công', {
              autoClose: 1000,
            })
            setLoading(false)
            onCancel()
          }
          fetchHelper.addDefaultHeader('Content-Type', 'application/json')

          return
        }

        let dataPost = { ...values, order_id: orderId, type_support_id: +values?.type_support_id }

        if (values?.date) {
          dataPost = {
            ...dataPost,
            date: values?.date.format('YYYY-MM-DD'),
          }
        }

        await supportService.create(
          dataPost,
          (response) => {
            toast.success(response?.data?.message || 'Báo hỗ trợ thành công', {
              autoClose: 1000,
            })
            setLoading(false)
            onCancel()
          },
          (response) => {
            toast.error(response?.message || 'Báo hỗ trợ không thành công')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('updateAddress', error)
        toast.error('Báo hỗ trợ không thành công')
      }
    })
  }, [auth, form, image, onCancel, orderId, selectedType, setLoading, supportType])

  const getListTypeSupport = useCallback(() => {
    supportService.getTypeSupports((data) => {
      setSupportType(data?.data?.typeSupports)
    })
  }, [])

  useEffect(() => {
    getListTypeSupport()
  }, [getListTypeSupport])

  const handleButtonClick = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click()
    }
  }, [])

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)

    if ([...image, ...files].length > 3) {
      toast.error('Bạn chỉ có thể chọn tối đa 3 tấm ảnh')
    }

    const fileListWithPreview = files.slice(0, 3).map((file: any) => ({
      uid: file.name,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      originFileObj: file,
    }))

    setImage((prev) => {
      if ([...prev, ...fileListWithPreview].length > 3) {
        return prev
      } else {
        return [...prev, ...fileListWithPreview]
      }
    })
  }

  const removeImage = (urlToRemove) => {
    const filteredImages = image.filter((item) => item.url !== urlToRemove)
    setImage(filteredImages)
  }

  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          <QuestionCircleOutlined /> Báo hỗ trợ đơn vận
        </p>
      }
      open={open}
      footer={false}
      centered
      onCancel={() => {
        if (!loading) {
          onCancel()
          setImage([])
        }
      }}
      width={700}
    >
      <ModalBody>
        <FormWrapper>
          <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <Form.Item
                  name='type_support_id'
                  label='Loại yêu cầu'
                  rules={[{ required: true, message: 'Vui lòng chọn Loại yêu cầu' }]}
                >
                  <select
                    className='custom-select'
                    onChange={(e) => {
                      setSelectedType(+e?.target?.value)
                    }}
                  >
                    <option value=''>Chọn Loại yêu cầu</option>
                    {supportType?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              {supportType?.find((item) => item?.id === selectedType)?.choose_date && (
                <Col span={24}>
                  <Form.Item label='Ngày chọn' name='date' rules={[{ required: true, message: 'Vui lòng chọn Ngày' }]}>
                    <DatePicker placeholder='Chọn Ngày' format='DD-MM-YYYY' style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              )}

              {supportType?.find((item) => item?.id === selectedType)?.type_content && (
                <>
                  <Col span={24}>
                    <Form.Item label='Nội dung' name='content' rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}>
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <div className='wrap-btn-upload'>
                      <Form.Item label='Tải đính kèm'>
                        <input
                          type='file'
                          multiple
                          accept='image/*'
                          style={{ display: 'none' }}
                          ref={inputFileRef}
                          onChange={handleFileChange}
                        />
                        <Button icon={<CloudUploadOutlined />} onClick={handleButtonClick}>
                          Tải đính kèm (Tối đa 3 tấm)
                        </Button>

                        <div className='wrap-item-upload-image'>
                          {image?.map((item) => (
                            <div key={item?.url} className='item-upload-image'>
                              <CloseOutlined onClick={() => removeImage(item.url)} />
                              <img src={item?.url} alt='' />
                            </div>
                          ))}
                        </div>
                      </Form.Item>
                    </div>
                  </Col>
                </>
              )}

              <Col span={24}>
                <ButtonGroups>
                  <Button type='primary' size='large' loading={loading} onClick={submitForm}>
                    Xác nhận
                  </Button>
                  <Button
                    size='large'
                    onClick={() => {
                      if (!loading) {
                        onCancel()
                        setImage([])
                      }
                    }}
                  >
                    Thoát
                  </Button>
                </ButtonGroups>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ModalCallSupport
