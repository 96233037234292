import { createAction } from '@reduxjs/toolkit'

interface IBankInfo {
  id: number
  bank_id?: number
  area_id?: number
  branch_id?: number
  bank_name?: string
  bank_number?: string
  status?: string
  created_at: string
  bank?: any
}

export interface ICustomerProfile {
  id: number | string
  token: null | string
  email: string
  full_name: string
  phone_number: string
  default_package_id: null | string | number
  is_verified: string | number
  verified_date: string
  status_kyc: string | number
  note_status_kyc: null | string | number
  created_at: string
  bankInfo: IBankInfo | null
  support?: any
  marginBalance?: any
}

export const setProfile = createAction<ICustomerProfile>('customer/profile')
