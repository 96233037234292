import { createReducer } from '@reduxjs/toolkit'
import { setBankData, TBanks } from './actions'

export interface BankState {
  bankList: TBanks[]
}

const initialState: BankState = {
  bankList: [],
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setBankData, (state, { payload }) => {
    state.bankList = payload
  })
)
