import { DownloadOutlined, SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { orderService } from 'services'
import { toast } from 'react-toastify'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 1px;
  }

  button {
    height: 40px;
  }

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
  }
`

const ActionGroup = ({ searchOrder, listSelectedOrder }: { searchOrder: () => void; listSelectedOrder: any[] }) => {
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const handleCancel = useCallback(() => {
    if (listSelectedOrder?.length > 0) {
      orderService.cancelByIds(
        listSelectedOrder?.map((item) => item?.id),
        (response) => {
          toast.success(response?.data?.message, {
            autoClose: 1000,
          })
          searchOrder()
        },
        (response) => {
          toast.error(response?.message)
          searchOrder()
          setLoading(false)
        }
      )
    }
  }, [listSelectedOrder, searchOrder])

  return (
    <Wrapper>
      <Button onClick={searchOrder}>
        <SearchOutlined /> Tìm kiếm
      </Button>

      <div className='wrap-button'>
        <Button disabled={!listSelectedOrder?.length} onClick={() => history.push(`/in-don-hang/${listSelectedOrder?.map((item) => item?.id).join(',')}`)}>
          <DownloadOutlined /> Tải đơn vận
        </Button>
        <Button onClick={handleCancel} loading={loading} disabled={!listSelectedOrder?.length}>
          <DeleteOutlined /> Hủy đơn vận
        </Button>
      </div>
    </Wrapper>
  )
}

export default ActionGroup
