import { Button } from 'antd'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { orderService } from 'services'
import { RetweetOutlined } from '@ant-design/icons'

const ReOrder = ({ id, searchOrder }: { id: string; searchOrder: () => void }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleReOrder = useCallback(() => {
    setLoading(true)

    orderService.reOrder(
      id,
      (response) => {
        toast.success(response?.data?.message, {
          autoClose: 1000,
        })
        setLoading(false)
        searchOrder()
      },
      (response) => {
        toast.error(response?.message)
        setLoading(false)
      }
    )
  }, [id, searchOrder])

  return (
    <Button onClick={handleReOrder} icon={<RetweetOutlined />} loading={loading}>
      Tạo lại
    </Button>
  )
}

export default ReOrder
