import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

const baseInputStyles = css`
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
`

const Wrapper = styled.div`
  padding: 10px 8px;
  border-radius: 5px;
  background: #fafafa;
  box-shadow: 0 4px 4px 0 rgba(54, 61, 83, 0.12);

  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 8px;
    padding: 16px 15px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    border-radius: 12px;
    padding: 16px 20px;
  }

  .warp-form-title {
    display: flex;

    svg {
      font-size: 17px;
      margin-right: 4px;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 18px;
      }

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 19px;
      }
    }
  }

  .form-title {
    font-size: 17px;
    font-weight: 700;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 18px;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 19px;
    }
  }

  .ant-form {
    .ant-form-item {
      &-label {
        label {
          font-weight: bold;
        }
      }

      .ant-form-item-control-input-content {
        > input {
          ${baseInputStyles}
        }
      }

      .ant-input-affix-wrapper {
        ${baseInputStyles}
      }

      .ant-select-selector {
        ${baseInputStyles}
        align-items: center;
      }

      .ant-input:read-only {
        ::placeholder {
          color: #000;
        }
      }

      .ant-input-number {
        width: 100%;

        input {
          ${baseInputStyles}
        }
      }

      .number-format {
        width: 100%;
        border: 1px solid #d9d9d9;

        &:focus {
          border-color: #40a9ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          border-right-width: 1px;
          outline: 0;
        }

        &::-ms-input-placeholder {
          color: #bfbfbf;
        }

        &::placeholder {
          color: #bfbfbf;
        }
      }

      .ant-select {
        &.ant-select-multiple {
          .ant-select-selector {
            height: auto;
          }
        }
      }
    }
  }

  .ant-select-selection-search {
    input {
      height: 100% !important;
    }
  }

  .ant-picker {
    height: 40px;
  }
`

type TFormWrapper = {
  title?: string
  children?: ReactNode
  icon?: ReactNode
}

const FormWrapper = ({ title, children, icon }: TFormWrapper) => {
  return (
    <Wrapper>
      {title && (
        <div className='warp-form-title'>
          {icon}
          <p className='form-title'>{title}</p>
        </div>
      )}

      {children}
    </Wrapper>
  )
}

export default FormWrapper
