import { Button, Col, Modal, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'

const ModalBody = styled.div`
  padding-right: 10px;

  > div {
    padding: 0;
    box-shadow: none;
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ModalCancelOrder = ({ open, onCancel, onSubmit, loading }) => {
  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>Xác nhận</p>}
      open={open}
      footer={false}
      centered
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <FormWrapper>
          <Row gutter={[30, 30]}>
            <Col span={24}>
              <p style={{ fontSize: 24, textAlign: 'center' }}>Bạn có chắc muốn huỷ đơn hàng?</p>
            </Col>

            <Col span={24}>
              <ButtonGroups>
                <Button type='primary' size='large' onClick={onSubmit} loading={loading}>
                  Xác nhận
                </Button>
                <Button size='large' onClick={onCancel}>
                  Thoát
                </Button>
              </ButtonGroups>
            </Col>
          </Row>
        </FormWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ModalCancelOrder
