import styled from 'styled-components'

export const WrapperDashboard = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-height: calc(100vh - 208px);
    padding-bottom: 40px;
  }

  .box-wellcome {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 5px;
    border-left: 3px solid #008848;
    min-height: 50px;
    padding: 20px 5px;
    gap: 8px;
    row-gap: 16px;

    ${({ theme }) => theme.mediaQueries.sm} {
      border-left: 8px solid #008848;
      padding: 25px;
      gap: 16px;
    }

    img {
      width: 70px;

      ${({ theme }) => theme.mediaQueries.sm} {
        width: 80px;
      }
    }

    .wrap-text {
      width: calc(100% - 78px);

      ${({ theme }) => theme.mediaQueries.sm} {
        width: auto;
      }

      p {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-bottom: 0px;

          ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 20px;
          }

          ${({ theme }) => theme.mediaQueries.lg} {
            font-size: 24px;
          }
        }

        &:nth-child(2) {
          font-size: 14px;

          ${({ theme }) => theme.mediaQueries.sm} {
            font-size: 16px;
          }
        }
      }
    }

    .wrap-button {
      display: flex;
      align-items: center;

      button {
        &:not(:last-child) {
          margin-right: 8px;

          ${({ theme }) => theme.mediaQueries.sm} {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .dashboard-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  .title {
    flex: 1;
    font-size: 18px;
    color: rgb(77, 77, 77);
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: #333;
  }
`

export const ReportBox = styled.div`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  * {
    font-size: 13px;
    text-align: center;
  }

  .anticon {
    svg {
      font-size: 16px;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    color: rgb(170, 174, 179);

    .green-icon {
      svg {
        fill: #3cc899;
      }
    }

    .red-icon {
      svg {
        fill: #ff6e6b;
      }
    }

    .yellow-icon {
      svg {
        fill: #ffc565;
      }
    }

    .grey-icon {
      svg {
        fill: gray;
      }
    }
  }

  .mid {
    font-weight: bold;
  }

  .bottom {
    color: rgb(77, 77, 77);
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
  }
`
