import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { publicService } from 'services'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Wrapper = styled.section`
  width: 100%;
  background: #f2f2f2;

  .container {
    padding: 20px 15px;

    h2 {
      font-weight: bold;
      font-size: 26px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
`

const TutorialItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.25s ease-in;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  :hover {
    border-color: #008848;
  }

  p {
    font-size: 13px;

    &.title {
      color: #008848;
    }

    &.time {
      font-style: italic;
      font-size: 11px;
    }

    &.desc {
      color: #666;
    }
  }

  a {
    font-size: 13px;
    font-style: italic;
    text-align: right;

    :hover {
      color: #008848;
    }
  }
`

const Tutorials = () => {
  const [news, setNews] = useState([])

  useEffect(() => {
    publicService.postHomePage((data) => {
      setNews(data?.data?.posts)
    })
  }, [])

  return (
    <Wrapper>
      <div className='container'>
        <h2>Hướng dẫn</h2>

        <Row gutter={[24, 24]} style={{ width: '100%' }}>
          {news.map((item: any) => (
            <Col span={24} sm={{ span: 12 }} lg={{ span: 8 }} key={item.id}>
              <TutorialItem>
                <p className='title'>{item?.title}</p>
                <p className='time'>{item?.created_at}</p>
                <p className='desc'>{item?.sumary}</p>
                <Link to={`/chi-tiet/${item?.slug}`}>Xem thêm</Link>
              </TutorialItem>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  )
}

export default Tutorials
