/* eslint-disable consistent-return */
import { API_BASE_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'

const getProfile = async (cbs?: (data) => void, cbe?: (data) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/profile`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      } else if (cbe) cbe(resp)
    })
    .catch((error) => {
      if (cbe) cbe(error)
    })
}

const RequestKYC = async (formData, cbs?: (data: any) => void) => {
  fetchHelper.addDefaultHeader('Content-Type', 'multipart/form-data')
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/request-kyc`, {
      method: 'POST',
      body: formData,
    })
    .then(([resp, status]: any) => {
      fetchHelper.addDefaultHeader('Content-Type', 'application/json')
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      }
    })
}

const GetKYC = async (cbs?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/get-request-kyc`, {
      method: 'GET',
    })
    .then(([resp, status]: any) => {
      if (status === 200 && !resp?.error) {
        if (cbs) cbs(resp)
      }
    })
}

const createAddress = async (type: string, body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer-address/create-${type}`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const getListAddress = async (type: string, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer-address/get-list-${type}`, {
      method: 'GET',
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp?.data)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const updateAddress = async (id: string | number, body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer-address/update-address/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const deleteAddress = async (id: string | number, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer-address/delete-address/${id}`, {
      method: 'DELETE',
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

const updateProfile = async (body, cbs?: (data: any) => void, cbe?: (data: any) => void) => {
  return await fetchHelper
    .fetch(`${API_BASE_URL}/customer/update-profile`, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
    .then(([resp]: any) => {
      if (!resp?.error) {
        if (cbs) cbs(resp)
      } else {
        if (cbe) cbe(resp)
      }
    })
}

export default { getProfile, RequestKYC, GetKYC, createAddress, getListAddress, updateAddress, deleteAddress, updateProfile }
