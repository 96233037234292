import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { useCallback } from 'react'
import { SearchOutlined } from '@ant-design/icons'

const FilterForm = ({ setParams }: { setParams: (input: any) => void }) => {
  const [form] = Form.useForm()

  const searchOrder = useCallback(() => {
    form.validateFields().then(async (values) => {
      setParams((prev) => {
        let paramsSearch: any = {
          page: prev?.page,
          per_page: prev?.per_page,
        }

        if (values?.order_code) {
          paramsSearch = {
            ...paramsSearch,
            order_code: values?.order_code,
          }
        }

        if (values?.end_date) {
          paramsSearch = {
            ...paramsSearch,
            end_date: values?.end_date?.format('YYYY-MM-DD'),
          }
        }

        if (values?.start_date) {
          paramsSearch = {
            ...paramsSearch,
            start_date: values?.start_date?.format('YYYY-MM-DD'),
          }
        }

        return {
          ...paramsSearch,
        }
      })
    })
  }, [form, setParams])

  return (
    <Form form={form} layout='vertical'>
      <div style={{ padding: '20px 0 10px 0' }}>
        <Row gutter={[8, 0]}>
          <Col>
            <Form.Item name='order_code'>
              <Input placeholder='Nhập Mã vận đơn' />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name='start_date' style={{ width: '100%' }}>
              <DatePicker format='DD-MM-YYYY' placeholder='Chọn ngày' />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item name='end_date' style={{ width: '100%' }}>
              <DatePicker format='DD-MM-YYYY' placeholder='Chọn ngày' />
            </Form.Item>
          </Col>

          <Col span={24} md={{ span: 24 }} xl={{ span: 12 }}>
            <Button onClick={searchOrder}>
              <SearchOutlined /> Tìm kiếm
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default FilterForm
