import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row } from 'antd'
import { convertAddress } from 'helpers'
import { useEffect, useState } from 'react'
import { customerService } from 'services'
import styled from 'styled-components'

const AddressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  cursor: pointer;

  :hover {
    border-color: #066637;
  }
`

const ModalBody = styled.div`
  padding-right: 10px;
  max-height: 400px;
  overflow-y: scroll;
`

const ModalChooseAddress = ({ type, open, onCancel, onChooseAddress, onAddAddress, refreshData }) => {
  const [addressList, setAddressList] = useState<any[]>([])
  const modalTitle = type === 'delivery' ? 'Thông tin gửi hàng' : 'Thông tin nhận hàng'

  const handleAdd = () => {
    onAddAddress(undefined)
    onCancel()
  }

  useEffect(() => {
    customerService.getListAddress(type, (res) => setAddressList(res?.addresses))
  }, [type, refreshData])

  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>{modalTitle}</p>}
      open={open}
      footer={false}
      centered
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button onClick={handleAdd}>
              <PlusCircleOutlined /> Thêm mới
            </Button>
          </Col>

          {addressList.map((address) => (
            <Col key={address?.id} span={24} md={{ span: 12 }} onClick={() => onChooseAddress(type, address)}>
              <AddressWrapper>
                <p>
                  {address?.full_name} - {address?.phone_number}
                </p>
                <p>{address?.address}</p>
                <p>{convertAddress(address, true)}</p>
              </AddressWrapper>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalChooseAddress
