import { Col, Form, Input, Radio, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { orderService } from 'services'
import styled from 'styled-components'
import { numberWithCommas } from 'utils'
import { NumericFormat } from 'react-number-format'
import { InfoCircleFilled } from '@ant-design/icons'
import ModalInfo from './ModalInfo'

const BoxSelectPackage = styled.div`
  position: relative;

  label {
    text-wrap: wrap !important;
  }

  > p {
    margin-left: 0;

    ${({ theme }) => theme.mediaQueries.sm} {
      margin-left: 20.83333333%;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      margin-left: 16.66666667%;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-left: 12.5%;
    }
  }
`

const OrderDetailForm = ({
  form,
  setPackageSelected,
  packageSelected,
  setQty,
  customerProfile,
  packageList,
  setPackageList,
  dataDetail,
}) => {
  const [hasAmountCollected, setHasAmountCollected] = useState<boolean>(true)
  const [amountCollected, setAmountCollected] = useState<number>(0)

  const [typePackageDesc, setTypePackageDesc] = useState<string>('')
  const [openModalInfo, setOpenModalInfo] = useState<boolean>(false)

  useEffect(() => {
    orderService.getPackageList((data) => {
      setPackageList(data?.data?.packages)
    })
  }, [setPackageList])

  const handleChagnePrice = useCallback(() => {
    const selectedPackage = packageList.find((item) => +item?.id === +form.getFieldValue('typePackage'))
    const price = form.getFieldValue('orderPrice') || 0
    const qty = form.getFieldValue('orderQty') || 0
    setAmountCollected((selectedPackage?.fee || 0) * +qty + +price)
    setQty(+qty)
  }, [form, packageList, setQty])

  useEffect(() => {
    if (customerProfile?.default_package_id) {
      setTypePackageDesc(packageList.find((item) => item?.id === customerProfile?.default_package_id)?.sumary)
      setPackageSelected(packageList.find((item) => item?.id === customerProfile?.default_package_id))
      form.setFieldValue('typePackage', customerProfile?.default_package_id)
      handleChagnePrice()
    }
  }, [customerProfile?.default_package_id, form, handleChagnePrice, packageList, setPackageSelected])

  return (
    <>
      <Form
        form={form}
        layout='horizontal'
        labelCol={{ span: 5, md: { span: 4 }, lg: { span: 4 }, xxl: { span: 3 } }}
        wrapperCol={{ span: 20, lg: { span: 20 }, xxl: { span: 21 } }}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item label='Tên sản phẩm' name='orderName' rules={[{ required: true, message: 'Vui lòng nhập Tên sản phẩm!' }]}>
              <Input placeholder='Nhập Tên sản phẩm' />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label='Cho xem hàng' name='isCheck'>
              <Radio.Group defaultValue={0}>
                <Radio value={1}>Có</Radio>
                <Radio value={0}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <BoxSelectPackage>
              <Form.Item label='Chọn gói vận chuyển' name='typePackage'>
                <select
                  className='custom-select'
                  disabled={!!dataDetail}
                  onChange={(e) => {
                    setTypePackageDesc(packageList.find((item) => +item?.id === +e?.target?.value)?.sumary)
                    setPackageSelected(packageList.find((item) => +item?.id === +e?.target?.value))
                    form.setFieldValue('typePackage', +e?.target?.value)
                    handleChagnePrice()
                  }}
                >
                  <option value=''>Chọn gói</option>
                  {packageList?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item.title} - {numberWithCommas(item.fee)}đ (kích thước: {item?.length}x{item?.width}x{item?.height}cm)
                    </option>
                  ))}
                </select>
              </Form.Item>

              {typePackageDesc && (
                <p>
                  <InfoCircleFilled style={{ color: '#008848', cursor: 'pointer' }} onClick={() => setOpenModalInfo(true)} />{' '}
                  {typePackageDesc}
                </p>
              )}
            </BoxSelectPackage>
          </Col>

          <Col span={24}>
            <Form.Item label='Phí vận chuyển' name='transferFee'>
              <Radio.Group
                defaultValue={1}
                onChange={(e) => {
                  setHasAmountCollected(Boolean(e?.target?.value))
                  handleChagnePrice()
                }}
              >
                <Radio value={0}>Người gửi trả</Radio>
                <Radio value={1}>Người nhận trả</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item style={{ display: 'none' }} name='orderPrice' />
            <Form.Item label='Tiền hàng'>
              <NumericFormat
                className='number-format'
                allowLeadingZeros
                thousandSeparator=','
                onValueChange={(e) => {
                  form.setFieldValue('orderPrice', e?.floatValue)
                  handleChagnePrice()
                }}
                isAllowed={(values: any) => {
                  const { floatValue } = values
                  if (floatValue) {
                    return floatValue <= 999999999999
                  }
                  return true
                }}
                placeholder='Nhập tiền hàng'
                defaultValue={0}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label='Số kiện hàng' name='orderQty' rules={[{ required: true, message: 'Vui lòng nhập Số kiện hàng!' }]}>
              <Input placeholder='Nhập Số kiện hàng' onChange={handleChagnePrice} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label='Tiền thu hộ'>
              <Input
                placeholder='Nhập Tiền thu hộ'
                disabled
                value={numberWithCommas(hasAmountCollected ? amountCollected : +form.getFieldValue('orderPrice'))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ModalInfo open={openModalInfo} onCancel={() => setOpenModalInfo(false)} packageSelected={packageSelected} />
    </>
  )
}

export default OrderDetailForm
