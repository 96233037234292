import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
`

const Page = () => {
  return (
    <WrapperPage>
      <p className='title-page'>Đơn điều chỉnh khối lượng</p>
      <FormWrapper title=''>
        <HistoryTable />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page
