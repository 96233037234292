import { Button, Col, Form, Input, Modal, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { customerService, locationService } from 'services'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import { IProvinceData } from 'state/location/actions'
import useGetProvince from 'state/location/hooks/useGetLocation'
import styled from 'styled-components'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .submit-btn {
    width: 100%;
  }

  > div {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }
`

const ModalAddress = ({ open, onCancel, type, editData, refreshData }) => {
  const [form] = Form.useForm()
  const { setFieldsValue } = form
  const modalTitle = type === 'delivery' ? 'Thông tin gửi hàng' : 'Thông tin nhận hàng'

  const { provinceList } = useGetProvince()
  const { customerProfile } = useGetCustomerData()

  const [loading, setLoading] = useState(false)
  const [districtList, setDistrictList] = useState<IProvinceData[]>([])
  const [wardList, setWardList] = useState<IProvinceData[]>([])

  useEffect(() => {
    if (customerProfile && type === 'delivery') {
      form.setFieldsValue({
        full_name: customerProfile?.full_name,
        phone_number: customerProfile?.phone_number,
      })
    }

    if (!customerProfile) return

    form.setFieldsValue({
      email: customerProfile?.email,
    })
  }, [customerProfile, form, type])

  const handleChangeLocation = useCallback(
    async (type, id) => {
      if (type === 'province') {
        setFieldsValue({
          district_id: undefined,
          ward_id: undefined,
        })
        await locationService.getDistrict(id, (res) => setDistrictList(res?.districts))
        setWardList([])
      }
      if (type === 'district') {
        setFieldsValue({
          ward_id: undefined,
        })
        await locationService.getWard(id, (res) => setWardList(res?.wards))
      }
    },
    [setFieldsValue]
  )

  const handleClose = () => {
    form.resetFields()
    form.setFieldsValue({
      email: customerProfile?.email,
    })
    onCancel()
  }

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      const onSuccess = async (message) => {
        toast.success(message, {
          autoClose: 1000,
        })
        form.resetFields()
        setLoading(false)
        await customerService.getListAddress(type, (res) => refreshData(res?.addresses))
        handleClose()
      }

      setLoading(true)

      if (editData?.id) {
        try {
          await customerService.updateAddress(
            editData?.id,
            values,
            (response) => onSuccess(response?.data?.message),
            (response) => {
              toast.error(response?.message || 'Chỉnh sửa không thành công')
              setLoading(false)
            }
          )
        } catch (error) {
          setLoading(false)
          console.error('updateAddress', error)
        }

        return
      }

      try {
        await customerService.createAddress(
          type,
          values,
          (response) => onSuccess(response?.data?.message),
          (response) => {
            toast.error(response?.message || 'Tạo không thành công')
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('createAddress', error)
      }
    })
  }

  useEffect(() => {
    if (editData) {
      const { address, district_id, email, full_name, phone_number, province_id, ward_id } = editData

      handleChangeLocation('province', province_id)
      handleChangeLocation('district', district_id)

      setFieldsValue({
        address,
        district_id,
        email,
        full_name,
        phone_number,
        province_id,
        ward_id,
      })
    }
  }, [editData, setFieldsValue, handleChangeLocation])

  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>{modalTitle}</p>}
      open={open}
      footer={false}
      centered
      onCancel={handleClose}
      width={700}
    >
      <ModalBody>
        <FormWrapper>
          <Form form={form} layout='horizontal' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  label='Tên đại diện'
                  name='full_name'
                  rules={[{ required: true, message: 'Vui lòng nhập Tên đại diện!' }]}
                >
                  <Input placeholder='Nhập Tên đại diện' />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label='Số điện thoại'
                  name='phone_number'
                  rules={[{ required: true, message: 'Vui lòng nhập Số điện thoại' }]}
                >
                  <Input placeholder='Nhập Số điện thoại' />
                </Form.Item>
              </Col>

              {type === 'delivery' && (
                <Col span={24}>
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={
                      type !== 'delivery'
                        ? []
                        : [
                            { required: true, message: 'Vui lòng nhập Email!' },
                            {
                              type: 'email',
                              message: 'Email sai định dạng!',
                            },
                          ]
                    }
                  >
                    <Input placeholder='Nhập email' disabled={type !== 'delivery'} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  name='province_id'
                  label='Tỉnh / Thành'
                  rules={[{ required: true, message: 'Vui lòng chọn Tỉnh / Thành' }]}
                >
                  <select
                    className='custom-select'
                    onChange={(e) => {
                      handleChangeLocation('province', e?.target?.value)
                    }}
                  >
                    <option value=''>Chọn tỉnh / thành</option>
                    {provinceList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name='district_id'
                  label='Quận / Huyện'
                  rules={[{ required: true, message: 'Vui lòng chọn Quận / Huyện' }]}
                >
                  <select className='custom-select' onChange={(e) => handleChangeLocation('district', e?.target?.value)}>
                    <option value=''>Chọn quận / huyện</option>
                    {districtList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name='ward_id' label='Phường / Xã' rules={[{ required: true, message: 'Vui lòng chọn Phường / Xã' }]}>
                  <select className='custom-select'>
                    <option value=''>Chọn phường / xã</option>
                    {wardList?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Địa chỉ' name='address' rules={[{ required: true, message: 'Vui lòng nhập Địa chỉ!' }]}>
                  <Input placeholder='Nhập địa chỉ' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </FormWrapper>

        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Button type='primary' className='submit-btn' loading={loading} onClick={handleSubmit}>
              Xác nhận
            </Button>
          </Col>

          <Col span={12}>
            <Button danger className='submit-btn' loading={loading} onClick={handleClose}>
              Hủy
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddress
