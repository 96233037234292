import { Button, Col, Modal, Row } from 'antd'
import styled from 'styled-components'
import { BankFilled, CloseOutlined, ForkOutlined, UserOutlined, CreditCardOutlined } from '@ant-design/icons'

const ModalBody = styled.div`
  b {
    display: contents;
  }
`

const ModalInfoDeposit = ({ open, onCancel, dataConfig, profile }) => {
  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>Thông tin chuyển khoản</p>}
      open={open}
      footer={
        <Button onClick={onCancel} icon={<CloseOutlined />}>
          Thoát
        </Button>
      }
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <div>
              <p>
                <BankFilled /> Tên ngân hàng: <b>{dataConfig?.data?.bank_name_deposit}</b>
              </p>
            </div>
          </Col>

          <Col span={24}>
            <div>
              <p>
                <ForkOutlined /> Chi nhánh: <b>{dataConfig?.data?.bank_branch_deposit}</b>
              </p>
            </div>
          </Col>

          <Col span={24}>
            <div>
              <p>
                <UserOutlined /> Tên tài khoản: <b>{dataConfig?.data?.bank_account_deposit}</b>
              </p>
            </div>
          </Col>

          <Col span={24}>
            <div>
              <p>
                <CreditCardOutlined /> Số tài khoản: <b>{dataConfig?.data?.bank_number_deposit}</b>
              </p>
            </div>
          </Col>

          <Col span={24}>
            <div>
              <p>
                Nội dung chuyển khoản:{' '}
                <b>
                  {dataConfig?.data?.bank_key_deposit} {profile?.id}
                </b>
              </p>
            </div>
          </Col>

          <Col span={24}>
            <div>
              <p>
                Ghi chú: Trong đó <b>{profile?.id}</b> là mã tài khoản của bạn
              </p>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfoDeposit
