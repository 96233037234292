import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  :root {
    --button-primary-color: #008848;
    --button-primary-color-hover: #008848CC;
  }

  

  * {
    font-family: Roboto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
  } 
  *, 
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    color: #333; 
    line-height: 1.3;
    font-size: 16px;
    
    @media (max-width: 576px) {
      width: 100% !important; 
    } 

    & > iframe {
      display: none; 
    }

    img {
      height: auto;
      max-width: 100%;

      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }

  .ant-btn-primary {
    background: var(--button-primary-color);
    border-color: var(--button-primary-color);

    :active {
      background: var(--button-primary-color);
      border-color: var(--button-primary-color);
    }

    :hover, :focus  {
      background: var(--button-primary-color-hover);
      border-color: var(--button-primary-color-hover);
    }


  }

  .Toastify__toast-body {
    font-family: 'Helvetica';
  }

  .box-error {
    color: red;
    font-family: 'Helvetica';
    font-size: 12px;
    padding: 5px;
    text-align:center;
  }
 
  .modal-confirm {
    width: 448px !important;
    max-width: 100% !important;
    .ant-modal-body {
      padding: 0 !important;
    }

    .ant-modal-content {
      border: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .public-layout{
    overflow: hidden;
    min-height: 100vh;
  }

  .container{
    width: 100%;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  .ant-message {
    .ant-message-notice {
      display: flex;
      justify-content: flex-end;

      .ant-message-notice-content {
        max-width: 400px;
      }
    }
  }

  label {
    @media (max-width: 991px) {
      text-wrap: wrap;
    }
  }

  .ant-form-item-label > label {
    height: auto;
    margin-bottom: -8px !important;

    ${({ theme }) => theme.mediaQueries.sm} {
      height: 40px;
      margin-bottom: 0 !important;
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
  }

  .title-page {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td{
    border-right: 1px solid #ddd;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #ddd;
    padding: 10px 8px !important;
  }

  input {
    &:disabled {
      background-color: #ebebe4 !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: #399753;
  }

  .ant-select-dropdown{
    background: rgb(239, 239, 239) !important;
  }

  .custom-select{
    height: 40px;
    padding: 8px 12px;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;

    &:disabled {
      background-color: #ebebe4 !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
`

export default GlobalStyle
