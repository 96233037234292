export function addCommasToNumber(number: number | string, decimalPlaces = 2): string {
  try {
    if (Number.isNaN(+number)) {
      throw new Error('Not a valid number')
    }

    // Nhân số với 10^decimalPlaces, sau đó làm tròn xuống bằng Math.floor
    const multiplier = 10 ** decimalPlaces
    const roundedNumber = Math.floor(+number * multiplier) / multiplier

    const numberWithCommas: string = roundedNumber.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return numberWithCommas
  } catch {
    return '0'
  }
}

export function convertAddress(locationInfo, hideAddress = false) {
  if (!locationInfo) return ''
  const { address, district, ward, province } = locationInfo
  const getValue = (data: string | { title: string; id: number }) => {
    return typeof data === 'string' ? data : data?.title ?? ''
  }

  const result = [getValue(address), getValue(ward), getValue(district), getValue(province)]
  if (hideAddress) result.shift()

  return result.filter((item) => item).join(', ')
}
