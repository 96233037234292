import { Table } from 'antd'
import styled from 'styled-components'

const TableBaseStyle = styled.div`
  background: transparent;
  overflow: hidden;

  .ant-table {
    border-radius: 8px;
    border: 1px solid #eeeaf4;

    thead tr th {
      background-color: #008848;

      :first-child {
        border-top-left-radius: 8px !important;
      }

      :last-child {
        border-top-right-radius: 8px !important;
      }

      ::before {
        display: none;
      }
    }

    th {
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      color: #fff;
      padding: 8px 16px;
    }

    td {
      font-size: 14px;
      text-align: center;
      padding: 5px 16px;
      vertical-align: top;
    }

    tbody {
      tr {
        border-top: 1px solid #eeeaf4;

        :last-child td {
          border-bottom: none;
        }

        &:first-child {
          display: none;
        }
      }
    }

    tfoot {
      td {
        border-top: 1px solid #eeeaf4;
      }
    }

    .arrow-up-right-icon {
      color: #483c75 !important;
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .ant-pagination {
    .ant-pagination-item,
    button {
      border: none;
    }

    .ant-pagination-item {
      a {
        color: #a5a5a5;
      }

      &.ant-pagination-item-active a {
        color: #100f0e;
      }
    }
  }
`

const TableBase = ({ className = '', ...props }) => {
  return (
    <TableBaseStyle className={className} {...props}>
      <Table bordered {...props} />
    </TableBaseStyle>
  )
}

export default TableBase
