import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'
import { Col, Row } from 'antd'
import { publicService } from 'services'
import { Link } from 'react-router-dom'

const News = () => {
  const [listNews, setListNews] = useState([])

  useEffect(() => {
    publicService.postList((data) => {
      setListNews(data?.data?.posts)
    })
  }, [])

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: 'Tin tức',
              href: '/tin-tuc',
            },
          ]}
        />

        <Content>
          <Row gutter={[20, 20]}>
            {listNews.map((item: any) => (
              <Col span={12} md={{ span: 8 }} lg={{ span: 6 }} key={item.title}>
                <Link to={`/chi-tiet/${item?.slug}`}>
                  <NewItem>
                    <div className='logo'>
                      <img src={item.avatar} alt={item.title} />
                    </div>
                    <p className='title'>{item.title}</p>
                    <p className='time'>{item.created_at}</p>
                  </NewItem>
                </Link>
              </Col>
            ))}
          </Row>
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default News

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
`

const NewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  .logo {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    color: #333;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    min-height: 36px;
    transition: all 0.25s ease-in;

    font-size: 14px;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 16px;
      min-height: 42px;
    }

    :hover {
      opacity: 0.8;
    }
  }

  .time {
    text-align: right;
    font-size: 12px;
    font-style: italic;
  }
`
