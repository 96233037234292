import { createReducer } from '@reduxjs/toolkit'
import { IProvinceData, setProvinceList } from './actions'

export interface LocationState {
  provinceList: IProvinceData[]
}

const initialState: LocationState = {
  provinceList: [],
}

export default createReducer(initialState, (builder) =>
  builder.addCase(setProvinceList, (state, { payload }) => {
    state.provinceList = payload
  })
)
