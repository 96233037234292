import { Col, Row } from 'antd'
import styled from 'styled-components'

const WrapperOperatingProcess = styled.div`
  background-color: #fff;
  padding: 30px 0;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 15px;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 2em;
      margin-bottom: 30px;
    }
  }

  .wrap-content {
    background: #f2f2f2;
  }

  .ant-col {
    ${({ theme }) => theme.mediaQueries.lg} {
      width: 20%;
      flex: 0 0 20%;
      max-width: 20%;

      &:not(:last-child) {
        border-right: 2px dotted #fff;
      }
    }
  }

  .item-process {
    display: flex;
    flex-direction: row;
    padding: 20px 15px;
    gap: 14px;

    ${({ theme }) => theme.mediaQueries.lg} {
      gap: 0;
      padding: 20px 25px;
      flex-direction: column;
    }

    > div {
      &:nth-child(1) {
        width: 20%;

        ${({ theme }) => theme.mediaQueries.lg} {
          width: 100%;
        }
      }

      &:nth-child(2) {
        flex: 1;
      }
    }

    img {
      width: 50px;
      margin-bottom: 10px;
    }

    p {
      color: #008848;
      font-size: 1.1em;
      margin-bottom: 10px;
      text-align: left;

      ${({ theme }) => theme.mediaQueries.lg} {
        text-align: center;
      }

      &:nth-child(2) {
        color: #333;
        margin-bottom: 0;
        font-size: 13px;

        ${({ theme }) => theme.mediaQueries.lg} {
          line-height: 1.6em;
        }
      }
    }
  }
`

const OperatingProcess = () => {
  return (
    <WrapperOperatingProcess>
      <div className='container'>
        <p className='title'>Phương thức hoạt động</p>
      </div>

      <div className='wrap-content'>
        <div className='container'>
          <Row gutter={[30, 0]}>
            <Col span={24} lg={{ span: 4 }}>
              <div className='item-process'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/bkg-active-1.png' alt='' />
                </div>
                <div>
                  <p>Tiếp nhận đơn hàng</p>
                  <p>
                    Shop đăng nhập và đăng đơn qua hệ thống của TOANQUOC - Đơn hàng sẽ tạo thành công đồng thời trên hệ thống của
                    ToanQuoc và đối tác vận chuyển. (GHN, GHTK, NinjaVan…)
                  </p>
                </div>
              </div>
            </Col>
            <Col span={24} lg={{ span: 4 }}>
              <div className='item-process'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/bkg-active-2.png' alt='' />
                </div>
                <div>
                  <p>Lấy hàng</p>
                  <p>
                    Nhân viên lấy hàng của đơn vị đối tác vận chuyển sẽ tới lấy hàng - Ngay lập tức đơn hàng sẽ chuyển trạng thái
                    sang trạng thái lấy hàng thành công hoặc lưu kho thành công.
                  </p>
                </div>
              </div>
            </Col>
            <Col span={24} lg={{ span: 4 }}>
              <div className='item-process'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/bkg-active-3.png' alt='' />
                </div>
                <div>
                  <p>Giao hàng</p>
                  <p>Nhân viên của đối tác vận chuyển sẽ giao hàng cho khách hàng và thu tiền COD (Cash on Delivery)</p>
                </div>
              </div>
            </Col>
            <Col span={24} lg={{ span: 4 }}>
              <div className='item-process'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/bkg-active-4.png' alt='' />
                </div>
                <div>
                  <p>Đối soát</p>
                  <p>
                    ToanQuoc đối soát trả tiền cho shop(5 lần/tuần) qua tài khoản ngân hàng. Đồng thời quý khách hàng có thể lưu
                    bên bản đối soát qua việc xuất file excel trong báo cáo tài chính.
                  </p>
                </div>
              </div>
            </Col>
            <Col span={24} lg={{ span: 4 }}>
              <div className='item-process'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/bkg-active-5.png' alt='' />
                </div>
                <div>
                  <p>Kết thúc</p>
                  <p>Quá trình giao dịch hoàn thành.</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </WrapperOperatingProcess>
  )
}

export default OperatingProcess
