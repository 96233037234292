import { ConfigProvider, Layout } from 'antd'
import FooterPrivate from 'components/FooterPrivate'
import HeaderPrivate from 'components/HeaderPrivate'
import ModalVerifyAccount from 'components/Modal/ModalVerifyAccount'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { customerService } from 'services'
import { setProfile } from 'state/customer/actions'
import useGetCustomerData from 'state/customer/hooks/useGetCustomerData'
import styled from 'styled-components'
import locale from 'antd/es/locale/vi_VN'

const StyleLayout = styled.div`
  position: relative;
  z-index: 0;
  max-width: 100%;

  .content {
    .content-page {
      padding-left: 0;
      padding-top: 15px;

      ${({ theme }) => theme.mediaQueries.lg} {
        padding-top: 30px;
        padding-left: 250px;
      }
    }
  }
`

const PrivateLayout = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [modalVerifyAccount, setModalVerifyAccount] = useState<any>({
    toggle: false,
    data: null,
  })

  const { customerProfile: dataUser } = useGetCustomerData()

  useEffect(() => {
    customerService.getProfile((data) => {
      dispatch(setProfile(data?.data?.customer))
    })
  }, [dispatch])

  useEffect(() => {
    if (location && dataUser && (dataUser?.status_kyc === 0 || dataUser?.status_kyc === 1 || dataUser?.status_kyc === 3)) {
      setModalVerifyAccount({
        toggle: true,
        data: dataUser,
      })
    }
  }, [dataUser, location])

  return (
    <Layout>
      <StyleLayout>
        <ConfigProvider locale={locale}>
          <HeaderPrivate />

          <div className='content'>
            <div className='content-page'>
              <div>{children}</div>
              <FooterPrivate />
            </div>
          </div>
        </ConfigProvider>
      </StyleLayout>

      <ModalVerifyAccount modalData={modalVerifyAccount} setModalData={setModalVerifyAccount} dataUser={dataUser} />
    </Layout>
  )
}

export default PrivateLayout
