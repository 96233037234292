import { Button, Col, Modal, Row } from 'antd'
import styled from 'styled-components'

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .submit-btn {
    width: 100%;
  }
`

const ModalDelete = ({ open, onCancel, onSubmit, loading, title = 'Xóa thông tin' }) => {
  return (
    <Modal
      title={<p style={{ fontSize: '20px', fontWeight: 700 }}>{title}</p>}
      open={open}
      footer={false}
      centered
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <p style={{ marginBottom: 16 }}>Bạn có muốn xóa dữ liệu ra khỏi hệ thống không?</p>

        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Button type='primary' className='submit-btn' loading={loading} onClick={onSubmit}>
              Xác nhận
            </Button>
          </Col>

          <Col span={12}>
            <Button danger className='submit-btn' loading={loading} onClick={onCancel}>
              Hủy
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalDelete
