import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bankService } from 'services'
import { AppState } from 'state'
import { setBankData } from '../actions'

const useGetBankData = () => {
  const dispatch = useDispatch()
  const bankList = useSelector((state: AppState) => state.bank.bankList)

  const fetchBankData = useCallback(async () => {
    await bankService.getBank((res) => {
      dispatch(setBankData(res?.data?.banks))
    })
  }, [dispatch])

  return {
    fetchBankData,
    bankList,
  }
}

export default useGetBankData
