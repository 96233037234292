import { Button, Col, Form, Input, Modal, Row } from 'antd'
import styled from 'styled-components'
import { LockFilled, CheckOutlined } from '@ant-design/icons'

const ModalBody = styled.div`
  b {
    display: contents;
  }
`

const ModalInfo = ({ open, onCancel, form, onConfirm, loading }) => {
  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          <LockFilled /> Xác nhận mật khẩu
        </p>
      }
      open={open}
      footer={
        <Button onClick={onConfirm} icon={<CheckOutlined />} loading={loading}>
          Xác nhận
        </Button>
      }
      onCancel={onCancel}
      width={700}
    >
      <ModalBody>
        <Form form={form} layout='horizontal'>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label='Mật khẩu hiện tại'
                name='current_password'
                rules={[{ required: true, message: 'Vui lòng nhập Mật khẩu hiện tại' }]}
              >
                <Input.Password
                  style={{ height: 40 }}
                  placeholder='Nhập Mật khẩu hiện tại'
                  autoComplete='new-password'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfo
