import { Grid } from 'antd'
import { usePublicSlider } from 'services/swr/slider'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Skeleton } from 'widgets/Skeleton'
import { Autoplay, Navigation, Pagination } from 'swiper'

const { useBreakpoint } = Grid

const WrapperBanner = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    ::after {
      font-size: 32px;
      color: #fff;
      font-weight: bold;
    }
  }

  .swiper-pagination-bullet-active {
      background-color: #fff;
  }

`

const Banner = () => {
  const breakpoint = useBreakpoint()
  const { data: sliderList } = usePublicSlider({
    revalidateIfStale: false,
  })

  const renderHeight = () => {
    if (breakpoint?.xl) return 460
    if (breakpoint?.lg) return 380
    if (breakpoint?.md) return 460
    return 300
  }

  return (
    <WrapperBanner>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {!sliderList ? (
          <>
            <SwiperSlide>
              <Skeleton width={'100%'} height={renderHeight()} />
            </SwiperSlide>
            <SwiperSlide>
              <Skeleton width={'100%'} height={renderHeight()} />
            </SwiperSlide>
          </>
        ) : (
          sliderList?.sliders?.map((item) => (
            <SwiperSlide key={item?.id}>
              <img src={item?.note} alt='' />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </WrapperBanner>
  )
}

export default Banner
