import { Col, Row } from 'antd'
import styled from 'styled-components'
import Banner from './components/Banner'
import Form from './components/Form'
import Tutorials from './components/Tutorials'
import PartnerCarousel from './components/PartnerCarousel'
import GeneralInformation from './components/GeneralInformation'
import OperatingProcess from './components/OperatingProcess'

const WrapperLogin = styled.div`
  min-height: 100vh;

  > .container {
    padding: 20px 0;
  }
`

const Login = () => {
  return (
    <WrapperLogin>
      <div className='container'>
        <Row gutter={[30, 30]}>
          <Col span={24} lg={{ span: 16 }}>
            <Banner />
          </Col>
          <Col span={24} lg={{ span: 8 }}>
            <Form />
          </Col>
        </Row>
      </div>

      <GeneralInformation />
      <OperatingProcess />
      <PartnerCarousel />
      <Tutorials />
    </WrapperLogin>
  )
}

export default Login
