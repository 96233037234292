const ROUTER = {
  // admin
  adminHome: '/bang-dieu-khien',
  profile: '/tai-khoan',
  orderTracking: "/theo-doi-don-van",
  orderCreate: "/tao-don-hang"

  // public
}

export default ROUTER
