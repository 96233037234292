import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import TableBase from 'components/Table/TableBase'
import styled from 'styled-components'

const OptionAction = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

const HistoryTable = () => {
  const columns = [
    {
      title: 'Thông tin đơn',
      dataIndex: '',
      render: (value) => value,
    },
    {
      title: 'Yêu cầu cập nhật',
      dataIndex: '',
      render: (value) => value,
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      render: (value) => value,
    },
    {
      title: 'Ngày tạo',
      dataIndex: '',
      render: (value) => value,
    },

    {
      title: 'Thao tác',
      dataIndex: '',
      render: () => (
        <OptionAction>
          <EditOutlined onClick={() => true} />
          <DeleteOutlined onClick={() => true} />
        </OptionAction>
      ),
    },
  ]

  return <TableBase columns={columns} dataSource={[]} pagination={false} scroll={{ x: 1000 }} rowKey={(row) => row.id} />
}

export default HistoryTable
