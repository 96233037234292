import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

const WrapperBanner = styled.div``

const Banner = () => {
  return (
    <WrapperBanner>
      <Swiper spaceBetween={50} slidesPerView={1}>
        {[1, 2, 3, 4, 5, 6].map((x) => (
          <SwiperSlide key={x}>
            <img src={`/images/services/banner-${x}.jpg`} alt='' />
          </SwiperSlide>
        ))}
      </Swiper>
    </WrapperBanner>
  )
}

export default Banner
