import { IeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { useSetting } from 'services/swr/settings'
import styled from 'styled-components'
import { Skeleton } from 'widgets/Skeleton'

const WrapperFooter = styled.div`
  width: 100%;
  background: #000;
  border-top: 2px solid #fff;

  * {
    color: #fff;
  }

  .body {
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .content-left {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        font-size: 13px;
      }

      .anticon {
        margin-right: 4px;
      }

      .name {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .content-right {
      display: flex;
      flex-direction: column;
      gap: 4px;

      ${({ theme }) => theme.mediaQueries.lg} {
        align-items: flex-end;
      }

      p {
        font-size: 13px;
      }

      .name {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
`
function Footer() {
  const { data: settings } = useSetting({
    revalidateIfStale: false,
  })

  const {
    site_name_mini,
    email,
    hotline,
    company_name,
    company_address,
    company_number_license,
    company_number_place,
  } = settings || {}

  return (
    <WrapperFooter>
      <div className='container body'>
        <Row gutter={[16, 16]} style={{ width: '100%' }}>
          <Col span={24} lg={{ span: 8 }}>
            <div className='content-left'>
              {!settings ? (
                <>
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                </>
              ) : (
                <>
                  <p className='name'>{company_name}</p>
                  <p>
                    <PhoneOutlined />
                    {hotline}
                  </p>
                  <p>
                    <MailOutlined />
                    {email}
                  </p>
                  <p>
                    <IeOutlined />
                    {site_name_mini}
                  </p>
                </>
              )}
            </div>
          </Col>
          <Col span={24} lg={{ span: 16 }}>
            <div className='content-right'>
              {!settings ? (
                <>
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                  <Skeleton width={'100%'} minHeight={17} height={17} />
                </>
              ) : (
                <>
                  <p className='name'>{company_name}</p>
                  <p>{company_number_license}</p>
                  <p>{company_number_place}</p>
                  <p>{company_address}</p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </WrapperFooter>
  )
}

export default Footer
