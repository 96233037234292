import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import modal from './modal/reducer'
import customer from './customer/reducer'
import location from './location/reducer'
import bank from './bank/reducer'

type MergedState = {
  user: {
    [key: string]: any
  }
  // transactions: {
  //   [key: string]: any
  // }
}
const PERSISTED_KEYS: string[] = ['user']
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState

const store = configureStore({
  reducer: {
    modal,
    customer,
    location,
    bank
  },
  middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS }) as any], // save trx localestorage
  preloadedState: loadedState as any,
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
