import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { supportService } from 'services'
import styled from 'styled-components'

const ActionGroup = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    position: relative;
    top: 1px;
  }

  button {
    height: 40px;
  }

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap;
  }
`

const FilterForm = ({ setParams }: { setParams: (input: any) => void }) => {
  const [form] = Form.useForm()

  const [listStatus, setListStatus] = useState<any[]>([])

  useEffect(() => {
    supportService.getStatusList((data) => setListStatus(data?.data?.statuses))
  }, [])

  const searchItems = useCallback(() => {
    form.validateFields().then(async (values) => {
      setParams((prev) => {
        let paramsSearch: any = {
          page: prev?.page,
          per_page: prev?.per_page,
        }

        if (values?.status) {
          paramsSearch = {
            ...paramsSearch,
            status: values?.status,
          }
        }

        if (values?.order_code) {
          paramsSearch = {
            ...paramsSearch,
            order_code: values?.order_code,
          }
        }

        return {
          ...paramsSearch,
        }
      })
    })
  }, [form, setParams])

  return (
    <Form form={form} layout='vertical'>
      <Row gutter={[8, 0]} style={{ justifyContent: 'flex-start' }}>
        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='order_code'>
            <Input placeholder='Nhập Mã vận đơn' />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 12 }} xl={{ span: 3 }}>
          <Form.Item name='status'>
            <select className='custom-select'>
              <option value=''>Chọn trạng thái</option>
              {listStatus?.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 24 }} xl={{ span: 12 }}>
          <ActionGroup>
            <Button onClick={searchItems}>
              <SearchOutlined /> Tìm kiếm
            </Button>
          </ActionGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default FilterForm
