import { Button, Col, Form, Input, Modal, Row } from 'antd'
import FormWrapper from 'components/Form/FormWrapper'
import { useCallback } from 'react'
import { orderService } from 'services'
import styled from 'styled-components'
import { toast } from 'react-toastify'

const ModalBody = styled.div`
  padding-right: 10px;
  margin-top: -12px;

  > div {
    padding: 0;
    box-shadow: none;
    background: transparent;

    .ant-form-item-label {
      padding-bottom: 12px;

      ${({ theme }) => theme.mediaQueries.sm} {
        padding-bottom: 0;
      }

      label {
        font-size: 16px !important;
      }
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

const ModalUpdateStatus = ({ modalUpdateStatus, onCancel, loading, setLoading, orderId }) => {
  const [form] = Form.useForm()

  const submitForm = useCallback(() => {
    form.validateFields().then(async (values) => {
      try {
        const dataPost = {
          note: values?.note,
          status: modalUpdateStatus?.type,
        }

        setLoading(true)
        await orderService.updateStatus(
          orderId,
          dataPost,
          (response) => {
            toast.success(response?.data?.message, {
              autoClose: 1000,
            })
            setLoading(false)
            onCancel()
          },
          (response) => {
            toast.error(
              response?.message || `${modalUpdateStatus?.type === 2 ? 'Hoàn đơn hàng' : 'Phát tiếp đơn hàng'} không thành công`
            )
            setLoading(false)
          }
        )
      } catch (error) {
        setLoading(false)
        console.error('updateAddress', error)
        toast.error(`${modalUpdateStatus?.type === 2 ? 'Hoàn đơn hàng' : 'Phát tiếp đơn hàng'} không thành công`)
      }
    })
  }, [form, modalUpdateStatus?.type, onCancel, orderId, setLoading])

  return (
    <Modal
      title={
        <p style={{ fontSize: '20px', fontWeight: 700 }}>
          {modalUpdateStatus?.type === 2 ? 'Xác nhận hoàn hàng' : 'Xác nhận phát tiếp'}
        </p>
      }
      open={modalUpdateStatus?.toggle}
      footer={false}
      centered
      onCancel={() => {
        if (!loading) {
          onCancel()
        }
      }}
      width={700}
    >
      <ModalBody>
        <FormWrapper>
          <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} requiredMark={false}>
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <Form.Item
                  label={
                    modalUpdateStatus?.type === 2
                      ? 'Bạn có chắc chắn muốn hoàn đơn hàng này không?'
                      : 'Bạn có chắc chắn muốn phát tiếp đơn hàng này không?'
                  }
                  name='note'
                >
                  <Input
                    placeholder={
                      modalUpdateStatus?.type === 2
                        ? 'Vui lòng nhập thông tin ghi chú gồm điện thoại, địa chỉ'
                        : 'Vui lòng nhập thông tin ghi chú gồm điện thoại, địa chỉ'
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <ButtonGroups>
                  <Button type='primary' size='large' loading={loading} onClick={submitForm}>
                    Xác nhận
                  </Button>
                  <Button size='large' onClick={onCancel}>
                    Thoát
                  </Button>
                </ButtonGroups>
              </Col>
            </Row>
          </Form>
        </FormWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdateStatus
