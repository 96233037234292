import styled from 'styled-components'
import Breadcrumb from 'components/Breadcrumb'
import PartnerCarousel from 'pages/login/components/PartnerCarousel'
import { Col, Input, Row } from 'antd'
import { useCallback, useState } from 'react'
import { publicService } from 'services'

const { Search } = Input

const Page = () => {
  const [dataDetail, setDataDetail] = useState<any>(null)
  const [searchValue, setSearchValue] = useState('')

  const onSearch = useCallback((e) => {
    if (e) {
      publicService.getOrderTracking(
        e,
        (data) => setDataDetail(data?.data?.order),
        (data) => setDataDetail(data)
      )
    }
  }, [])

  return (
    <Wrapper>
      <div className='container'>
        <Breadcrumb
          menuList={[
            {
              label: 'Trang chủ',
              href: '/',
            },
            {
              label: ' Tra cứu đơn vận',
              href: '',
            },
          ]}
        />

        <Content>
          <Search
            allowClear
            value={searchValue}
            style={{ maxWidth: 900, width: '100%', margin: 'auto' }}
            placeholder='Nhập mã đơn vận, tên người nhận, số điện thoại người nhận...'
            onSearch={onSearch}
            onChange={(e) => setSearchValue(e?.target?.value)}
            onPressEnter={(e: any) => onSearch(e?.target?.value)}
          />

          <div>
            <div className='page-content'>
              {dataDetail && (
                <>
                  {dataDetail?.error ? (
                    <p>{dataDetail?.message}</p>
                  ) : (
                    <>
                      <h2 className='page-title'>Chi tiết đơn vận</h2>

                      <fieldset>
                        <legend>Lịch sử vận chuyển:</legend>
                        <div className='info-detail'>
                          {dataDetail?.statusLogs?.map((item) => (
                            <Row gutter={[15, 15]} key={item?.id}>
                              <Col span={7} sm={4}>
                                <p style={{ textAlign: 'right' }}>{item?.date_status}</p>
                              </Col>
                              <Col span={17} lg={{ span: 20 }}>
                                <p style={{ fontWeight: 'bold' }}>{item?.status_string}</p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </fieldset>

                      <div className='clear-4' />

                      <fieldset>
                        <legend>Thông tin đơn vận:</legend>
                        <div className='info-detail'>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Mã vận đơn:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.order_code}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Thời gian tạo:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.created_at}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Trạng thái:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.status_string}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Ngày cập nhật:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.updated_at}</p>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>

                      <div className='clear-4' />

                      <fieldset>
                        <legend>Thông tin người gửi hàng:</legend>
                        <div className='info-detail'>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Tên đại điện:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.sender?.full_name}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Điện thoại:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.sender?.phone_number}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Địa chỉ:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>
                                {' '}
                                {dataDetail?.sender?.address}, {dataDetail?.sender?.ward?.title},{' '}
                                {dataDetail?.sender?.district?.title}, {dataDetail?.sender?.province?.title}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>

                      <div className='clear-4' />

                      <fieldset>
                        <legend>Thông tin người nhận hàng:</legend>
                        <div className='info-detail'>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Tên đại điện:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.receiver?.full_name}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Điện thoại:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>{dataDetail?.receiver?.phone_number}</p>
                            </Col>
                          </Row>
                          <Row gutter={[15, 15]}>
                            <Col span={7} sm={4}>
                              <p style={{ textAlign: 'right' }}>Địa chỉ:</p>
                            </Col>
                            <Col span={17} lg={{ span: 20 }}>
                              <p style={{ fontWeight: 'bold' }}>
                                {' '}
                                {dataDetail?.receiver?.address}, {dataDetail?.receiver?.ward?.title},{' '}
                                {dataDetail?.receiver?.district?.title}, {dataDetail?.receiver?.province?.title}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </fieldset>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Content>
      </div>

      <PartnerCarousel />
    </Wrapper>
  )
}

export default Page

const Wrapper = styled.div`
  .container {
    padding: 20px 15px 0;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;

  .page-content {
    min-height: 500px;
    font-size: 13px;
    color: #333;
    margin-bottom: 40px;

    .page-title {
      margin-top: 0px;
      margin-bottom: 15px;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
    }
  }

  fieldset {
    padding: 15px 5px;
    margin: 0 2px;
    border: 1px solid silver;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 15px;
    }

    legend {
      display: block;
      width: auto;
      padding: 0;
      font-size: 1.1em;
      line-height: inherit;
      border: 0;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: bold;
    }
  }

  .clear-4 {
    height: 20px;
  }

  .info-detail {
    .ant-row {
      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }
  }
`
