import FormWrapper from 'components/Form/FormWrapper'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'
import FilterForm from './components/FilterForm'
import { useCallback, useEffect, useState } from 'react'
import { supportService } from 'services'

const WrapperPage = styled.div`
  min-height: 77vh;
  width: 100%;
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
`

const Page = () => {
  const [dataList, setDataList] = useState<any>()
  const [loadingList, setLoadingList] = useState<boolean>(true)
  const [params, setParams] = useState<any>({
    page: 1,
    per_page: 10,
  })

  const searchSupport = useCallback(() => {
    setLoadingList(true)
    if (params) {
      supportService.search(params, (data) => {
        setDataList(data)
        setLoadingList(false)
      })
    }
  }, [params])

  useEffect(() => {
    searchSupport()
  }, [searchSupport])

  return (
    <WrapperPage>
      <p className='title-page'>Hỗ trợ khách hàng</p>

      <FormWrapper>
        <FilterForm setParams={setParams} />

        <HistoryTable dataList={dataList} loadingList={loadingList} setParams={setParams} />
      </FormWrapper>
    </WrapperPage>
  )
}

export default Page
