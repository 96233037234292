import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { loggerService } from 'services'
import styled from 'styled-components'
import HistoryTable from './components/HistoryTable'
import FilterForm from './components/FilterForm'

const WrapperPage = styled.div`
  min-height: 100vh;
  background: #fff;

  .ant-menu {
    background: #056638;
    color: #fff;
    border-right: unset;
  }
`

const Page = () => {
  const [logger, setLogger] = useState<any>()
  const [paramSearch, setSearch] = useState<any>({
    page: 1,
    per_page: 10,
    order_code: '',
    start_date: '',
    end_date: '',
  })
  const [loadingList, setLoadingList] = useState<boolean>(true)

  useEffect(() => {
    if (paramSearch) {
      setLoadingList(true)
      loggerService.search(
        paramSearch,
        (data) => {
          setLogger(data)
          setLoadingList(false)
        },
        () => {
          setLoadingList(false)
        }
      )
    }
  }, [paramSearch])

  return (
    <WrapperPage>
      <div className='container'>
        <Row gutter={[10, 10]}>
          {/* <Col span={4} style={{ backgroundColor: '#056638' }}>
            <Menu>
              {logger?.logs?.map((item) => (
                <Menu.Item key={item?.id}>{item?.created_at}</Menu.Item>
              ))}
            </Menu>
            ;
          </Col>
          <Col span={20}></Col> */}
          <Col span={24}>
            <FilterForm setParams={setSearch} />
            <HistoryTable dataList={logger} setParams={setSearch} loadingList={loadingList} />
          </Col>
        </Row>
      </div>
    </WrapperPage>
  )
}

export default Page
