import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  PauseCircleOutlined,
  SyncOutlined,
  TruckOutlined,
  UndoOutlined,
  UserSwitchOutlined,
  WarningOutlined,
  ImportOutlined
} from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import ROUTER from 'services/router'
import { useCODReport, useOrderReport } from 'services/swr/order'
import { Skeleton } from 'widgets/Skeleton'
import { Label, ReportBox, WrapperDashboard } from './styled'
import { numberWithCommas } from 'utils'

const CONFIG_COD = {
  ORDER_DELIVERY_FAILED: {
    title: 'Giao hàng thất bại',
    icon: <CloseCircleOutlined className='red-icon' />,
  },
  CREATE_ORDER_FAILED: {
    title: 'Tạo đơn vận thất bại',
    icon: <WarningOutlined className='red-icon' />,
  },
  ORDER_PAYMENTED: {
    title: 'Đã thu tiền',
    icon: <CheckCircleOutlined className='green-icon' />,
  },
  ORDER_NOT_PAYMENTED: {
    title: 'Chưa lấy tiền',
    icon: <PauseCircleOutlined className='yellow-icon' />,
  },
}

const CONFIG_SUMMARY = {
  ORDER_NEW: {
    title: 'Mới tạo và chờ lấy',
    icon: <SyncOutlined className='grey-icon' />,
  },
  ORDER_SHIPPING: {
    title: 'Đang giao',
    icon: <TruckOutlined className='grey-icon' />,
  },
  ORDER_BACK_SENDER: {
    title: 'Đơn trả lại',
    icon: <UndoOutlined className='grey-icon' />,
  },
  ORDER_SHOP_PROCCESSING: {
    title: 'Chờ shop xử lý',
    icon: <UserSwitchOutlined className='grey-icon' />,
  },
  ORDER_LOST: {
    title: 'Đơn thất lạc',
    icon: <EyeInvisibleOutlined className='grey-icon' />,
  },
  ORDER_CANCEL: {
    title: 'Đơn bị hủy',
    icon: <DeleteOutlined className='grey-icon' />,
  },
  ORDER_PICKUP: {
    title: 'Đang lấy hàng',
    icon: <ImportOutlined className='grey-icon' />,
  },
}

const renderLoading = (total: number) => {
  return Array.from({ length: total }, (v, i) => i).map((x) => (
    <Col span={12} md={{ span: 6 }} key={x}>
      <Skeleton width={'100%'} height={115} />
    </Col>
  ))
}

const Dashboard = () => {
  const history = useHistory()
  const { data: orderSummaryData } = useOrderReport()
  const { data: orderCODData } = useCODReport()

  return (
    <WrapperDashboard>
      <div className='container'>
        <div className='box-wellcome'>
          <div>
            <img width={80} src='/logo.png' alt='' />
          </div>
          <div className='wrap-text'>
            <p>Xin chào!</p>
            <p>Sẵn sàng để gửi một số bưu kiện?</p>
          </div>

          <div className='wrap-button'>
            <Button type='primary' onClick={() => history.push(ROUTER.orderCreate)}>
              ĐƠN HÀNG MỚI
            </Button>
            <Button type='primary' onClick={() => history.push(ROUTER.orderTracking)}>
              THEO DÕI ĐƠN
            </Button>
          </div>
        </div>

        <div className='dashboard-item'>
          <Label>
            <p className='title'>COD tuần này</p>
            <a href='#'>Xem chi tiết &gt; </a>
          </Label>

          <Row gutter={[20, 20]}>
            {!orderCODData ? (
              renderLoading(4)
            ) : (
              <>
                {orderCODData?.map((item) => (
                  <Col span={12} md={{ span: 6 }} key={item?.key}>
                    <ReportBox onClick={() => history.push(`/theo-doi-don-van?cod=${item?.id}`)}>
                      <div className='top'>
                        {CONFIG_COD[item?.key]?.icon}
                        <p>{CONFIG_COD[item?.key]?.title}</p>
                      </div>

                      <p className='mid'>{numberWithCommas(item?.total_cod)}đ</p>
                      <p className='bottom'>Có {item?.total_order} bưu kiện</p>
                    </ReportBox>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </div>

        <div className='dashboard-item'>
          <Label>
            <p className='title'>Tổng quan tuần này</p>
          </Label>

          <Row gutter={[20, 20]}>
            {!orderSummaryData ? (
              renderLoading(6)
            ) : (
              <>
                {orderSummaryData?.map((item) => (
                  <Col span={12} md={{ span: 6 }} key={item?.key}>
                    <ReportBox onClick={() => history.push(`/theo-doi-don-van?statusSumary=${item?.id}`)}>
                      <div className='top'>{CONFIG_SUMMARY[item?.key]?.icon}</div>

                      <p className='mid'>{CONFIG_SUMMARY[item?.key]?.title}</p>
                      <p className='bottom'>{item?.total_order}</p>
                    </ReportBox>
                  </Col>
                ))}
              </>
            )}
          </Row>
        </div>
      </div>
    </WrapperDashboard>
  )
}

export default Dashboard
