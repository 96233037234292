import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;

  svg {
    position: relative;
    top: 1px;
  }
`

type TActionGroup = {
  onAdd: () => void
}

const ActionGroup = ({ onAdd }: TActionGroup) => {
  return (
    <Wrapper>
      <Button onClick={onAdd}>
        <PlusCircleOutlined /> Thêm mới
      </Button>
      {/* <Button>
        <ExportOutlined /> Xuất file
      </Button>
      <Button>
        <FileAddOutlined /> Nhập file
      </Button> */}
    </Wrapper>
  )
}

export default ActionGroup
