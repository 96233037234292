import { Button, Col, Row } from 'antd'
import styled from 'styled-components'

const WrapperGeneralInformation = styled.div`
  background-color: #fff;
  padding: 30px 0;

  .content-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;

    p {
      &:nth-child(1) {
        font-weight: 400;
        font-size: 1.5em;
        margin-bottom: 5px;
        margin-bottom: 5px;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 2em;
        }
      }
      &:nth-child(2) {
        margin-bottom: 15px;
        font-weight: bold;

        ${({ theme }) => theme.mediaQueries.lg} {
          margin-bottom: 30px;
        }
      }

      &:nth-child(3) {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 1.5em;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 2em;
        }
      }
    }
  }

  .content-right {
    .item-info {
      display: flex;
      align-items: center;
      gap: 4px;

      &:not(:last-child) {
        margin-bottom: 15px;

        ${({ theme }) => theme.mediaQueries.lg} {
          margin-bottom: 30px;
        }
      }

      > div {
        &:nth-child(1) {
          img {
            width: 100%;
            max-width: 80px;
          }
        }

        &:nth-child(2) {
          flex: 1;

          p {
            font-size: 1em;

            &:nth-child(1) {
              color: #008848;
              text-transform: uppercase;
              margin-bottom: 5px;

              ${({ theme }) => theme.mediaQueries.sm} {
                font-size: 1em;
              }

              ${({ theme }) => theme.mediaQueries.lg} {
                font-size: 1.8em;
              }
            }

            &:nth-child(2) {
              color: #958686;
              font-style: italic;
            }
          }
        }
      }
    }
  }
`

const GeneralInformation = () => {
  return (
    <WrapperGeneralInformation>
      <div className='container'>
        <Row gutter={[30, 30]}>
          <Col span={24} lg={{ span: 12 }}>
            <div className='content-left'>
              <p>Bạn có biết?</p>
              <p>Dịch vụ chúng tôi cung cấp đã phù hợp với</p>
              <p>890.389 ++ khách hàng</p>
              <Button type='primary' style={{ maxWidth: 350, width: '100%' }}>
                Đăng ký ngay
              </Button>
            </div>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <div className='content-right'>
              <div className='item-info'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/know-1.png' alt='' />
                </div>
                <div>
                  <p>PHỦ SÓNG 99% HUYỆN XÃ</p>
                  <p>Lấy hàng / Giao hàng trên 11.000 huyện xã trên toàn quốc</p>
                </div>
              </div>
              <div className='item-info'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/know-2.png' alt='' />
                </div>
                <div>
                  <p>APP CHO IOS VÀ ANDROID</p>
                  <p>Đăng đơn – Tracking – Xử lý đơn hàng mọi lúc mọi nơi trên điện thoại!</p>
                </div>
              </div>
              <div className='item-info'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/know-3.png' alt='' />
                </div>
                <div>
                  <p>ĐỐI SOÁT TRẢ TIỀN NHANH</p>
                  <p>Chuyển khoản vào tài khoản NH 3 lần/tuần vào thứ 2/4/6</p>
                </div>
              </div>
              <div className='item-info'>
                <div>
                  <img src='https://dev.toanquoc.vn/img/know-4.png' alt='' />
                </div>
                <div>
                  <p>GIAO HÀNG LINH HOẠT</p>
                  <p>Linh hoạt giao hàng cho khách chọn, đổi địa chỉ giao, đổi tiền thu hộ, đổi SĐT, đổi người nhận hàng…</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </WrapperGeneralInformation>
  )
}

export default GeneralInformation
