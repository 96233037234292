import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ModalState {
  modal: any
}

const initialState: ModalState = {
  modal: { toggle: false, dataModal: null },
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setModal, (state, { payload }) => {
    state.modal = payload
  })
)
